<template>
    <div>
        <audio  :src="music" loop="loop"  preload="auto" ref="audio"></audio>
        <div @mouseover="changeOver()" @mouseleave="changeLeave()" class="look-box" ref="audioBox">
            <div ref="font" v-if="isMusic" class="look" @click="musicPause()">{{close}}</div>
            <div ref="font" v-if="!isMusic" class="look" @click="musicPlay()">{{open}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "musicPlay",
        props:['color','border','sizes','zIndex','fontSize','font','open','close','music','top','right','bottom','left','hoverColor'],
        data(){
          return{
              isMusic:true
          }
        },
        mounted(){
            this.$refs.audio.play();
            let box = this.$refs.audioBox;
            let font=this.$refs.font;
            box.style.position='absolute';
            box.style.width = this.sizes.w +'px';
            box.style.height = this.sizes.h+'px';
            box.style.lineHeight=this.sizes.h+'px';
            box.style.backgroundColor=this.color.color;
            box.style.borderRadius=this.border+'%';
            box.style.zIndex = this.zIndex;
            box.style.top=this.top+'px';
            box.style.left=this.left+'px';
            box.style.right=this.right+'px';
            box.style.bottom=this.bottom+'px';
            font.style.fontSize=this.fontSize+'px';
            font.style.fontFamily=this.font.fontType;
            font.style.color=this.font.color;
        },
        methods:{
            musicPause(){
                this.isMusic=false;
                this.$refs.audio.pause()
            },
            musicPlay(){
                this.isMusic=true;
                this.$refs.audio.play()
            },
            changeOver(){
                this.$refs.audioBox.style.backgroundColor=this.hoverColor.color;
            },
            changeLeave(){
                this.$refs.audioBox.style.backgroundColor=this.color.color;
            }
        }
    }
</script>

<style scoped>
    .look-box{
        white-space: nowrap;
        text-align: center;
        border-radius: 50%;
    }
    .look{
        cursor: pointer;
    }
    @media (max-width: 768px){
        .look-box{
            zoom: 0.8;
        }
    }
</style>
