<template>
  <div
      ref="MAXcontainer"
      :class="this.size.w == 0 || this.size.h == 0 ? 'container' : ''"
  >
    <slot></slot>
    <!-- <div ref="cssRenderer" class=" container" id="cssRenderer">
    </div> -->
    <div
        ref="container"
        :class="this.size.w == 0 || this.size.h == 0 ? 'container' : ''"
    ></div>
  </div>
</template>

<script>
    import * as THREE from "three";
    import Stats from "three/examples/jsm/libs/stats.module";
    import {CSS2DRenderer} from "three/examples/jsm/renderers/CSS2DRenderer";
    import {TWEEN} from "three/examples/jsm/libs/tween.module.min.js";

    export default {
        name: "Renderer",
        provide() {
            return {
                parentObj: null, // avoid "injection not found" warning
                renderer: this, // dom
            };
        },
        props: {
            size: {
                type: Object, // { w, h }
                // required: true,
                default() {
                    return {w: 0, h: 0};
                },
            },
            insetsize: {
                type: Object,
                // required: true,
                default() {
                    return {w: 0, h: 0};
                }
            },
            obj: {type: Object},
            EventSwitching: {
                type: Boolean,
                default() {
                    return true
                }
            },
            ISmousemove: {
                type: Boolean,
                default() {
                    return false
                }
            },
            alpha: {
                type: Boolean,
                default() {
                    return false
                }
            },
            options: {
                type: Object, default: () => ({
                    outputEncoding: THREE.sRGBEncoding,
                    toneMapping: THREE.NoToneMapping,
                    autoClear: true
                })
            }
        },
        watch: {
            size() {
                this.rendererDomSize = this.size;
                this.onWindowResize();
            },
            EventSwitching(val) {
                if (val) {
                    this.addEventListener()
                } else {
                    this.removeEventListener()
                }
            }
        },
        data() {
            let curObj = this.obj;
            let rendererDomSize = {w: 500, h: 500}
            let {w, h} = rendererDomSize
            let div = document.createElement("div");
            if (!curObj) {
                curObj = new THREE.WebGLRenderer({antialias: true, alpha: this.alpha});
                curObj.setPixelRatio(window.devicePixelRatio);
                curObj.shadowMap.enabled = true;
                curObj.shadowMap.type = THREE.PCFSoftShadowMap;
                Object.keys(this.options).forEach((k) => {
                    curObj[k] = this.options[k];
                });
            }
            curObj.name = curObj.name || curObj.type;
            curObj.setSize(w, h);
            this.$global.rendererSize = rendererDomSize
            // this.$global.rendererDom = curObj.domElement;
            let canvasDom = curObj.domElement;
            this.$global.renderer = curObj;

            let rtTexture = new THREE.WebGLRenderTarget(
                this.insetsize.w,
                this.insetsize.h,
                {
                    minFilter: THREE.LinearFilter,
                    magFilter: THREE.NearestFilter,
                    format: THREE.RGBFormat,
                }
            );

            const stats = new Stats();
            let containerDom = null,
                RendererAnimation = true;
            // css
            let cssRenderer = new CSS2DRenderer();
            cssRenderer.setSize(w, h);
            cssRenderer.domElement.style.position = "absolute";
            cssRenderer.domElement.style.top = " 0";
            cssRenderer.domElement.style.left = "0";
            cssRenderer.domElement.style.zIndex = "99";
            div.appendChild(curObj.domElement);
            div.appendChild(cssRenderer.domElement);
            this.$global.rendererDom = div;
            return {
                curObj,
                stats,
                containerDom,
                RendererAnimation,
                map: false,
                rtTexture,
                canvasDom,
                cssRenderer,
                div,
                camera: null,
                scene: null,
                rendererDomSize,
            };
        },
        beforeDestroy() {
            this.$refs.MAXcontainer.innerHTML = "";
            this.div = null
                this.removeEventListener()
            cancelAnimationFrame(this.RendererAnimation); //停止动画
            this.curObj.dispose();
            this.curObj.forceContextLoss();
            this.curObj.content = null;
            this.curObj.domElement = null;
            this.curObj = null;
            this.rtTexture.dispose();
            this.rtTexture = null;
            this.clock = null;
            this.stats = null;
            this.containerDom = null;
            //this.$global.scene = null;
            // this.$global.rendererSize = null
        },
        destroyed() {
            // console.log("销毁后");
            // this.curObj.dispose();
            // this.curObj.forceContextLoss();
            // this.curObj.content = null;
            // this.curObj.domElement = null;
            // this.curObj = null;
            // this.rtTexture.dispose();
            // this.rtTexture = null;
            // this.clock = null;
            // this.mouseDownTime = null;
            // this.mouseUpTime = null;
            // this.isClick = null;
            // this.stats = null;
            // this.containerDom = null;
        },
        mounted() {
            if (this.EventSwitching) {
                this.addEventListener()
            }


            this.$refs.container.appendChild(this.div);
            // this.$refs.container.appendChild(this.stats.dom);
            // cssRenderer
            // this.$refs.cssRenderer.appendChild(this.cssRenderer.domElement)
            // this.containerDom = this.$refs.container;
            if (this.size.w == 0 || this.size.h == 0) {
                var time = setInterval(() => {
                    this.containerDom = this.$refs.container || this.$parent.$parent.$el;
                    this.onWindowResize();
                    if (!this.curObj) {
                        return;
                    }
                    if (this.curObj.domElement.height > 0) {
                        clearInterval(time);
                        //  console.error("size or isAdapt 参数错误")
                    }
                }, 1000);
            }
            this.animate();
        },
        methods: {
            animate() {
                this.RendererAnimation = requestAnimationFrame(this.animate);
                TWEEN.update();
                let {w, h} = this.rendererDomSize;
                this.stats.update();
                this.curObj.setRenderTarget(null);
                this.curObj.clear();
                this.curObj.setSize(w, h);
                this.curObj.setViewport(0, 0, w, h);
                this.cssRenderer.render(this.scene, this.camera);
                this.curObj.render(this.scene, this.camera);

                if (this.$global.assistCamera) {
                    this.curObj.setScissorTest(true);
                    this.curObj.setScissor(
                        w - this.insetsize.w - 100,
                        h - this.insetsize.h - 20,
                        this.insetsize.w,
                        this.insetsize.h
                    );
                    this.curObj.setViewport(
                        w - this.insetsize.w - 100,
                        h - this.insetsize.h - 20,
                        this.insetsize.w,
                        this.insetsize.h
                    );

                    if (!this.map) {
                        this.curObj.render(
                            this.scene,
                            this.$global.assistCamera,
                            this.rtTexture
                        );
                        this.$global.mapscene.background = this.rtTexture.texture;
                        this.map = true;
                    }
                    this.curObj.setRenderTarget(null);
                    if (this.map) {
                        this.curObj.setScissorTest(false);
                        this.curObj.setScissor(
                            w - this.insetsize.w - 100,
                            h - this.insetsize.h - 20,
                            this.insetsize.w,
                            this.insetsize.h
                        );
                        this.curObj.setViewport(
                            w - this.insetsize.w - 100,
                            h - this.insetsize.h - 20,
                            this.insetsize.w,
                            this.insetsize.h
                        );
                        this.curObj.render(this.$global.mapscene, this.$global.assistCamera);
                    }
                    this.curObj.setScissorTest(false);
                }
                if (this.$global.screenshot) {
                    this.$global.screenshotData = this.curObj.domElement.toDataURL();
                    this.$global.screenshot = false;
                }
                this.curObj.clearDepth();
            },

            onWindowResize() {

                if (!this.containerDom || !this.curObj) {
                    return;
                }
                if (this.size.w == 0 || this.size.h == 0) {
                    //$global.rendererSize
                    this.rendererDomSize = {
                        w: this.containerDom.clientWidth,
                        h: this.containerDom.clientHeight,
                    };
                }
                let {w, h} = this.rendererDomSize;
                this.cssRenderer.setSize(w, h);
                this.curObj.setSize(w, h);
                this.camera.aspect = w / h;
                this.camera.updateProjectionMatrix();
                if (this.$global.assistCamera) {
                    this.$global.assistCamera.aspect = this.insetsize.w / this.insetsize.h;
                    this.$global.assistCamera.updateProjectionMatrix();
                }
            },
            onKeyDown(event) {
                // console.log('onKeyDown',event)
                this.$bus.$emit("key-down", event);
            },
            onKeyUp(event) {
                // console.log('onKeyUp',event)
                this.$bus.$emit("key-up", event);
            },
            onMouseClick(event){
                // console.log('onMouseClick',event);

                this.$bus.$emit("mouse-click", event);
            },
            onMouseDown(event) {
                this.$bus.$emit("mouse-down", event);

                setTimeout(()=>{
                    event.preventDefault();
                }, 200)

            },
            onMouseUp(event) {
                this.$bus.$emit("mouse-up",event);
            },
            onMouseMove(event) {
                this.$bus.$emit("mouse-move", event);
            },
            onTouchStart(event) {
                //console.log('onTouchStart',event)
                this.$bus.$emit("touch-start", event);
                setTimeout(() => {
                    event.preventDefault();
                }, 200);
            },
            onTouchEnd(/*event*/) {
                //console.log('onTouchEnd',event)
                this.$bus.$emit("touch-end");
            },
            onTouchMove(event) {
                //console.log('onTouchMove',event)
                this.$bus.$emit("touch-move", event);
            },

            addEventListener() {
                window.addEventListener("resize", this.onWindowResize, false);
                window.addEventListener("keydown", this.onKeyDown, false);
                window.addEventListener("keyup", this.onKeyUp, false);
                this.$refs.MAXcontainer.addEventListener(
                    "click",
                    this.onMouseClick,
                    false
                );
                this.$refs.MAXcontainer.addEventListener(
                    "mousedown",
                    this.onMouseDown,
                    false
                );
                this.$refs.MAXcontainer.addEventListener(
                    "mouseup",
                    this.onMouseUp,
                    false
                );
                if (this.ISmousemove) {
                    this.$refs.MAXcontainer.addEventListener(
                        "mousemove",
                        this.onMouseMove,
                        false
                    );
                }
                this.$refs.MAXcontainer.addEventListener(
                    "touchstart",
                    this.onTouchStart,
                    false
                );

                this.$refs.MAXcontainer.addEventListener(
                    "touchend",
                    this.onTouchEnd,
                    false
                );
                this.$refs.MAXcontainer.addEventListener(
                    "touchmove",
                    this.onTouchMove,
                    false
                );

            },
            removeEventListener() {
                window.removeEventListener("resize", this.onWindowResize, false);
                window.removeEventListener("keydown", this.onKeyDown, false);
                window.removeEventListener("keyup", this.onKeyUp, false);
                this.$refs.MAXcontainer.removeEventListener(
                    "click",
                    this.onMouseClick,
                    false
                );
                this.$refs.MAXcontainer.removeEventListener(
                    "mousedown",
                    this.onMouseDown,
                    false
                );
                this.$refs.MAXcontainer.removeEventListener(
                    "mouseup",
                    this.onMouseUp,
                    false
                );
                this.$refs.MAXcontainer.removeEventListener(
                    "mousemove",
                    this.onMouseMove,
                    false
                );
                this.$refs.MAXcontainer.removeEventListener(
                    "touchstart",
                    this.onTouchStart,
                    false
                );
                this.$refs.MAXcontainer.removeEventListener(
                    "touchend",
                    this.onTouchEnd,
                    false
                );
                this.$refs.MAXcontainer.removeEventListener(
                    "touchmove",
                    this.onTouchMove,
                    false
                );
                if (this.ISmousemove) {
                    this.$refs.MAXcontainer.removeEventListener(
                        "mousemove",
                        this.onMouseMove,
                        false
                    );
                }
            }
        },
    };
</script>
<style scoped>
  .container {
    min-width: 10px;
    min-height: 10px;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    display: inline-block;
    position: relative;
  }

  .container > div {
    display: inline-block;
    position: relative;
  }

  #cssRenderer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
  }
</style>
