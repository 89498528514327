import { render, staticRenderFns } from "./negativeObject.vue?vue&type=template&id=2c4891c8&scoped=true&"
import script from "./negativeObject.vue?vue&type=script&lang=js&"
export * from "./negativeObject.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c4891c8",
  null
  
)

export default component.exports