import { render, staticRenderFns } from "./freeMove.vue?vue&type=template&id=1dc56dd4&"
import script from "./freeMove.vue?vue&type=script&lang=js&"
export * from "./freeMove.vue?vue&type=script&lang=js&"
import style0 from "./freeMove.vue?vue&type=style&index=0&id=1dc56dd4&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports