<template>
<div>
  <object3d :name="name">
    <mesh
      :rotation="{ x: 0, Y: 0, z: 3.14 }"
      :position="{ x: 0, Y: 0, z: 0 }"
      :scale="-1"
    >
      <geometry type="Sphere" :args="[440, 40, 40]"></geometry>
      <material type="MeshBasic" :options="matOpts">
        <texture-loader :url="src" :MappingIndex="index"></texture-loader>
      </material>
    </mesh>
  </object3d>
</div>
  
</template>

<script>
import * as THREE from "three";
import Object3D from './../vue_three_js/components/Object3D'
export default {
  name: "Sphere",
  mixins:[Object3D],
  props:{
    src:{
      type: [Array, String],
      default(){
        return  ['static/f1/pic1.jpg']
      }
    },
    index:{
      type:Number,
      default(){
        return 0
      }
    },
  },
  data() {
    return {
      matOpts: {
        color: "#fff",
        // side: THREE.DoubleSide,
        blending: THREE.AdditiveBlending,
        opacity: 1,
        depthWrite: false,
        transparent: true,
        precision: "lowp",
        side: THREE.DoubleSide,
      },
      // src: [],
      // index:0
      texture:null
    };
  },
  watch:{
    src(){
      // this.texture.load(this.src)
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
      // texture(texture){
      //   console.log("sdfasdfas",texture)
      //   this.texture = texture
      // }
  },
};
</script>


<style>
#ddd {
  position: fixed;
  left: 200px;
  top: 300px;
  z-index: 1000;
}
</style>