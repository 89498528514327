<template>
  <main>
    <!-- 标题 -->
    <header class="head">
      <div class="head-body">
        <div class="head_logo"><img src="static/LOL/logo.png" alt="" /></div>
        <div class="head_music" @click="music = !music">
          <img v-show="music" src="static/LOL/music0.png" />
          <img v-show="!music" src="static/LOL/music1.png" />
        </div>
        <audio src="static/SoundPosition.mp3" ref="BgMusic" :autoplay="music" loop controls="controls" hidden>
          Your browser does not support the audio element.
        </audio>
      </div>
    </header>
    <!-- 背景图 -->
    <div class="bg">
      <img src="static/LOL/bg3.jpg" alt="" />
    </div>
    <!-- 内容 -->
    <section ref="page0" class="page content">
      <div class="content_name">
        <span @click="Open_the_next_page(0)" ref="name" class="text name"
          >{{userData.name}}</span
        >
      </div>
    </section>
    <section ref="page1" class="page content flipped">
      <div class="page1" @click="Open_the_next_page(1)">
        <div class="my_name text">{{userData.name}}</div>
        <div class="my_post text">{{userData.post}}</div>
        <div class="my_phone text">{{userData.phone}}</div>
        <div class="my_title">个人简介</div>
        <div class="my_sketch text">{{userData.sketch}}</div>
      </div>
      <!-- <div  class="title text">个人简介</div> -->
    </section>
    <section ref="page2" class="page content flipped">
      <div @click="Open_the_next_page(2)" class="page2 ">
        <div class="company_name text">{{companyData.name}}</div>
        <div class="company_img"><img :src="companyData.img" alt=""></div>
        <div class="company_introduce">{{companyData.introduce}}</div>
        <div class="company_address">{{companyData.address}}</div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "businessCard",
  data() {
    return {
      page: 0,
      music: true,
    };
  },
  watch: {
    page(val) {
      this.$refs["page" + val].classList.remove("flipped");
    },
    music(val){
      if(val){
        this.$refs.BgMusic.play()
      }else{
         this.$refs.BgMusic.pause()
      }
    }
  },
  props: {
    userData:{
      type:Object,
      default(){
        return {
          name:'名字',
          phone:'159*******981',
          post:'职位',
          sketch:'面朝大海,春暖花开....'
        }
      }
    },
    companyData:{
      type:Object,
      default(){
        return {
          name:'公司名字',
          img:'static/Earth.png',
          introduce:'广州xxxx系统工程有限公司成立于1989年，是一家专注于xx产品和xxx产品研究、开发、生产及销售的高科技企业，总部及研发基地设立于风景秀丽的广州软件园，并在全国各地设有分支机构。公司技术和研发实力雄厚，是国家863项目的参与者，并被政府认定为“高新技术企业”。',
          address:'公司地址'
        }
      }
    }
  },
  mounted() {
    var _this = this;
    this.$refs.BgMusic.play()
    this.$refs.name.addEventListener(
      "animationstart",
      function () {
        console.log("animate start");
      },
      false
    );
    this.$refs.name.addEventListener(
      "animationend",
      function () {
        console.log("animate end");
        _this.$refs.name.style.opacity = 1;
      },
      false
    );
  },
  methods: {
    seTpage(val) {
      if (val > 2) {
        this.page = 0;
      } else {
        this.page = val;
      }
    },
    //打开下一页
    Open_the_next_page(val) {
      this.$refs["page" + val].classList.add("flipped");
      console.log(this.$refs["page" + val]);
      this.seTpage(val + 1);
    },
  },
  beforeDestroy(){
    this.$refs.BgMusic.pause()
  }
};
</script>

<style scoped>
main {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.head {
  width: 100%;
  padding: 50px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 222;
}
.head .head-body {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
}
.head .head_logo {
  flex: 1;
  text-align: left;
}
.head .head_music {
  flex: 1;
  text-align: right;
}
/* 内容 */

.content {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 333;
  transform: translateY(-50%);
}
/*第0页*/
.content_name {
  text-align: center;
  font-size: 50px;
}
.text {
  color: #fff;
  text-shadow: 0px 2px 0px #b5b5b5, -2px 2px 0 #333, 2px 2px 0 #333,
    -2px 3.5px 0 #333, 2px 3.5px 0 #333, 0.4px 3.55px 0 #333,
    -0.25px 3.55px 0 #333, -2px 5.55px 0 #222, -0.25px 5.55px 0 #222,
    0.25px 5.55px 0 #222, 0.75px 5.55px 0 #222;
}
.text:hover {
  color: #fff;
  text-shadow: 0px 3px 5px #0c92ff, -2px 2px 0 #333, 2px 2px 0 #333,
    -2px 3.5px 0 #333, 2px 3.5px 0 #333, 0.4px 3.55px 0 #333,
    -0.25px 3.55px 0 #333, -2px 5.55px 0 #222, -0.25px 5.55px 0 #222,
    0.25px 5.55px 0 #222, 0.75px 5.55px 0 #222;
}
.name {
  position: relative;
  opacity: 0;
  display: inline-block;
  animation: myfirst 5s cubic-bezier(0.3, 2.4, 0.85, 2.5) 2s 1 alternate;
  /* Safari 与 Chrome: */
  -webkit-animation: myfirst 5s cubic-bezier(0.3, 2.4, 0.85, 2.5) 2s 1 alternate;
}

/* 第1页 */
.page1 {
  text-align: center;
  font-size: 30px;
  color: whitesmoke;
}
.page1 .my_name {
  font-size: 30px;
}
.page1 .my_post {
  font-size: 30px;
}
.page1 .my_title {
  font-size: 16px;
  text-shadow: 0px 2px 0px #b5b5b5, -2px 2px 0 #333, 2px 2px 0 #333,
    -2px 3.5px 0 #333, 2px 3.5px 0 #333, 0.4px 3.55px 0 #333,
    -0.25px 3.55px 0 #333, -2px 3.55px 0 #222, -0.25px 3.55px 0 #222,
    0.25px 3.55px 0 #222, 0.75px 3.55px 0 #222;
}
.page1 .my_sketch {
  font-size: 30px;
}

/* 第2页 */
.page2 {
  color: whitesmoke;
  width: 90%;
  margin: 0 auto;
  height: 60%;
  padding-left: 50px;
  overflow: hidden;
  overflow-y: scroll;
}
.company_name{
  font-size: 50px;
  text-align: left;
}
.company_img{
  width: 95%;
  height: 300px;
}
.company_img img{
  width: 100%;
  height: 100%;
}
.company_introduce{

}
.company_address{

}

/* 背景图 */
.bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.bg img {
  width: 100%;
  height: 100%;
}

/* 动画效果 */
@keyframes myfirst {
  0% {
    top: 100px;
    opacity: 0;
    transform: scaleY(1);
  }
  25% {
    transform: scaleY(1.05);
    top: 50px;
  }
  50% {
    transform: scaleY(0.96);
    top: 100px;
  }
  75% {
    transform: scaleY(1.05);
    top: 50px;
  }
  100% {
    transform: scaleY(1);
    opacity: 1;
    top: 0px;
  }
}
/* Safari and Chrome */
@-webkit-keyframes myfirst {
  0% {
    top: 100px;
    opacity: 0;
    transform: scaleY(1);
  }
  25% {
    transform: scaleY(1.05);
    top: 50px;
  }
  50% {
    transform: scaleY(0.96);
    top: 100px;
  }
  75% {
    transform: scaleY(1.05);
    top: 50px;
  }
  100% {
    transform: scaleY(1);
    opacity: 1;
    top: 0px;
  }
}

/* 翻页效果 */
.page {
  cursor: pointer;
  position: absolute;
  transition: 2.5s transform;
  transform-style: preserve-3d;
  transform-origin: left center;
  z-index: 0;
}
.flipped {
  transform: rotateY(-180deg);
}
</style>