
<template>
    <div id="videoContainer" style="display:none;"></div>
</template>
<script>
// import * as THREE from 'three'
export default {
    name:'canvasVideo',
    inject: ['material'],
    data(){
        let video =  document.createElement('video')

        return{
            video: video
        }
    },
    props:{
        videourl:String,
        isplay:{
            default(){
                return false
            }
        }
    },
    watch:{
        isplay(){
            if(this.isplay){
                this.video.play()
                this.video.muted = false;
            }else{
                this.video.pause()
                // this.video.currentTime = 0;
                this.video.muted = true;
            }
        },
        videourl(val){
            this.getVideo(val)
        }
    },
    created(){
            this.getVideo(this.videourl)
    },
    mounted(){
        let container = document.getElementById('videoContainer');
        container.appendChild(this.video)
        // if (window.__wxjs_environment) {
            this.autoPlayAudio()
        // }
        //   this.video.play()
    },
    methods:{
        getVideo(val){
            this.video.src = val; // 设置视频地址
            this.video.autoplay = true; //要设置播放
            this.video.muted = true; //静音
            this.video.loop = true;
            this.video.crossOrigin="anonymous";
            // this.video["webkit-playsinline"]="true";
            this.video.setAttribute('playsinline', '');
            this.video.style="display:none"
            if(this.isplay){
                this.video.play()
            }
            this.material.video = this.video
        },
        autoPlayAudio() {

            let _this = this;
            if (window.WeixinJSBridge) {
                window.WeixinJSBridge.invoke('getNetworkType', {}, function () {
                    console.log('WeixinJSBridge', window.WeixinJSBridge)
                    _this.video.play();
                }, false);
            } else {
                document.addEventListener("WeixinJSBridgeReady", function () {
                    window.WeixinJSBridge.invoke('getNetworkType', {}, function () {
                        _this.video.play();
                    });
                }, false);
            }
            this.video.play();

        }
    },
    beforeDestroy(){
        this.video.pause()
        this.video = null
    }

}
</script>
