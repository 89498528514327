<template>
  <renderer :size="size" :insetsize="insetsize" :ISmousemove="true">
    <scene :gridHelper="GridHelpers">
      <fps-controls :model-url="modelUrl" :modelPosition="position" :modelRotation="rotation" :modelScale="scale" :radius="radius"
                    :initPosition="CameraPosition" :initRotation="CameraRotation" :isAddOct="isAddOct" :octBlackList="octBlackList"
                    :minAngle="minAngle" :maxAngle="maxAngle" :edition="edition" :mvSpeed="mvSpeed">
        <camera
            :position="CameraPosition"
            :rotation="CameraRotation"
        ></camera>
      </fps-controls>

      <audio-listener :position="{x:0,y:0,z:0}"></audio-listener>
      <slot></slot>
      <ambient-light hex="#fff" :intensity="0.3"></ambient-light>
      <raycaster></raycaster>
    </scene>
  </renderer>
</template>

<script>
    import FpsControls from "../vue_three_js/controls/FpsControls";

    export default {
        name: "freeMove",
        components: {FpsControls},
        data() {
            return {};
        },
        props: {
            modelUrl: {
                type: String,
                default() {
                    return '';
                }
            },
            edition: {
                type: Number,
                default() {
                    return 1
                }
            },
            position: {
                type: Object,
                default() {
                    return {x: 0, y: 0, z: 0};
                },
            },
            rotation: {
                type: Object,
                default() {
                    return {x: 0, y: 0, z: 0};
                },
            },
            scale: {
                type: Number,
                default() {
                    return 1
                }
            },
            isAddOct: {
                type: Boolean,
                default() {
                    return false;
                }
            },
            radius: {
                type: Number,
                default() {
                    return 1
                }
            },
            octBlackList:{
                type: Array,
                default(){
                    return [];
                }
            },
            size: { // 画布大小
                type: Object,
                default() {
                    return {w: 0, h: 0}
                }
            },
            insetsize: {
                //小地图大小
                type: Object,
                default() {
                    return {w: 0, h: 0}
                }
            },

            CameraPosition: {
                //相机位置
                type: Object,
                default() {
                    return {x: 0, y: 0, z: 0}
                }
            },
            CameraRotation: {
                // 相机角度
                type: Object,
                default() {
                    return {x: 0, y: 0, z: 0}
                }
            },
            mvSpeed: {
                type: Number,
                default() {
                    return 0.52
                }
            },
            // 网格
            GridHelpers: {
                type: Boolean,
                default() {
                    return true
                }
            },
            // 最小角度
            minAngle: {
                type: Number,
                default() {
                    return null
                }
            },
            // 最大角度
            maxAngle: {
                type: Number,
                default() {
                    return 1
                }
            },
        },
        mounted() {
        },

    };
</script>

<style>
  .vdr {
    top: 0;
  }
</style>
