import {
  TWEEN
} from "three/examples/jsm/libs/tween.module.min.js";
import * as THREE from "three";
import {
  Object
} from "core-js";
const tools = {
  /** 
      方法说明
    *@method 相机向某个位置移动
    *@for 所属类名
    *@param{Object}camera 相机对象
    *@param{Object}point  要移动的位置
    *@return {返回值类型} 返回值说明
    */
  Camera_position_movement(camera, point) {
    let position = camera.position;
    let tween
    tween = new TWEEN.Tween(position);
    tween.to({
        x: point.x,
        z: point.z,
      },
      2000
    );
    tween.easing(TWEEN.Easing.Cubic.InOut);
    tween.start();
    tween.onUpdate(function (object) {
      camera.position.x = object.x;
      camera.position.z = object.z;
    });
    tween.onComplete(() => {
      // this.End_of_animation()
    });
  },
  /** 
    方法说明
  *@method 设置模型位置居中
  *@for 所属类名
  *@param{Object}camera 相机对象
  *@param{Object}point  要移动的位置
  *@return {object} object 修改后物体mesh 对象
  */
  set_Model_centered(object, camera) {
    object.updateMatrixWorld();
    const box = new THREE.Box3().setFromObject(object);
    let mdlen = box.max.x - box.min.x; // 模型长度
    let mdwid = box.max.z - box.min.z; // 模型宽度
    let mdhei = box.max.y - box.min.y; // 模型高度
    let x1 = box.min.x + mdlen / 2; // 模型中心点坐标X
    let y1 = box.min.y + mdhei / 2; // 模型中心点坐标Y
    let z1 = box.min.z + mdwid / 2; // 模型中心点坐标Z
    object.position.set(-x1, -y1 / 2, -z1); // 将模型进行偏移
    const boxSize = box.getSize();
    const center = box.getCenter(new THREE.Vector3());
    object.position.x += object.position.x - center.x;
    object.position.y += object.position.y - center.y; //修改center.y可以设置模型整体上下偏移
    object.position.z += object.position.z - center.z;
    let cpygeometry = object.geometry.clone();
    object.geometry = cpygeometry;
    object.geometry.center();
    object.geometry.computeBoundingBox();
    // camera.position.copy(center);
    if (boxSize.x > boxSize.y) {
      camera.position.z = boxSize.x * -2.85;
    } else {
      camera.position.z = boxSize.y * -2.85;
    }
    camera.lookAt(0, 0, 0);

    return object;
  },
  /** 
      方法说明
    *@method camera位置移动
    *@for 所属类名
    *@param{Object}position 起点位置
    *@param{Object}targetPosition  终点的位置
    *@param{Number}time 时间毫秒
    *@param{camera}camera  相机对象
    *@return {object} tween tween对象
    */
  tweenPosition(self, target, time, camera) {
    let selfData = {
        p_x:self.p.x,
        p_y:self.p.y,
        p_z:self.p.z,
        r_x:self.r.x,
        r_y:self.r.y,
        r_z:self.r.z,
    }
    let targetData = {
      p_x:target.p.x,
      p_y:target.p.y,
      p_z:target.p.z,
      r_x:target.r.x,
      r_y:target.r.y,
      r_z:target.r.z,
   }
    let tween = new TWEEN.Tween(selfData);
    tween.to(targetData, time);
    tween.onUpdate(function (object) {
      camera.lookAt(new THREE.Vector3(object.r_x, object.r_y, object.r_z));
      camera.position.x = object.p_x;
      camera.position.z = object.p_z;
    });
    return tween;
  },
  /** 
    方法说明
    *@method 开启页面全屏
    *@for tools
    */
  fullscreen() {
    let element = document.body;
    // console.log(element);
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullScreen();
    }
  },
  /** 
  方法说明
  *@method 推出页面全屏
  *@for tools
  */
  exitFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  },
  /** 
      方法说明
    *@method 一组动画
    *@for 所属类名
    *@param{Object}camera 相机对象
    *@param{Array}p_r_List   位置p 角度 r 时间 time 的数组 { p: { x: 0, y: 0, z: 10 }, r: { y: 0 }, time: 2000, },
    *@param{function}start 动画开始
    *@param{function}The_end_of_the  动画结束
    *@return {返回值类型} 返回值说明
    */
  Animation_group(camera, p_r_List, start, The_end_of_the) {
    let tweenArray = [];
    /// 起始位置
    let list = p_r_List
    Object.assign(camera.position, list[0].p)
    Object.assign(camera.rotation, list[0].r)
    camera.rotation.x = 0
    camera.rotation.z = 0

    for (let i = 1; i < list.length; i++) {
      const target = list[i];
      const element = list[i - 1];
      let tween = this.tweenPosition(element, target, target.time, camera);
      tweenArray.push(tween);
    }
    for (let i = 0; i < tweenArray.length; i++) {
      const item = tweenArray[i];
      item.easing(TWEEN.Easing.Cubic.InOut);

      if (i == 0) {
        start()
        item.start();
      }
      if (i <= tweenArray.length - 2) {
        item.chain(tweenArray[i + 1]);
      }
    }
    tweenArray[tweenArray.length - 1].onComplete(() => {
      The_end_of_the()
      // this.End_of_animation() //停止动画
    });
  },
}

export default tools