<template>
  <renderer :size="size" :insetsize="insetsize">
    <scene :gridHelper="false">
      <orbit-controls
        :minDistance="minDistance"
        :maxDistance="maxDistance"
        :target="target"
        :autoRotate="autoRotate"
        :autoRotateSpeed="autoRotateSpeed"
      >
        <camera :position="CameraPosition" :rotation="CameraRotation"></camera>
      </orbit-controls>
      <audio-listener :position="{ x: 0, y: 0, z: 0 }"></audio-listener>
      <slot></slot>
      <Sphere :src="markerList" :index="currentIndex"></Sphere>
      <ambient-light hex="#fff" :intensity="0.3"></ambient-light>
      <raycaster></raycaster>
    </scene>
  </renderer>
</template>

<script>
export default {
  name: "panoramaWorld",
  props: {
    size: {
      // 画布大小
      type: Object,
      default() {
        return { w: 0, h: 0 };
      },
    },
    insetsize: {
      //小地图大小
      type: Object,
      default() {
        return { w: 0, h: 0 };
      },
    },
    CameraPosition: {
      //相机位置
      type: Object,
      default() {
        return { x: 0, y: 0, z: 0 };
      },
    },
    CameraRotation: {
      // 相机角度
      type: Object,
      default() {
        return { x: 0, y: 0, z: 0 };
      },
    },
    minDistance: {
      type: null,
      default() {
        return 100;
      },
    },
    maxDistance: {
      type: null,
      default() {
        return 1000;
      },
    },
    target: {
      type: Object,
      default() {
        return { x: 0, y: 0, z: 0 };
      },
    },
    //自动旋转
    autoRotate: {
      type: Boolean,
      default() {
        return true;
      },
    },
    //围绕物体旋转速度
    autoRotateSpeed: {
      type: null,
      default() {
        return 1;
      },
    },
    firstPanorama: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      markerList: [],
      currentIndex: 0,
      currentPanorama: "",
    };
  },
  watch: {
    currentIndex(newIndex) {
      //向子组件发送 ，当前组件数据
      this.currentPanorama = this.markerList[newIndex];
      this.$bus.$emit("current_panorama", this.currentPanorama);
    },
  },
  created() {
    this.markerList.push(this.firstPanorama);
    this.$bus.$on("launch_marker", this.panoramaImg);
    this.$bus.$on("click_marker", this.clickMarker);
  },
  mounted() {
    // 第一次发送当前组件数据
    this.currentPanorama = this.markerList[this.currentIndex];
    this.$bus.$emit("current_panorama", this.currentPanorama);
  },
  methods: {
    //收集标点的 全景图
    panoramaImg(PanoramaSrc) {
      let index = this.markerList.findIndex((item) => {
        return item == PanoramaSrc;
      });
      if (index == -1) {
        this.markerList.push(PanoramaSrc);
      }

    },
    //图片被点击触发的事件
    clickMarker(name) {
      let index = this.markerList.findIndex((item) => {
        return item == name;
      });
      if (index != -1) {
        this.currentIndex = index;

      }
      //this.$bus.$emit("current_panorama", this.currentPanorama);
    },
  },
  beforeDestroy() {
    this.$bus.$off("current_panorama");
  },
};
</script>

<style>
.vdr {
  top: 0;
}
</style>
