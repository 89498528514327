import Vue from 'vue'
import App from './App.vue'

//iView-UI
import ViewUI from 'view-design';
// import style
import 'view-design/dist/styles/iview.css';
Vue.use(ViewUI);

//Mint-UI
import { Toast, Indicator } from 'mint-ui'
import 'mint-ui/lib/style.css'
Vue.prototype.$toast = Toast
Vue.prototype.$indicator = Indicator
import mint from 'mint-ui'
Vue.use(mint)

//Muse-UI
import MuseUI from 'muse-ui'
import 'muse-ui/dist/muse-ui.css'
// import '@/assets/css/theme-dark.css'
Vue.use(MuseUI)

import vueThree from './components/vue-three/vue_three_js'
Vue.use(vueThree)

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import Axios from 'axios'
import router from './router'
// Axios.defaults.baseURL = '/api/'
Axios.defaults.withCredentials = true


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
