<script>
    import Object3D from "@/components/vue-three/vue_three_js/components/Object3D";
    import * as THREE from "three";
    import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
    import {DRACOLoader} from "three/examples/jsm/loaders/DRACOLoader";

    export default {
        name: "AcrossCurve",
        mixins: [Object3D],
        props: {
            lineName:{
                type: String,
                default() {
                    return "";
                },
            },
            modelsrc1: {
                type: String,
                default() {
                    return null;
                },
            },
            modelsrc2: {
                type: String,
                default() {
                    return null;
                },
            },
            modelsrc3: {
                type: String,
                default() {
                    return null;
                },
            },
            modelsrc4: {
                type: String,
                default() {
                    return null;
                },
            },
            renderorder: {
                type:Number,
                default(){
                    return 0
                }
            },
            edition:{
                type:Number,
                default(){
                    return 1
                }
            },
            scale: {
                type:Number,
                default(){
                    return 1
                }
            },
            minRightDis: {
                type:Number,
                default(){
                    return 80
                }
            },
            maxRightDis: {
                type:Number,
                default(){
                    return 100
                }
            },
            minLeftDis: {
                type:Number,
                default(){
                    return 50
                }
            },
            maxLeftDis: {
                type:Number,
                default(){
                    return 60
                }
            },
        },
        data() {
            let urlBoats = [];
            if (this.modelsrc1)
            {
                urlBoats.push(this.modelsrc1)
            }
            if (this.modelsrc2)
            {
                urlBoats.push(this.modelsrc2)
            }
            if (this.modelsrc3)
            {
                urlBoats.push(this.modelsrc3)
            }
            if (this.modelsrc4)
            {
                urlBoats.push(this.modelsrc4)
            }
            return {
                urlBoats:urlBoats,
                cars:[],
                offset: Math.random() / 40
            };
        },
        mounted(){

            setTimeout( ()=>{
                this.$global.scene.traverse((child) => {
                    if (child.name === this.lineName) {
                        let temArr = child.geometry.attributes.position.array;
                        let arr = []
                        temArr.forEach((child, ndx) => {
                            if (ndx % 3 == 0) {
                                let temD = [];
                                temD.push(temArr[ndx])
                                temD.push(temArr[ndx + 1])
                                temD.push(temArr[ndx + 2])
                                arr.push(temD)
                            }
                        })
                        let controlPoints = arr;
                        const p0 = new THREE.Vector3();

                        this.curve = new THREE.CatmullRomCurve3(
                            controlPoints.map((p) => {
                                p0.set(...p);
                                return [
                                    (new THREE.Vector3()).copy(p0)

                                ];
                            }).flat(),
                            true, 'centripetal', 0
                        );

                        let boatObjs= [], count = 0
                        this.loader = new GLTFLoader();

                        this.dracoLoader = new DRACOLoader();
                        this.dracoLoader.setDecoderPath("static/threex/draco/gltf/");
                        this.loader.setDRACOLoader(this.dracoLoader);

                        this.urlBoats.forEach(child => {
                            this.loader.load(child, glb => {
                                glb.scene.scale.set(this.scale, this.scale, this.scale)
                                // glb.scene.traverse((child)  => {
                                //     if (child.type === 'Mesh') {
                                //         child.castShadow = true;
                                //         // child.receiveShadow = true;
                                //     }
                                // })
                                boatObjs.push(glb.scene);

                                count++;
                                if (count === this.urlBoats.length) {//

                                    // console.log(boatObjs,count);
                                    for (let i = 0; i < 10; ++i) {
                                        let pos = i / 10 + Math.random() * 0.1;
                                        // console.log(pos);

                                        let point = this.curve.getPointAt(pos);
                                        point.y = -12
                                        let tangent = this.curve.getTangent(pos);
                                        let vec1 = new THREE.Vector3(-tangent.z, tangent.y, tangent.x)
                                        let vec2 = new THREE.Vector3(tangent.z, tangent.y, -tangent.x)
                                        let dvRight = this.maxRightDis - this.minRightDis
                                        let dvLeft = this.maxLeftDis - this.minLeftDis
                                        vec1.setLength(Math.random() * dvRight + this.minRightDis)
                                        vec2.setLength(Math.random() * dvLeft + this.minLeftDis)
                                        // console.log(vec1, vec2);

                                        let int1 = Math.floor(Math.random() * count)
                                        let int2 = Math.floor(Math.random() * count)
                                        // console.log(int1,int2);
                                        let boat1 = boatObjs[int1].clone()
                                        let boat2 = boatObjs[int2].clone();

                                        boat1.position.copy(new THREE.Vector3().copy(point).add(vec1))
                                        this.$global.scene.add(boat1);
                                        boat2.position.copy(new THREE.Vector3().copy(point).add(vec2))
                                        this.$global.scene.add(boat2)
                                        // console.log(boat1,boat2);

                                    }

                                }
                            })
                        });
                    }
                })

            }, 5000)

        },
    }
</script>

<style scoped>

</style>
