<template>
    <div id="box" ></div>
</template>

<script>
    export default {
        props:['src'],
        name: "comImg",
        mounted() {
            console.log('123',this.src)
            let id=document.getElementById('box')
            id.style.backgroundImage='url('+this.src+')'
            id.style.backgroundRepeat='no-repeat'
        }
    }
</script>

<style scoped>
#box{
    width: 45px;
    height: 45px;
}
</style>