<template>
  <Object3D :visible="visible" :name="name">
    <positional-audio
      loop
      :url="audiosrc"
      :volume="1"
      :position="position"
      :paused="toplay"
    ></positional-audio>
    <object3d>
      <mesh
        :rotation="rotation"
        :scale="scale"
        :position="position"
        :onRaycaster="audioplay"
      >
        <geometry type="Plane" :args="[5, 5]"></geometry>
        <material type="MeshBasic" :options="matOpts">
          <texture-loader
            :MappingIndex="Number(toplay)"
            :url="[suspend, play]"
          ></texture-loader>
        </material>
      </mesh>
    </object3d>
  </Object3D>
</template>

<script>
import * as THREE from "three";
export default {
  name: "AudioSwitch",
  props: {
    name:{
      type: String,
      default() {
        return "";
      },
    },
    position: {
      type: Object,
      default() {
        return { x: 0, y: 0, z: 0 };
      },
    },
    rotation: {
      type: Object,
      default() {
        return { x: 0, y: 0, z: 0 };
      },
    },
    scale: {
      type:Number,
      default(){
        return 1
      }
    },
    audiosrc: {
      type: String,
      default() {
        return "";
      },
    },
    play: {
      //开始图片路径
      type: String,
      default() {
        return "";
      },
    },
    suspend: {
      //暂停图片路径
      type: String,
      default() {
        return "";
      },
    },
    visible: {
      //模型显示隐藏
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      toplay: true,
      matOpts: {
        // color: 0x00ffff,
        side: THREE.DoubleSide,
        // blending: THREE.AdditiveBlending,
        opacity: 1,
        // depthWrite: false,
        transparent: true,
      },
    };
  },
  mounted() {},
  methods: {
    audioplay() {
      this.toplay = !this.toplay;
    },
  },
};
</script>

<style>
</style>