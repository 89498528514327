<script>
    import * as THREE from "three";
    import {LightningStorm} from "three/examples/jsm/objects/LightningStorm.js";
    import Object3D from "./../components/Object3D";

    export default {
        name: "lightning",
        inject: ["scene", "renderer"],
        mixins: [Object3D],
        props: {},
        data() {
            let curObj = new THREE.Group();
            let lightningColor = new THREE.Color(0xb0ffff);
            let outlineColor = new THREE.Color(0x00ffff);
            let lightningMaterial = new THREE.MeshBasicMaterial({
                color: lightningColor,
            });
            const rayDirection = new THREE.Vector3(0, -1, 0);
            let rayLength = 0;
            const vec1 = new THREE.Vector3();
            const vec2 = new THREE.Vector3();
            let rayParams = {
                radius0: 1,
                radius1: 0.5,
                minRadius: 0.3,
                maxIterations: 7,

                timeScale: 0.15,
                propagationTimeFactor: 0.2,
                vanishingTimeFactor: 0.9,
                subrayPeriod: 4,
                subrayDutyCycle: 0.6,

                maxSubrayRecursion: 3,
                ramification: 3,
                recursionProbability: 0.4,

                roughness: 0.85,
                straightness: 0.65,

                onSubrayCreation: function (segment, parentSubray, childSubray, lightningStrike) {
                    lightningStrike.subrayConePosition(segment, parentSubray, childSubray, 0.6, 0.6, 0.5);

                    // Plane projection

                    rayLength = lightningStrike.rayParameters.sourceOffset.y;
                    vec1.subVectors(childSubray.pos1, lightningStrike.rayParameters.sourceOffset);
                    const proj = rayDirection.dot(vec1);
                    vec2.copy(rayDirection).multiplyScalar(proj);
                    vec1.sub(vec2);
                    const scale = proj / rayLength > 0.5 ? rayLength / proj : 1;
                    vec2.multiplyScalar(scale);
                    vec1.add(vec2);
                    childSubray.pos1.addVectors(vec1, lightningStrike.rayParameters.sourceOffset);
                },
            };
            const storm = new LightningStorm({
                size: 1000,
                minHeight: 90,
                maxHeight: 200,
                maxSlope: 0.6,
                maxLightnings: 8,

                lightningParameters: rayParams,

                lightningMaterial: lightningMaterial,

                // onLightningDown: function (lightning) {
                //   // Add black star mark at ray strike
                // },
            });
            curObj.add(storm);
            let clock = new THREE.Clock();
            return {
                curObj,
                frame: null,
                storm,
                outlineColor,
                clock,
                currentTime: 0,
                timeRate: 1,
            };
        },
        created() {
        },
        mounted() {
            this.animation();
        },
        methods: {
            animation() {
                this.frame = requestAnimationFrame(this.animation);
                this.currentTime += this.timeRate * this.clock.getDelta();
                if (this.currentTime < 0) {
                    this.currentTime = 0;
                }
                this.storm.update(this.currentTime);
            },
            end() {
                if (this.frame) {
                    cancelAnimationFrame(this.frame);
                }
            },
        },
        beforeDestroy() {
            this.end()
        }
    };
</script>

<style>
</style>
