<template>
  <renderer :size="size" :insetsize="insetsize">
    <scene :gridHelper="GridHelper">
      <orbit-controls
        :minDistance="minDistance"
        :maxDistance="maxDistance"
        :target="target"
        :autoRotate="autoRotate"
        :autoRotateSpeed="autoRotateSpeed"
        :angle="angle"
        ref="control"
      >
        <camera
          :position="position"
          :rotation="CameraRotation"
        ></camera>
      </orbit-controls>
<!--        <audio-listener :position="{x:0,y:0,z:0}"></audio-listener>-->
      <slot></slot>
      <ambient-light hex="#fff" :intensity="0.1"></ambient-light>
      <raycaster></raycaster>
    </scene>
  </renderer>
</template>

<script>
export default {
  name: "freeShow",
  props: {
    size: { // 画布大小
      type: Object,
      default(){
        return {w:0,h:0}
      }
    },
    insetsize:{
      //小地图大小
      type:Object,
       default(){
        return {w:0,h:0}
      }
    },
    CameraPosition:{
      //相机位置
       type:Object,
       default(){
        return {x:0,y:0,z:0}
      }
    },
    CameraRotation:{
      // 相机角度
       type:Object,
       default(){
        return {x:0,y:0,z:0}
      }
    },
    minDistance: {
      type: null,
      default(){
        return 0
      }
    },
    maxDistance: {
      type: null,
      default(){
        return 1
      }
    },
    target:{
      type:Object,
      default(){
        return {x:0,y:0,z:0}
      }
    },
    //自动旋转
    autoRotate:{
      type: Boolean,
      default(){
        return true
      }
    },
    //围绕物体旋转速度
    autoRotateSpeed:{
      type: null,
      default(){
        return 1
      }
    },
    // 网格
    GridHelper:{
      type: Boolean,
      default(){
        return true
      }
    },
    //角度
    angle:{
      type:Number,
      dufault(){
        return 0.5
      }
    }
  },
  data(){
    return{
      position:{x:0,y:14,z:0},
    }
  },
  mounted() {
    this.position=this.CameraPosition;
    // sessionStorage.setItem('car',JSON.stringify(this.CameraPosition));
    // this.$bus.$on("emitCamera", () => {
    //   // _this.position.x=0;
    //   // _this.position.y=14;
    //   // _this.position.z=0;
    //   if(_this.$refs.control){
    //     _this.$refs.control.changes();
    //   }
    // });
    // let _self=this;
    // this.$bus.$on("emitCameras", () => {
    //   _self.position=JSON.parse(sessionStorage.getItem('car'));
    //     if(_self.$refs.control){
    //         _self.$refs.control.noChange();
    //     }
    // });
    // this.$bus.$on("closeCameras", (val) => {
    //   if(_self.$refs.control){
    //     _self.$refs.control.closeControls(val);
    //   }
    // });
  },
    beforeDestroy() {
      sessionStorage.removeItem('car')
    }

};
</script>

<style>
.vdr{
  top: 0;
}
</style>
