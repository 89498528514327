
<template>
  <div></div>
</template>
<script>
import * as THREE from "three";
export default {
  name: "RaycasterHover",
  data() {
    const material = new THREE.MeshPhysicalMaterial({
      color: "red",
      metalness: 0,
      roughness: 0.5,
      clearcoat: 1.0,
      clearcoatRoughness: 1.0,
      reflectivity: 1.0,
      emissive: "rgba(255,0,0,0.3)",
    });

    return {
      uuid: null,
      object: null,
      material,
      backups_material: null,
    };
  },
  inject: {
    renderer: { name: "renderer", default: null },
  },
  props: {
    Blockname: {
      type: String,
    },
  },
  created() {
    // this.$global.rendererDom.addEventListener("click", this.raycaster, true);
    this.$bus.$on("Renderer_mouse_move", this.raycaster);
  },
  mounted() {},
  beforeDestroy() {
    this.$bus.$off("Renderer_mouse_move", this.raycaster);
    this.$off("raycaster_hover");
  },
  methods: {
    raycaster(event) {
      // 取消事件的默认动作。
      event.preventDefault();
      let { w, h } = this.renderer.rendererDomSize;
      // 声明 raycaster 和 mouse 变量
      var raycaster = new THREE.Raycaster();
      var mouse = new THREE.Vector2();
      var X = event.offsetX;
      var Y = event.offsetY;
      // 通过鼠标点击位置,计算出 raycaster 所需点的位置,以屏幕为中心点,范围 -1 到 1
      mouse.x = (X / w) * 2 - 1;
      mouse.y = -(Y / h) * 2 + 1;
      //通过鼠标点击的位置(二维坐标)和当前相机的矩阵计算出射线位置
      raycaster.setFromCamera(mouse, this.renderer.camera);
      // 获取与射线相交的对象数组，其中的元素按照距离排序，越近的越靠前
      var intersects = raycaster.intersectObjects(
        this.renderer.scene.children,
        true
      );

      if (intersects.length > 0) {
        let object = intersects[0].object;
        if (object.name == this.Blockname) {
          if (this.object) {
            if (this.object.material) {
              this.reduction();
            }
          }
          return;
        }
        if (!this.uuid) {
          this.replace(object);
        } else if (this.uuid != object.uuid) {
          // 还原材质
          this.reduction();
          this.replace(object);
        }
      } else {
        if (this.object) {
          if (this.object.material) {
            // 还原材质
            this.reduction();
          }
        } else {
          this.object = null;
          this.uuid = "";
        }
      }

      //返回 射线 所穿透 物体的集合
      this.$emit("raycaster_hover", intersects);
    },
    // 替换材质
    replace(newMesh) {
      // 1.备份
      this.backups_material = newMesh.material;
      // 2. 替换材质
      newMesh.material = this.material;
      // 3. 记录数据
      this.object = newMesh;
      this.uuid = newMesh.uuid;
    },
    /**
     * @param 还原材质
     */
    reduction() {
      // 还原材质
      this.object.material = this.backups_material;
      this.uuid = "";
      this.object = null;
    },
  },
};
</script>

