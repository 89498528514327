<template>
  <object3d :position="position" :rotation="rotation" :visible="visible" :name="name">
    <mesh
        :rotation="{ y: -Math.PI / 2 }"
        :scale="scale"
        :position="{ x: 0, y: 0, z: 0 }"

    >
      <geometry type="Plane" :args="[10.4, 6]"></geometry>
      <material type="MeshBasic" :options="matOpts">
        <canvas-video :videourl="videourl" :isplay="toplay"></canvas-video>
        <video-texture></video-texture>
      </material>
    </mesh>
    <mesh
        :rotation="{ y: -1.57 }"
        :scale="1"
        :position="{ x: -0.1, y: -4, z: -8 }"
        name="isplay"
        :onRaycaster="VideoChange"
    >
      <geometry type="Plane" :args="[2, 2]"></geometry>
      <material type="MeshBasic" :options="matOpts">
        <texture-loader
            :MappingIndex="Number(toplay)"
            :url="[play, suspend]"
        ></texture-loader>
      </material>
    </mesh>
  </object3d>
</template>

<script>
    import * as THREE from "three";

    export default {
        name: "VideoSwitch",
        props: {
            name: {
                type: String,
                default() {
                    return "";
                },
            },
            position: {
                type: Object,
                default() {
                    return {x: 0, y: 0, z: -50};
                },
            },
            rotation: {
                type: Object,
                default() {
                    return {x: 0, y: 0, z: 0};
                },
            },
            scale: {
                type: Number,
                default() {
                    return 1
                }
            },
            videourl: {
                //视频路径
                type: String,
                default() {
                    return "static/tv.mp4";
                },
            },
            play: {
                // 开始图标
                type: String,
                default() {
                    return "static/img/play.png";
                },
            },
            suspend: {
                // 关闭图标
                type: String,
                default() {
                    return "static/img/suspend.png";
                },
            },
            visible: {
                type: Boolean,
                default() {
                    return true;
                },
            },
        },
        data() {
            return {
                toplay: false,
                matOpts: {
                    // color: 0x00ffff,
                    side: THREE.DoubleSide,
                    // blending: THREE.AdditiveBlending,
                    opacity: 1,
                    // depthWrite: false,
                    transparent: true,
                },
            };
        },
        methods: {
            VideoChange() {
                this.toplay = !this.toplay;
            },
        },
    };
</script>

<style>
</style>
