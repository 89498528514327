<template>
  <cssObject :scale="scale" 
  :position="position"
  :rotation="rotation"
  :name="name"
  :url="url"
  :size="size"
  > </cssObject>
</template>

<script>
import Object3D from "../vue_three_js/components/Object3D";
export default {
  name: "DomPlane",
  mixins: [Object3D],
  props: {
    size: {
      type: Object, // { w, h }
      // required: true,
      default() {
        return { w: 1920, h: 1080 };
      },
    },
    url:{
      type: String, 
      default() {
        return "https://www.yfzne.com/product.html";
      },
    }
  },
};
</script>

<style>
</style>