<template>
  <div>
    <object3d
      v-if="renderorder <= loaderorder"
      :position="position"
      :rotation="rotation"
      :name="name"
    >
      <mesh :options="Mshoptions" :scale="scale">
        <animation-action :clampWhenFinished="clampWhenFinished" :timeScale="timeScale" :play="play" :time="time" v-if="getFileType(modelsrc, 'gltf')">
          <m-gltf :gltf-url="modelsrc" :edition="edition" :process="process">
          </m-gltf>
        </animation-action>
        <animation-action :clampWhenFinished="clampWhenFinished" :timeScale="timeScale" :play="play" :time="time" v-if="getFileType(modelsrc, 'glb')">
          <m-gltf :gltf-url="modelsrc" :edition="edition" :process="process">
          </m-gltf>
        </animation-action>
        <animation-action v-if="getFileType(modelsrc, 'fbx')">
          <m-fbx :fbx-url="modelsrc" :process="process" :edition="edition">
            <!-- :edition="edition" -->
          </m-fbx>
        </animation-action>
      </mesh>
    </object3d>
  </div>
</template>

<script>
import Object3D from "../vue_three_js/components/Object3D";
export default {
  name: "model",
  mixins: [Object3D],
  props: {
    name:{
      type: String,
      default() {
        return "";
      },
    },
    position: {
      type: Object,
      default() {
        return { x: 0, y: 0, z: 0 };
      },
    },
    rotation: {
      type: Object,
      default() {
        return { x: 0, y: 0, z: 0 };
      },
    },
    modelsrc: {
      type: String,
      default() {
        return "static/Dracogltf/Duck.gltf";
      },
    },
    renderorder: {
      type:Number,
      default(){
        return 0
      }
    },
    edition:{
      type:Number,
      default(){
        return 1
      }
    },
    scale: {
      type:Number,
      default(){
        return 1
      }
    },
  },
  data() {
    return {
      loaderorder: 0,
      play: true,
      num: 1,
      clampWhenFinished:true,
      Mshoptions:{
        receiveShadow:true, //// 平面接收阴影,
        castShadow:true  // 立方体的阴影
      },
      time:0,
      timeScale:1,
    };
  },
  watch: {
  },
  mounted() {
    this.$bus.$on("time", (val)=>{
       this.time = val
    })
    this.$bus.$on("animation-switch",(val)=>{
      this.play = val
    })
    this.$bus.$on("animation-timeScale", (val)=>{
      this.timeScale = val
    });
  },
  methods: {
    process() {
      this.loaderorder += 1;
      this.$bus.$emit("modelloaded", this.loaderorder);
    },
    /** 返回文件 后缀名 */
    getFileType(filePath, fileName) {
      var startIndex = filePath.lastIndexOf(".");
      if (startIndex != -1) {
        var name = filePath
          .substring(startIndex + 1, filePath.length)
          .toLowerCase();
        return name == fileName;
      } else {
        return "";
      }
    },
    beforeDestroy() {
      this.loaderorder = 0;
    },
  },
  beforeDestroy(){
    this.$bus.$off("time");
    this.$bus.$off("animation-switch");
    this.$bus.$off("animation-timeScale");
  }
};
</script>

<style>
</style>
