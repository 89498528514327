<template>
  <Object3D :position="position" :scale="scale" :rotation="rotation" :name="name">
    <mesh :onRaycaster="Show">
      <geometry type="Plane" :args="args"></geometry>
      <material type="MeshBasic" :options="matOpts">
        <canvas-text
          :cirColor="cirColor"
          :dotColor="dotColor"
          :show="false"
        ></canvas-text>
        <canvas-texture></canvas-texture>
      </material>
    </mesh>
    <mesh v-if="tipsShow">
      <geometry type="Plane" :args="args"></geometry>
      <material type="MeshBasic" :options="matOpts">
        <texture-loader :url="src"></texture-loader>
      </material>
    </mesh>
  </Object3D>
</template>

<script>
import Object3D from "../vue_three_js/components/Object3D";
import * as THREE from "three";
export default {
  name: "ImgTips",
  mixins: [Object3D],
  props: {
    name:{
      type: String,
      default() {
        return "";
      },
    },
    position: {
      type: Object,
      default() {
        return { x: 0, y: 0, z: -50 };
      },
    },
    rotation: {
      type: Object,
      default() {
        return { x: 0, y: 0, z: 0 };
      },
    },
    scale: {
      type:Number,
      default(){
        return 1
      }
    },
    cirColor: {
      // 圆形颜色
      type: String,
      default() {
        return "rgba(0, 0, 0,0.5)";
      },
    },
    dotColor: {
      // 圆点和长条颜色
      type: String,
      default() {
        return "rgba(255, 255, 255,0.9)";
      },
    },
    src: {
      // 提示图片路径
      type: String,
      default() {
        return "static/img/play.png";
      },
    },
  },
  data() {
    return {
      tipsShow: false,
      matOpts: {
        color: 0xffffff,
        side: THREE.DoubleSide,
        // blending: THREE.AdditiveBlending,
        opacity: 1,
        // depthWrite: false,
        transparent: true,
      },
      args: [10, 10],
    };
  },
  methods: {
    Show() {
      this.tipsShow = !this.tipsShow;
    },
  },
  created() {},
  beforeDestroy() {
    this.Show = null;
  },
};
</script>

<style>
</style>
