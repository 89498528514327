<script>
import * as THREE from "three";
import Object3D from "./../components/Object3D";

export default {
  name: "DirectionLight",
  mixins: [Object3D],
  inject: ["scene"],
  props: {
    hex: { type: String || Number },
    intensity: Number,
    targetPosition: {
      type: Object,
      default() {
        return { x: 0, y: 0, z: 0 };
      },
    },
    bias: {
      //阴影贴图偏差，在确定曲面是否在阴影中时，从标准化深度添加或减去多少。
      //默认值为0.此处非常小的调整（大约0.0001）可能有助于减少阴影中的伪影
      type: Number,
      default() {
        return -0.0001;
      },
    },
    far: {
      type: Number,
      default() {
        return 35000;
      },
    },
    edge: {
      type: Number,
      default() {
        return 50;
      },
    },
    mapSize: {
      type: Object, // { w, h }
      default() {
        return { w: 2048, h: 2048 };
      },
    },
    castShadow: {
      type: Boolean, // { w, h }
      default() {
        return true;
      },
    },
  },

  data() {
    let curObj = this.obj;
    if (!curObj) {
      curObj = new THREE.DirectionalLight(
        new THREE.Color(this.hex),
        this.intensity
      );
      curObj.castShadow = this.castShadow;
      const d = this.edge;
      curObj.shadow.camera.left = -d;
      curObj.shadow.camera.right = d;
      curObj.shadow.camera.top = d;
      curObj.shadow.camera.bottom = -d;
      curObj.shadow.camera.far = this.far;
      curObj.shadow.bias = this.bias;
      curObj.shadow.mapSize.width = this.mapSize.w;
      curObj.shadow.mapSize.height = this.mapSize.h;
    }
    curObj.name = curObj.name || curObj.type;
    curObj.target = new THREE.Object3D();
    curObj.target.position.x = this.targetPosition.x;
    curObj.target.position.y = this.targetPosition.y;
    curObj.target.position.z = this.targetPosition.z;
    return { curObj };
  },
  created() {},
  mounted() {
    this.scene.add(this.curObj.target);
  },
};
</script>
