<template>
    <div ref="link" @click="localtions()"></div>
</template>

<script>
    export default {
        name: "superLinks",
        props:['src'],
        data(){
            return{

            }
        },
        methods:{
            localtions(){
                this.$nextTick(() => {
                    if(this.src.includes('http://')){
                        window.open(this.src);
                    }
                })
            },
        },
        mounted() {
            let child=this.$refs.link;

            let parent=child.parentNode.parentNode;


            this.$nextTick(()=>{
                child.style.position='absolute';
                child.style.top=parent.offsetTop+'px';
                child.style.left=parent.offsetLeft+'px';
                child.style.height=parent.offsetHeight+'px';
                child.style.width=parent.offsetWidth+'px'
            })
        }
    }
</script>

<style scoped>
</style>