<template>
  <renderer :size="size"  :insetsize="insetsize">
    <scene :gridHelper="GridHelpers">
      <pointer-lock-controls :wallname="wallname" :minAngle="minAngle" :maxAngle="maxAngle">
        <camera
          :position="CameraPosition"
          :rotation="CameraRotation"
        ></camera>
      </pointer-lock-controls>
      <audio-listener :position="{x:0,y:0,z:0}"></audio-listener>
      <slot></slot>
      <ambient-light hex="#fff" :intensity="0.3"></ambient-light>
      <raycaster></raycaster>
    </scene>
  </renderer>
</template>

<script>
export default {
  name: "freeWorld",
  data() {
    return {};
  },
  props: {
    size: { // 画布大小
      type: Object,
      default(){
        return {w:0,h:0}
      }
    },
    insetsize:{
      //小地图大小
      type:Object,
       default(){
        return {w:0,h:0}
      }
    },
    wallname:{
      //碰撞检测名字
      type:String,
      default(){
        return "$"
      }
    },
    CameraPosition:{
      //相机位置
       type:Object,
       default(){
        return {x:0,y:0,z:0}
      }
    },
    CameraRotation:{
      // 相机角度
       type:Object,
       default(){
        return {x:0,y:0,z:0}
      }
    },
    // 网格
    GridHelpers:{
      type: Boolean,
      default(){
        return true
      }
    },
    // 最小角度
    minAngle:{
      type: Number,
      default(){
        return null
      }
    },
    // 最大角度
    maxAngle:{
      type: Number,
      default(){
        return 1
      }
    },
  },
  mounted() {
  },

};
</script>

<style>
.vdr{
  top: 0;
}
</style>
