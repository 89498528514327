<template>
    <div>
        <div ref="dialog" @click="opens()" style="z-index: 99;"></div>
        <el-dialog
                title=""
                :visible.sync='visible'
                :modal="true"
                :modal-append-to-body="false"
                :show-close="false"
                :width='sizes.w+sizes.typeW'
                :close-on-click-modal="false"
        >
            <div class="close" @click="closes">x</div>
            <div :style="{height: sizes.h+sizes.typeH,overflow: 'auto'}">
                <slot></slot>
            </div>
        </el-dialog>

    </div>
</template>

<script>

    export default {
        name: "dialogComponent",
        props:['positions','sizes','close'],
        data(){
            return{
              visible:this.close
            }
        },
        mounted(){
            let child=this.$refs.dialog;
            // this.getParent(child.parentNode.parentNode);
            this.getParent(child.parentNode.parentNode.parentNode);



            // this.$nextTick(()=>{
            //     child.style.position='absolute';
            //     child.style.top=parent.offsetTop+'px';
            //     child.style.left=parent.left+'px';
            //     child.style.height=parent.offsetHeight+'px';
            //     child.style.width=parent.offsetWidth+'px'
            // })

        },
        methods:{
            getParent(val){
                  if(val.id){
                      let child=this.$refs.dialog;
                      this.$nextTick(()=>{
                          child.style.position='absolute';
                          child.style.top=val.offsetTop+'px';
                          child.style.left=val.left+'px';
                          child.style.height=val.offsetHeight+'px';
                          child.style.width=val.offsetWidth+'px'
                      });
                  }else {
                      let result=val.parentNode;
                      this.getParent(result)
                  }
            },
            opens(){
                this.visible=true;

            },
            closes(){
                this.visible=false;
            },
        },

    }
</script>

<style>

</style>
