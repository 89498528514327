<template>
    <div class="PhotoExhibition">
        <renderer :size="size" :insetsize="insetsize">
            <scene :gridHelper="false">
                <pointer-lock-controls :wallname="wallname" :minAngle="minAngle" :maxAngle="maxAngle">
                    <camera
                            :position="CameraPosition"
                            :rotation="CameraRotation"
                    ></camera>
                </pointer-lock-controls>
                <audio-listener :position="{ x: 0, y: 0, z: 0 }"></audio-listener>
                <slot></slot>
                <ambient-light hex="#ccc" :intensity="0.2"></ambient-light>
                <raycaster @Object3D_Raycaster="Raycaste"></raycaster>
                <mesh v-for="(item,i) in titleList" :visible="item.id!==isTitle" :scale="10" :position="item.position" :rotation="{ y: -Math.PI / 0.01 }" :key="'a'+i" :name="JSON.stringify(item.look)">
                    <Sprite :args="args"></Sprite>
                    <material type="Sprite" :options="matOpts">
                        <canvas-text
                                :show="true"
                                title=""
                                :shop="true"
                                textBgColor="rgba(0,0,0,0)"
                                :describe="item.name"
                        ></canvas-text>
                        <canvas-texture></canvas-texture>
                    </material>
                </mesh>

                <mesh v-for="(items,i) in tipList" :position="items.position" name="goods" :index="items.id" :key="i">
                    <geometry type="Plane" :args="args"></geometry>
                    <material type="MeshBasic" :options="matOpts">
                        <canvas-acircle
                        ></canvas-acircle>
                        <canvas-texture></canvas-texture>
                    </material>
                </mesh>


                <CssImg ref="dom" :titleId="titleId" :shopItem="shopItem" :position="itemPosition"  :scale="0.01"></CssImg>
                <!-- <cssDomBackground name="eee" :position="{x:0,z:0,y:0}" src="static/img/d.jpg"></cssDomBackground> -->
            </scene>
        </renderer>
            <model-pop-up titleName="VIRTUAL SUPERMARKET" :isShow.sync="visible" ref="shop">
                <template #model>
                    <div class="shop-img">
                        <img :src="oneGoods.img" />
                    </div>
                </template>
                <template #details>
                    <div><span class="p">&yen;</span><span class="price">{{oneGoods.price}}</span></div>
                    <div class="old-price">&yen;{{oneGoods.oldPrice}}.00</div>
                    <div class="goods-name">{{oneGoods.name}}</div>
                    <div class="weight">重量<span>{{oneGoods.weight}}g</span></div>
                    <div style="margin-top: 10px">
                        <div>用户浏览<span style="font-size: 14px;color: #0C9A9A"> {{oneGoods.glance}}</span></div>
                    </div>
                    <div>商品销量<span style="font-size: 14px;color: #0C9A9A"> {{oneGoods.sales}}</span></div>
                </template>
                <template #number>
                    <div style="display: flex;">
                        <div>数量：</div>
                        <div style="display: flex;">
                            <div style="width: 20px;height: 20px;border: 1px solid #ccc;
                    text-align: center;line-height: 18px;border-radius: 5px 0 0 5px;cursor: pointer" @click="dwNumber()">-</div>
                            <div style="width: 30px;height: 20px;border: 1px solid #ccc;
                    text-align: center;line-height: 19px;border-left: none;
                    border-right:none ;"><input v-model="goodsNumber" style="text-align: center;border: none;outline: none;width: 30px;height: 17px;" /></div>
                            <div style="width: 20px;height: 20px;border: 1px solid #ccc;
                    text-align: center;line-height: 18px;border-radius: 0 5px 5px 0;cursor: pointer" @click="upNumber()">+</div>
                        </div>
                    </div>
                </template>
            </model-pop-up>
        <div v-if="!isPc">
            <div class="Direction">
                <div class="Direction_keys">
                    <div class="key top" @touchstart="topdown" @touchend="topup">
                        <i class="el-icon-caret-top"></i>
                    </div>
                    <div class="key bottom" @touchstart="bottomdown" @touchend="bottomup">
                        <i class="el-icon-caret-bottom"></i>
                    </div>
                    <div class="key right" @touchstart="rightdown" @touchend="rightup">
                        <i class="el-icon-caret-right"></i>
                    </div>
                    <div class="key left" @touchstart="leftdown" @touchend="leftup">
                        <i class="el-icon-caret-left"></i>
                    </div>
                </div>
            </div>
        </div>
        <slot name="pane"></slot>
        <div v-if="!isModel" class="load">
            <load-animation>
            </load-animation>
        </div>
        <div class="Homing">
            <i @click="moveLook(homePosition)" @mouseover="home=true" @mouseleave="home=false" class="el-icon-location-information"></i>
            <div style="font-size: 16px;color: #fff;height: 20px;width: 100px"><div v-if="home">回到初始位置</div></div>
        </div>
    </div>
</template>

<script>
    import * as THREE from "three";
    import { TWEEN } from "three/examples/jsm/libs/tween.module.min.js";
    import  '../vue_three_js/rem'
    import CssImg from "../vue_three_js/css/CssImg";
    import LoadAnimation from "./loadAnimation";
    export default {
        components: {LoadAnimation, CssImg},
        name: "shopingTemplate",
        data() {
            return {
                centerDialogVisible: false,
                currentSrc: "",
                tween: null,
                animateinfo: null,
                audio: null,
                cameraIsPaly: false,
                visible:false,
                index:0,
                pos:{x:0,y:23,z:0},
                isMusic:true,
                isPc:true,
                matOpts: {
                    // color: 0xffffff,
                    side: THREE.DoubleSide,
                    // blending: THREE.AdditiveBlending,
                    opacity: 1,
                    // depthWrite: false,
                    transparent: true,
                },
                args: [3, 3],
                tipList:[
                    {position:{x:-40,y:16,z:1.5},rotation:0.01,id:1},
                    {position:{x:-40,y:12,z:1.5},rotation:0.01,id:2},
                    {position:{x:-40,y:7,z:1.5},rotation:0.01,id:3},
                    {position:{x:-40,y:3,z:1.5},rotation:0.01,id:4},
                    {position:{x:-40,y:16,z:12},rotation:0.01,id:5},
                    {position:{x:-40,y:6,z:12.5},rotation:0.01,id:6},
                    {position:{x:-40,y:16,z:-15},rotation:0.01,id:7},
                    {position:{x:-40,y:11,z:-15},rotation:0.01,id:8},
                    ],
                titleList:[
                    {id:1,position:{x:-41,y:29,z:9.5},name:'休闲食品',look:{move:{x:-36,y:18,z:-10},position:{x:-41,y:26,z:10},rotation:{x:-3.14,y:-0.03,z:-3.14},id:1}},
                    {id:2,position:{x:-41,y:26,z:-20},name:'打折促销',look:{move:{x:-36,y:18,z:-10},position:{x:-41,y:26,z:-20},rotation:{x:0,y:-0.03,z:0},id:2}},
                    {id:3,position:{x:-81,y:26,z:10},name:'新鲜水果',look:{move:{x:-65,y:18,z:5},position:{x:-81,y:26,z:10},rotation:{x:0,y:1.4,z:0},id:3}},
                    {id:4,position:{x:-81,y:26,z:-18},name:'健康饮品',look:{move:{x:-63,y:18,z:-17},position:{x:-81,y:26,z:-18},rotation:{x:0,y:1.4,z:0},id:4}},
                    {id:5,position:{x:-41,y:30,z:37.5},name:'休闲食品',look:{move:{x:-42,y:18,z:23},position:{x:-41,y:30,z:38},rotation:{x:-3.14,y:0.01,z:-3.14},id:5}},
                    {id:6,position:{x:-50,y:35,z:64},name:'饮料',look:{move:{x:-49,y:18,z:45},position:{x:-50,y:35,z:64},rotation:{x:-3.14,y:0.04,z:-3.14},id:6}},
                    ],
                goodsList:[
                    {id:1,img:'static/img/goods/tu1.jpg',price:'24.80',oldPrice:'49.90',weight:'102',name:'小纸杯蛋糕（六个一盒，随本店蛋糕一起配送，单买不送货）',glance:'99',sales:'88'},
                    {id:2,img:'static/img/goods/tu2.jpg',price:'16.10',oldPrice:'21.60',weight:'50',name:'儿童创意网红彩色糖豆彩虹巧克力味糖果奖励小孩瓶装不哭零食礼物',glance:'221',sales:'180'},
                    {id:3,img:'static/img/goods/tu0.jpg',price:'15.90',oldPrice:'28.10',weight:'420',name:'老先生麦芽饼干咸蛋黄夹心黑糖味休闲糕點饼干小圆饼零食散装整箱',glance:'210',sales:'120'},
                    {id:4,img:'static/img/goods/tu4.jpg',price:'14.50',oldPrice:'35.00',weight:'425',name:'旭源锅巴零食火锅店同款杂米粗粮脆香麻辣味网红吃货爆款小包装袋',glance:'456',sales:'234'},
                    {id:5,img:'static/img/goods/tu4.jpg',price:'14.50',oldPrice:'35.00',weight:'425',name:'旭源锅巴零食火锅店同款杂米粗粮脆香麻辣味网红吃货爆款小包装袋',glance:'456',sales:'234'},
                    {id:6,img:'static/img/goods/tu0.jpg',price:'15.90',oldPrice:'28.10',weight:'420',name:'老先生麦芽饼干咸蛋黄夹心黑糖味休闲糕點饼干小圆饼零食散装整箱',glance:'210',sales:'120'},
                    {id:7,img:'static/img/goods/tu0.jpg',price:'15.90',oldPrice:'28.10',weight:'420',name:'老先生麦芽饼干咸蛋黄夹心黑糖味休闲糕點饼干小圆饼零食散装整箱',glance:'210',sales:'120'},
                    {id:8,img:'static/img/goods/tu4.jpg',price:'14.50',oldPrice:'35.00',weight:'425',name:'旭源锅巴零食火锅店同款杂米粗粮脆香麻辣味网红吃货爆款小包装袋',glance:'456',sales:'234'},
                ],
                oneGoods:{},
                isModel:false,
                isFull:true,
                home:false,
                homePosition:{move:{x:2,y:18,z:-8},position:{x:-41,y:26,z:10},rotation:{x:-3.14,y:1,z:-3.14}},
                titleId:-1,
                shopList:[
                    {id:1,name:['婴幼儿滋养面霜','洗衣皂','高露洁牙刷','高露洁牙膏','生活用品','婴幼儿滋养面霜','婴幼儿滋养面霜','洗衣皂','高露洁牙刷','高露洁牙膏','生活用品','生活用品']},
                    {id:2,name:['打折促销','打折促销','打折促销','打折促销','打折促销','打折促销']},
                    {id:3,name:['苹果','哈密瓜','香蕉','梨','橘子','葡萄']},
                    {id:4,name:['纯牛奶','酸奶','燕麦牛奶','老酸奶','乳酸菌']},
                    {id:5,name:['薯片','水果干','饼干','坚果','辣条','面包']},
                    {id:6,name:['可口可乐','百世可乐','雪碧','红牛','农夫山泉','营养快线']},
                ],
                shopItem:[],
                itemPosition:{x:0,y:16,z:0},
                isTitle:-1,
                goodsNumber:1
            }
        },
        props: {
            size: {
                // 画布大小
                type: Object,
                default() {
                    return { w: 0, h: 0 };
                },
            },
            insetsize: {
                //小地图大小
                type: Object,
                default() {
                    return { w: 0, h: 0 };
                },
            },
            wallname: {
                //碰撞检测名字
                type: String,
                default() {
                    return "";
                },
            },
            CameraPosition: {
                //相机位置
                type: Object,
                default() {
                    return { x: 0, y: 0, z: 0 };
                },
            },
            CameraRotation: {
                // 相机角度
                type: Object,
                default() {
                    return { x: 0, y: 0, z: 0 };
                },
            },
            // 网格
            GridHelpers: {
                type: Boolean,
                default() {
                    return true;
                },
            },
            audioSrc:{
                type: String,
                default() {
                    return "";
                },
            },
            music:{
                type:String,
                default(){
                   return "";
                },
            },
            // 最小角度
            minAngle:{
                type: Number,
                default(){
                    return 0.5
                }
            },
            // 最大角度
            maxAngle:{
                type: Number,
                default(){
                    return 1
                }
            },
        },
        mounted(){
            this.$bus.$on("isLoad", () => {
                    setTimeout(this.loadMesh,this.loadTime*1000);
            });
            if(window.screen.width<960){
                this.isPc=false
            }
            this.$nextTick(()=>{
                this.$refs.dom.removeDoms();
                // this.$refs.audio.play()
            });
        },
        methods: {
            dwNumber(){
              if(this.goodsNumber>1){
                  this.goodsNumber--
              }
            },
            upNumber(){
                this.goodsNumber++
            },
            // fullScreen() {
            //     this.isFull=false;
            //     if(document.body.requestFullscreen) {
            //         document.body.requestFullscreen();
            //     } else if(document.body.mozRequestFullScreen) {
            //         document.body.mozRequestFullScreen();
            //     } else if(document.body.webkitRequestFullscreen) {
            //         document.body.webkitRequestFullscreen();
            //     } else if(document.body.msRequestFullscreen) {
            //         document.body.msRequestFullscreen();
            //     }
            // },
            // exitFullscreen() {
            //     this.isFull=true;
            //     if(document.exitFullscreen) {
            //         document.exitFullscreen();
            //     } else if(document.mozCancelFullScreen) {
            //         document.mozCancelFullScreen();
            //     } else if(document.webkitExitFullscreen) {
            //         document.webkitExitFullscreen();
            //     }
            // },
            loadMesh(){
                this.isModel=true;
            },
            topdown(event) {
                // console.log("event",this.$global.camera.position)
                event.keyCode = 38;
                this.$bus.$emit("key-down", event);
            },
            topup(event) {
                event.keyCode = 38;
                this.$bus.$emit("key-up", event);
            },
            bottomdown(event) {
                event.keyCode = 40;
                this.$bus.$emit("key-down", event);
            },
            bottomup(event) {
                event.keyCode = 40;
                this.$bus.$emit("key-up", event);
            },
            rightdown(event) {
                event.keyCode = 39;
                this.$bus.$emit("key-down", event);
            },
            rightup(event) {
                event.keyCode = 39;
                this.$bus.$emit("key-up", event);
            },
            leftdown(event) {
                event.keyCode = 37;
                this.$bus.$emit("key-down", event);
            },
            leftup(event) {
                event.keyCode = 37;
                this.$bus.$emit("key-up", event);
            },
            // musicPause(){
            //     this.isMusic=false;
            //     this.$refs.audio.pause()
            // },
            // musicPlay(){
            //     this.isMusic=true;
            //     this.$refs.audio.play()
            // },

            Raycaste(intersects) {
                this.moveBottom()
                console.log('1234567',this.$global.camera,intersects);
                //  console.log("targetPosition:{x:"+ intersects[0].point.x+",y:"+intersects[0].point.y+",z:"+intersects[0].point.z+"}")
                this.tween = new TWEEN.Tween(this.$global.camera.position);
                if (intersects.length > 0) {
                    if (
                        intersects[0].object.material.map &&
                        intersects[0].object.name != "地面"
                    ) {
                        this.centerDialogVisible = true;
                        this.currentSrc = intersects[0].object.material.map.image.currentSrc
                        // this.currentSrc = "static/hua/" + intersects[0].object.name + ".png";
                    }
                    if (intersects[0].object.name == "地面") {
                        this.animate();
                        let position = this.$global.camera.position;
                        let tween = this.tween;
                        tween = new TWEEN.Tween(position);
                        tween.to(
                            {
                                x: intersects[0].point.x,
                                z: intersects[0].point.z,
                            },
                            2000
                        );
                        tween.easing(TWEEN.Easing.Cubic.InOut);
                        tween.start();
                        let _this = this;
                        tween.onUpdate(function (object) {
                            _this.$global.camera.position.x = object.x;
                            _this.$global.camera.position.z = object.z;
                        });
                        tween.onComplete(() => {
                            cancelAnimationFrame(this.animateinfo); //停止动画
                        });
                    }if(intersects[0].object.name==='goods') {
                            this.$refs.shop.open();
                            let result=intersects[0].object.index;
                            this.getData(result);
                    }else if(intersects[0].object.type==='Sprite'){
                        this.moveLook(JSON.parse(intersects[0].object.name));
                    }
                }
            },
            moveLook(obj){
                let CameraPosition = this.$global.camera.position;
                Object.assign(this.$global.camera.position, CameraPosition);
                let tween1 = new TWEEN.Tween(CameraPosition).to(obj.move,3000).easing(TWEEN.Easing.Quadratic.InOut);
                let _this=this;
                let update = function (object) {
                    _this.$global.camera.lookAt(new THREE.Vector3(obj.position.x,obj.position.y,obj.position.z));
                    _this.$global.camera.position.x=object.x;
                    _this.$global.camera.position.z=object.z;
                };
                this.animate();
                tween1.onUpdate(update);
                tween1.start();
                if(obj.id){
                    this.shopList.forEach(item=>{
                        if(item.id===obj.id){
                            this.shopItem=item.name
                        }
                    });
                    let result=this.titleList.filter(item=>item.id===obj.id);
                    this.itemPosition.x=result[0].position.x;
                    this.itemPosition.y=result[0].position.y;
                    this.itemPosition.z=result[0].position.z;
                    this.titleId=obj.id;
                    this.isTitle=obj.id;
                }
            },
            moveBottom(){
                this.isTitle=-1;
                this.titleId=-1
            },
            getData(val){
                this.oneGoods={};
                this.goodsNumber=1;
                this.goodsList.forEach(item=>{
                    if(val===item.id){
                        this.oneGoods=item;
                    }
                });
            },
            animate() {
                this.animateinfo = requestAnimationFrame(this.animate);
                TWEEN.update();
            },

        },
    };
</script>
<style>

</style>
<style lang="less" scoped>
    .Homing {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 30px;
        z-index: 2000;
        text-align: center;
    }
    .model-box  /deep/ .btn_position{
        width: 200rem;
        bottom: 25rem;
    }
    .model-box  /deep/ .btn_text{
        line-height: 60rem;
    }
    .model-box  /deep/ .btn_position{
        right: 25rem;
        bottom: 35rem;
    }
    .model-box  /deep/ .btn{
        width: 150rem;
        height: 45rem;
        line-height: 45rem;
    }
    .model-box  /deep/ .btn_text{
        font-size: 23rem;
    }
    .model-box  /deep/ .details{
        padding-left: 10rem;
    }
    .PhotoExhibition {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        overflow-x: hidden;
    }
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    .goods-box{
        position: absolute;
        width: 100%;
        height: 90%;
        top: 10%;
        left: 0;
        background-color: #f2f2f2;
        transform: translateX(0);
    }
    .goods{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 50%;
        transform: translateX(-50%);
        padding-bottom: 50rem;
    }
    .box{
        top: 20rem;
        width: 70%;
        padding: 20rem 0;
        margin: 0 auto;
        background-color: #fff;
        overflow: auto;
    }
    /*.goods-img{*/
    /*    width: 210rem;*/
    /*    height: 210rem;*/
    /*    border-radius: 50%;*/
    /*    max-height: 210rem;*/
    /*    overflow: hidden;*/
    /*    margin-top: 20rem;*/
    /*    margin-right: 40rem;*/
    /*}*/

    /*.goods-img img{*/
    /*    width: 100%;*/
    /*    height: 100%;*/
    /*}*/
    .shop-img{
        width: 120px;height: 120px;border-radius: 50%;margin-right: 20px;margin-top: 10px;overflow: hidden;
    }
    .shop-img img{
        width: 100%;
        height: 100%
    }
    .box-center{
        height: 1rem;
        width: 100%;
        background-color: #ccc;
    }
    .price-box{
        width: 100%;
        padding: 10px;
    }
    .p{
        color: #B800E1;
    }
    .price{
        color: #B800E1;
        font-size: 30px;
    }
    .old-price{
        font-size: 16px;
        text-decoration: line-through;
        color: #ccc;
    }
    .goods-name{
        font-size: 18px;
        font-weight: bold;
        margin-top: 5px;
    }
    .bottoms{
        position: fixed;
        bottom: 0;
        height: 50rem;
        width: 100%;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        display: flex;
        background-color: #fff;
        z-index: 99;
    }
    .bottoms>div{
        width: 33.3%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
    }
    .buy ,.c-buy{
        width: 80%;
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #1E1E1E;
        border-radius: 20rem;
        font-size: 16rem;
    }
    .c-buy{
        color: #fff;
        background: -webkit-linear-gradient(left,#8700FF,#B800E1);
    }
    .weight{
        font-size: 12px;
        margin-top: 10px;
    }
    .weight span{
        margin-left: 10px;
    }
    @media (max-width: 960px){
        .look{
            font-size: 14px;
        }
        .box{
            width: 90%;
        }
        /*.goods-img{*/
        /*    width: 130rem;*/
        /*    height: 130rem;*/
        /*}*/
        /*.model-box  /deep/ .btn{*/
        /*    width: 150rem;*/
        /*    height: 45rem;*/
        /*    line-height: 45rem;*/
        /*}*/
    }

    .Direction {
        width: 110px;
        height: 110px;
        position: absolute;
        left: 40px;
        bottom: 40px;
        z-index: 999;
        transform: rotate(45deg);
        background-color: rgba(255,255,255,0.2);
        border-radius: 50%;
        /* display: none; */
    }
    .Direction > .Direction_keys {
        position: relative;
        width: 100%;
        height: 100%;
    }
    .Direction_keys > .top {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 10px 10px 0 0;
    }
    .Direction_keys > .bottom {
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: 0 0 10px 10px;
    }
    .Direction_keys > .left {
        position: absolute;
        left: 0;
        bottom: 0;
        border-radius: 10px 0 0 10px;
    }
    .Direction_keys > .right {
        position: absolute;
        right: 0;
        border-radius: 0 10px 10px 0;
    }
    .key {
        width: 40px;
        height: 40px;
        background: rgba(0, 0, 0,0.5);
        transform: rotate(-45deg);
        line-height: 40px;
        font-size: 20px;
        z-index: 333;
        color: #f2f2f2;
        text-align: center;
    }
      /deep/ * {
         .Homing {
        position: absolute;
        bottom: 10rem;
        left: 50%;
        transform: translateX(-50%);
        font-size: 30rem;
        z-index: 2000;
        text-align: center;
    }
    .Tailoring{
        height: 440rem!important;
    }
          .shop-top{
              height: 80px;
          }
    }
    @media (min-width: 512px) and (max-width: 960px) {
    }
    @media (max-width: 512px){
        .goods-name{
            font-size: 16px;
        }
    }
    @media (min-width: 960px){

    }
</style>
