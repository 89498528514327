<script>
import Base from '../components/Base'
import * as THREE from 'three'
export default {
  name: 'Material',
  mixins: [Base],
  inject: ['meshVm'],
  props: {
    options: { type: Object, default: () => ({}) },
    color: { type: Number },
    type: { type: String, default: '' }
  },

  data () {
    let mod = `${this.type}Material`
    let opts = { ...this.options }
    if (this.color) opts.color = this.color
    // console.log("opts:",opts)
    let material = new THREE[mod](opts)

    return { material }
  },
  provide () {
    return { material: this.material }
  },
  mounted () {
    this.meshVm.curObj.material = this.material
  },
  beforeDestroy () {
    this.material.dispose();
    this.material = null;
    this.meshVm.curObj.material = null;
  }
}
</script>
