<template>
  <Object3D>
    <slot></slot>
  </Object3D>
</template>

<script>

import { MapControls } from 'three/examples/jsm/controls/OrbitControls.js';
import Object3D from "../components/Object3D";
// import {Clock, MOUSE, TOUCH} from "three";
import {Clock} from "three";

export default {
  name: "MapControls",
  mixins: [Object3D],
  inject: {
    renderer: { name: 'renderer', default: null }
  },
  data() {
    return {
      controls: null,
      timer: null,
      frame: null,
    };
  },
  props: {
      screenSpacePanning: {
          //指定是否使用阻尼
          typeof: Boolean,
          default() {
              return false;
          },
      },
    enableDamping: {
      //指定是否使用阻尼
      typeof: Boolean,
      default() {
        return true;
      },
    },
    dampingFactor: {
      //动态阻尼系数
      typeof: Number,
      default() {
        return 0.05;
      },
    },

    minDistance: {
      //相机距离原点的最近距离
      typeof: Number,
      default() {
        return 1;
      },
    },
    maxDistance: {
      //相机距离远点的最远距离
      typeof: Number,
      default() {
        return 800;
      },
    },

    maxPolarAngle: {
      // camera 角度
      typeof: Number,
      default() {
        return 0.5;
      },
    },
    minPolarAngle:{
       // camera 你能够垂直旋转的角度的下限，范围是0到Math.PI，其默认值为0。
      typeof: Number,
      default() {
        return 0;
      }
    },
      enableZoom: {
          //缩放
          typeof: Boolean,
          default() {
              return true;
          },
      },
      enableRotate: {
          //缩放
          typeof: Boolean,
          default() {
              return true;
          },
      },
      enablePan: {
          // 禁用鼠标右键平移
          typeof: Boolean,
          default() {
              return true;
          },
      },
      autoRotate: {
          //是否自动旋转
          typeof: Boolean,
          default() {
              return false;
          },
      },

      panSpeed: {
          // camera 平移速度
          typeof: Number,
          default() {
              return 1;
          },
      },
      rotateSpeed:{
          // camera 旋转速度
          typeof: Number,
          default() {
              return 1;
          },
      },
      //围绕物体的旋转速度
      autoRotateSpeed:{
          type:Number,
          default(){
              return 10
          }
      },
    //围绕物体中心
    target:{
      type:Object,
      default(){
        return {x:0,y:0,z:0}
      }
    },
  },
  watch:{
    autoRotate(){
      this.setControls()
    },
  },
  mounted() {
    this.$bus.$on("target",()=>{
      this.setControls()
    });
    this.initControls()

    this.timer = new Clock();
    this.frame = this.animate();

  },
  beforeDestroy() {
    if (this.frame) {
      cancelAnimationFrame(this.frame);
    }
    if (this.controls) {
      this.controls.dispose();
      this.controls = null;
    }
    this.$bus.$off('target')
  },

  methods: {

    animate() {
      this.frame = requestAnimationFrame(this.animate);

      if(this.controls){
            this.controls.update();
      }
    },
    initControls(){
      let domElement = this.$global.rendererDom;
      let  camera = this.renderer.camera
      this.controls = new MapControls(camera, domElement);
      this.setControls()
    },
    setControls(){

        this.controls.screenSpacePanning = this.screenSpacePanning; // pan orthogonal to world-space direction camera.up
        // this.controls.mouseButtons.LEFT = MOUSE.PAN;
        // this.controls.mouseButtons.RIGHT = MOUSE.ROTATE;
        //
        // this.controls.touches.ONE = TOUCH.PAN;
        // this.controls.touches.TWO = TOUCH.DOLLY_ROTATE;

        this.controls.enableDamping = this.enableDamping; //指定是否使用阻尼
        this.controls.dampingFactor = this.dampingFactor; //动态阻尼系数

        this.controls.maxPolarAngle = Math.PI * this.maxPolarAngle;//相机最大角度
        this.controls.minPolarAngle = Math.PI * this.minPolarAngle;
        this.controls.minDistance = this.minDistance; //相机距离原点的最近距离
        this.controls.maxDistance = this.maxDistance; //相机距离远点的最远距离

        this.controls.enableZoom = this.enableZoom; //缩放
        this.controls.enableRotate = this.enableRotate;
        this.controls.enablePan = this.enablePan; // 禁用鼠标右键
        this.controls.autoRotate = this.autoRotate ; //是否自动旋转

        this.controls.rotateSpeed = this.rotateSpeed; //旋转速度
        this.controls.panSpeed = this.panSpeed; // camera 平移速度
        this.controls.autoRotateSpeed = this.autoRotateSpeed; //围绕物体的旋转速度

        // let position = new THREE.Vector3()
        // position.x = Number(this.target.x)
        // position.y = Number(this.target.y)
        // position.z = Number(this.target.z)
        // this.controls.target =position;
        this.controls.target.x=this.target.x;
        this.controls.target.y=this.target.y;
        this.controls.target.z=this.target.z;
        // this.controls.enablePan = this.enablePan; // 禁用鼠标右键
        // this.controls.panSpeed = this.panSpeed; // camera 平移速度
        console.log("control",this.controls)
        console.log("rendererSize", this.$global.renderer)
    }
  },
};
</script>
