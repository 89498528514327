<template>
  <div id="app" class="box">
<!--    <div id="nav">-->
<!--      <router-link to="/">Home</router-link> |-->
<!--      <router-link to="/about">About</router-link>-->
<!--    </div>-->
    <transition>
      <keep-alive>
        <router-view>
        </router-view>
      </keep-alive>
    </transition>
  </div>
</template>


<script>
    import Vconsole from 'vconsole';

    export default {
        name: 'app',
        data() {
            return {
                vConsole: new Vconsole()
            }
        }
    }
</script>


<style lang="less">
  * {
    margin:0;
    padding:0;
  }
  html,body{
    width: 100%;
    height:100%;
  }
  .box{
    width: 100%;
    height:100%;
  }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

  @media screen and (orientation: portrait) {

    #app {
      position: absolute;
      top: 0;
      left: 100vw;

      width: 100vh;
      height: 100vw;

      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
      transform-origin: 0 0;
    }
  }

  @media screen and (orientation: landscape) {
    #app {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
    }
  }
</style>
