<template>
    <div class="panes" style="position: relative;overflow: hidden" ref="pane">
       <slot></slot>
    </div>
</template>

<script>
    export default {
        props:['sizes'],
        name: "divComponent",
        mounted() {
            let box=this.$refs.pane;
            box.style.width=this.sizes.w+this.sizes.typeW;
            box.style.height=this.sizes.h+this.sizes.typeH;
        }
    }
</script>

<style scoped>
.panes{
    min-height: 20px;
    border: 1px solid #ccc;
}
</style>
