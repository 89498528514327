<template>
    <div class="pane" ref="pane">
        <audio  :src="music" loop="loop"  preload="auto"  ref="audio"></audio>
        <div v-if="isModel">
        <div class="color-box">
            <div v-for="(item,index) in color" :key="index" :class="[isColor===index?'color':' ']" @click="getColor(item.value,index)">
                <div :style="{'backgroundColor':'rgb('+item.value.r+','+item.value.g+','+item.value.b+')'}" class="color-list"></div><div v-if="isColor===index" class="color-p">{{item.text}}</div>
            </div>
        </div>
            <div  class="look-box fullScreen">
                <div v-if="isFull" class="look" @click="fullScreen()">全屏</div>
                <div v-if="!isFull" class="look" @click="exitFullscreen()">退出全屏</div>
            </div>
            <div class="look-box music">
                <div v-if="isMusic" class="look" @click="musicPause()">停止播放</div>
                <div v-if="!isMusic" class="look" @click="musicPlay()">播放音乐</div>
            </div>
        <div class="car-name">奥迪-奥迪Q7 2021款55 TF SI quattro S line 运动型</div>
        <div class="look-box">
        <div class="look" v-if="look" @click="getCamera()">查看内饰</div>
        <div class="look" v-if="!look" @click="getCameras()">查看车身</div>
        </div>
        <div class="m-height">
            <div class="box">
                <div class="close-item" v-if="isClose" @click="getLook(false)">停止旋转</div>
                <div class="close-item" v-if="!isClose" @click="getLook(true)">旋转观看</div>
            </div>
            <div class="box buy">
                <div class="close-item" @click="getPrice()">获取底价</div>
            </div>
            <div class="box buy">
                <div class="close-item" @click="getParameter()">参数配置</div>
            </div>
        </div>
        </div>
        <div v-if="!isModel" class="load">
            <div id="redball"></div>
            <div class="loading">3D模型加载中，请稍后...</div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
    import * as THREE from 'three'
    export default {
        name: "carShow",
        props:['loadTime','music','price','parameter'],
        data(){
          return{
              isColor:-1,
              colorData:[
                  {value:{r:0,g:0,b:0},text:'魔力黑'},
                  {value:{r:128,g:138,b:135},text:'天云灰'},
                  {value:{r:65,g:105,b:225},text:'纳瓦拉蓝'},
                  {value:{r:225,g:0,b:0},text:'斗牛士红'},
                  {value:{r:250,g:255,b:240},text:'冰川白'},
                  {value:{r:192,g:192,b:192},text:'花剑银'},
                  {value:{r:128,g:42,b:42},text:'凯撒棕'}
              ],
              colorN:[
                  {value:{r:200,g:197,b:192},text:'象牙白'},
                  {value:{r:247,g:238,b:214},text:'米色'},
                  {value:{r:128,g:42,b:42},text:'凯撒棕'}
              ],
              look:true,
              isClose:true,
              isModel:false,
              isMusic:true,
              color:[],
              isFull:true
          }
        },
        mounted() {
            this.color=this.colorData;
            this.$bus.$on("modelloaded", (val) => {
                if(val>=1){
                    setTimeout(this.loadMesh,this.loadTime*1000);
                }
            });
            this.$refs.audio.play()

        },
        methods:{
            loadMesh(){
                this.isModel=true;
                this.getColor(this.colorData[0].value,0);
            },
            musicPause(){
                this.isMusic=false;
                this.$refs.audio.pause()
            },
            musicPlay(){
                this.isMusic=true;
                this.$refs.audio.play()
            },
            getColor(v,index){
                this.isColor=index;
                let result=new THREE.Color("rgb("+v.r+","+v.g+","+v.b+")");
                this.$bus.$emit("emitColor",result);
            },
            getCamera(){
                this.color=this.colorN;
                this.look=false;
                this.$bus.$emit("emitCamera");
                this.$bus.$emit("changeColor",true);
                this.getColor(this.colorN[0].value,0);

            },
            getCameras(){
                this.color=this.colorData;
                this.look=true;
                this.$bus.$emit("emitCameras");
                this.$bus.$emit("changeColor",false);
                this.getColor(this.colorData[0].value,0);
            },
            getLook(val){
                this.$bus.$emit("closeCameras",val);
                this.isClose=val
            },
            getPrice(){
                window.open(this.price);
            },
            getParameter(){
                window.open(this.parameter)
            },
            fullScreen() {
                this.isFull=false;
                if(document.body.requestFullscreen) {
                    document.body.requestFullscreen();
                } else if(document.body.mozRequestFullScreen) {
                    document.body.mozRequestFullScreen();
                } else if(document.body.webkitRequestFullscreen) {
                    document.body.webkitRequestFullscreen();
                } else if(document.body.msRequestFullscreen) {
                    document.body.msRequestFullscreen();
                }
            },
            exitFullscreen() {
                this.isFull=true;
                if(document.exitFullscreen) {
                    document.exitFullscreen();
                } else if(document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if(document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                }
            }
        }
    }
</script>

<style scoped>
    .load{
        position: absolute;
        z-index: 99999;
        background-color: rgba(0,0,0,0.9);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center
    }
    .loading{
        color: #fff;
        margin-top: 45px;
    }
    #redball{
        border-radius: 50%;
        width: 30px;height: 30px;
        background-color: blue;
        background-image: radial-gradient(ellipse farthest-corner at 25% 25%,#4b8b8b,#fff);
        margin: 0 auto;
        margin-top:40vh;
        z-index: 999999;
    }
    #redball{
        transform-origin: center bottom;
        animation: bounce 1.3s cubic-bezier(0.30,2.40,0.85,2.50) infinite;
    }
    @keyframes bounce{
        from,to{
            transform: translateY(30px) scaleY(.98);
        }
        80%{
            transform: translateY(15px) scaleY(1.02);
        }
    }
    .pane{
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
.color-box{
    position: absolute;
    height: 40px;
    top: 20px;
    /*width: 300px;*/
    left: 50%;
    padding: 0 15px;
    transform: translateX(-50%);
    background-color: rgba(0,0,0,0.1);
    z-index: 999;
    display: flex;
    align-items: center;
    border-radius: 8px;
}
.color{
    height: 35px;
    padding: 0 8px;
    background-color: rgba(255,255,255,0.4);
    display: flex;
    align-items: center;
    margin-right: 10px;
    border-radius: 8px;
    overflow: hidden;
    white-space: nowrap;
}
.color-list{
    width: 25px;
    height: 25px;
    margin-right: 10px;
}
    .color-p{
        margin-left: -5px;
    }

    .look-box{
        position: absolute;
        top: 80px;right: 50px;
        z-index: 99999;
        width: 80px;
        height: 80px;
        line-height: 80px;
        font-size: 16px;
        text-align: center;
        background-color: rgba(0,0,0,0.5);
        border-radius: 50%;
    }
    .fullScreen{
        top: 180px;
    }
    .music{
        margin-top: 200px;
    }
    .m-height{
        position: absolute;
        height: 40px;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 999;
        display: flex;
        align-items: center;
        border-radius: 8px;
    }
    .look-box:hover{
        background-color: rgba(0,0,0,0.8);
    }
    .look{
        color: white;cursor: pointer;
    }
    .box{
        /*position: absolute;*/
        /*top: 80%;*/
        z-index: 99999;
        width: 100px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-color: rgba(0,0,0,0.5);
        cursor: pointer;
        border-radius: 8px;
    }
    @media (max-width: 512px){
        .look-box{
            zoom: 0.5;
            line-height: 80px;
            font-size: 14px;
            top: 150px;
            right: 40px;
        }
        .fullScreen{
            top: 250px;
        }

        .box{
            zoom: 0.8;
        }
    }
    @media (min-width: 512px){

        .look-box{
            zoom: 0.5;
            line-height: 80px;
            font-size: 14px;
            top: 150px;
            right: 40px;
        }
        .fullScreen{
            top: 250px;
        }
        .box{
            zoom: 0.8;
        }
    }
    @media (min-width: 960px){

        .look-box{
            zoom: 0.8;
        }
    }
    .box:hover{
        background-color: rgba(0,0,0,0.8);
    }
    .close{
        /*right: 0;*/
    }

    .close-item{
        color: #f2f2f2;
        font-size: 16px;
    }
    .buy{
       margin-left: 30px;
    }
    .car-name{
        position: absolute;
        top: 60px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9999;
    }
</style>
