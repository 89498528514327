<template>
  <div class="EquipmentDisplay">
    <renderer :size="size" :insetsize="insetsize" :ISmousemove="true">
      <scene :gridHelper="GridHelper">
        <orbit-controls
          :minDistance="minDistance"
          :maxDistance="maxDistance"
          :target="target"
          :autoRotate="rotate"
          :autoRotateSpeed="autoRotateSpeed"
          :angle="angle"
          ref="control"
        >
          <camera
            :position="CameraPosition"
            :rotation="CameraRotation"
          ></camera>
        </orbit-controls>
        <audio-listener :position="{ x: 0, y: 0, z: 0 }"></audio-listener>
        <slot></slot>
        <ambient-light hex="#fff" :intensity="0.1"></ambient-light>
        <raycaster @Object3D_Raycaster="Raycaste"></raycaster>
        <raycaster-hover Blockname="地面"></raycaster-hover>
      </scene>
    </renderer>
    <div class="load" v-if="loadMesh">
      <div id="redball"></div>
      <div class="loading">3D模型加载中，请稍后...</div>
    </div>
    <div class="Operation_tips" @click="tipsShow = false" v-show="tipsShow">
      <div class="tips_box">
        <div class="tips tips1">点击模型显示信息</div>
        <div class="tips tips2">拖动进度条控制动画</div>
        <!-- <div class="tips tips3">前后左右移动</div> -->
      </div>
    </div>
    <div class="full-screen" @click="fullscreenchange">
      <el-button type="info" v-show="full" plain>打开全屏</el-button>
      <el-button type="info" v-show="!full" plain>关闭全屏</el-button>
    </div>
    <div class="rotate" @click="rotate = !rotate">
      <el-button type="info" v-show="!rotate" plain>开始旋转</el-button>
      <el-button type="info" v-show="rotate" plain>停止旋转</el-button>
      <!-- <div class="button" v-show="!rotate"></div>
      <div class="button" v-show="rotate">停止旋转</div> -->
    </div>
    <div class="Information" v-if="Information">
      <div class="tip1">
        <div class="back1">
          <p class="tip_title"><span></span>装备信息</p>
          <div class="tip_con">
            <p>装备名称：{{ Information.title }}</p>
            <p>使用率：{{ Information.Utilization_Rate }}%</p>
            <!-- <p>入库数量：100</p> -->
            <!-- <p>出库数量：10</p> -->
            <p>库存数量：{{ Information.stock }}</p>
            <p>装备信息：{{ Information.desc }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="slider">
      <div class="block">
        <div>
          <div class="text" >
             <span class="demonstration">控制动画</span>
          </div>
         <div class="switch">
                <el-switch
              v-model="disabled"
              @change="switchAnmation"
              active-color="#13ce66"
              inactive-color="#eee"
            >
            </el-switch>
         </div>
        </div>

        <div>
          <el-slider
            v-model="sliderValue"
            :max="20"
            :disabled="disabled"
            :step="0.01"
          ></el-slider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from "three";
export default {
  name: "EquipmentDisplay",
  props: {
    size: {
      // 画布大小
      type: Object,
      default() {
        return { w: 0, h: 0 };
      },
    },
    insetsize: {
      //小地图大小
      type: Object,
      default() {
        return { w: 0, h: 0 };
      },
    },
    CameraPosition: {
      //相机位置
      type: Object,
      default() {
        return { x: 0, y: 0, z: 0 };
      },
    },
    CameraRotation: {
      // 相机角度
      type: Object,
      default() {
        return { x: 0, y: 0, z: 0 };
      },
    },
    minDistance: {
      type: null,
      default() {
        return 0;
      },
    },
    maxDistance: {
      type: null,
      default() {
        return 1;
      },
    },
    target: {
      type: Object,
      default() {
        return { x: 0, y: 0, z: 0 };
      },
    },
    //自动旋转
    autoRotate: {
      type: Boolean,
      default() {
        return true;
      },
    },
    //围绕物体旋转速度
    autoRotateSpeed: {
      type: null,
      default() {
        return 1;
      },
    },
    // 网格
    GridHelper: {
      type: Boolean,
      default() {
        return true;
      },
    },
    //角度
    angle: {
      type: Number,
      default() {
        return 0.5;
      },
    },
  },
  data() {
    return {
      loadMesh: true, //加载动画
      falg: true, //判断是否是PC
      tipsShow: true, // 操作提示信息
      rotate: false, // 控制物体旋转
      Information: null,
      InfoList: [],
      uuid: null,
      color: new THREE.Color(),
      object: null,
      full: true, //全屏
      sliderValue: 0,
      disabled: true,
    };
  },
  created() {
    this.IsPC();
    this.$bus.$on("modelloaded", (val) => {
      if (val >= 1) {
        let s = 0;
        if (this.falg) {
          s = 3;
        } else {
          s = 8;
        }
        setTimeout(() => {
          this.loadMesh = false;
        }, s * 1000);
      }
    });
    this.InfoList = [
      {
        id: "4_1",
        title: "机械臂",
        stock: 0,
        Utilization_Rate: 33.5,
        desc: ` 机械臂是自动执行工作的机器装置.它的任务是协助或取代人类工作的工作，例如生产业、建筑业，或是危险的工作。`,
      },
      {
        id: "11_1",
        title: "螺丝",
        stock: 99,
        Utilization_Rate: 11,
        desc: ` 组合螺丝,异形螺丝,专业生产防松螺丝,可以根据客户需求订做,量身订做.可以订做各种规格的防护头,防封螺丝,可根据您的需求来图设计,我们更专业,欢迎咨询,长度不限,欢迎来..`,
      },
      {
        id: "3_1",
        title: "机械臂底座",
        stock: 55,
        Utilization_Rate: 21,
        desc: ` 机械臂180度旋转底座,采用树脂绝缘板加工,强度较高,可承重5Kg负载。 本底座支持大多数的标准舵机。总而言之这是一个非常不错的机械臂旋转底座`,
      },
      {
        id: "27_1",
        title: "专业机械臂机箱",
        stock: 134,
        Utilization_Rate: 14,
        desc: ` 机箱,钣金机箱加工厂家,便携式机箱,工控机箱,各类服务器机箱,桌面式机箱,制作方便,设备支持,更低价格,更高的激光设备,欢迎咨询,加固服务器机箱,非标机箱OEM开发设计经验..`,
      },
      {
        id: "1_1",
        title: "机械臂底座",
        stock: 55,
        Utilization_Rate: 21,
        desc: ` 机械臂180度旋转底座,采用树脂绝缘板加工,强度较高,可承重5Kg负载。 本底座支持大多数的标准舵机。总而言之这是一个非常不错的机械臂旋转底座`,
      },
      {
        id: "5_1",
        title: "专业机械关节二",
        stock: 44,
        Utilization_Rate: 55,
        desc: ` 机械手关节是一种适用于靠近机体操作的传动形式。它像人手一样有肘关节，可实现多个自由度，动作比较灵活，适于在狭窄空间工作。`,
      },
      {
        id: "6_1",
        title: "专业机械关节一",
        stock: 44,
        Utilization_Rate: 55,
        desc: ` 机械手关节是一种适用于靠近机体操作的传动形式。它像人手一样有肘关节，可实现多个自由度，动作比较灵活，适于在狭窄空间工作。`,
      },
    ];
  },
  watch: {
    sliderValue(val) {
      this.$bus.$emit("animation-switch", true);
      this.switchTimeScale(0);
      this.$bus.$emit("time", val);
    },
    disabled(val) {
      if (val) {
        this.switchTimeScale(1);
        this.$bus.$emit("animation-switch", true);
      } else {
        this.$bus.$emit("animation-switch", false);
      }
    },
  },
  mounted() {},
  methods: {
    Raycaste(intersects) {
      if (intersects.length > 0) {
        let object = intersects[0].object;
        let id = this.InfoList.findIndex((item) => {
          return item.id == object.name;
        });
        if (id != -1) {
          this.Information = this.InfoList[id];
        } else {
          this.Information = null;
        }
      }
    },
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];

      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          this.falg = false;
          break;
        }
      }
    },
    fullscreenchange() {
      this.full = !this.full;
      let element = document.body;
      // console.log(element);
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullScreen();
      }
      // document.querySelector('canvas').height = document.body.clientHeight
      // var fullscreenElement =
      //   document.fullscreenElement ||
      //   document.mozFullScreenElement ||
      //   document.webkitFullscreenElement;
      var fullscreenEnabled =
        document.fullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.webkitFullscreenEnabled;
      if (fullscreenEnabled) {
        // 退出全屏模式!
        this.exitFullscreen();
      }
      // fullscreenElement.height = document.body.clientHeight;
    },
    // 退出全屏
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    },
    switchAnmation(val) {
      this.disabled = val;
    },
    switchTimeScale(val) {
      this.$bus.$emit("animation-timeScale", val);
    },
  },
  beforeDestroy() {
    this.$bus.$off("model_animationAction", this.animationAction);
  },
};
</script>
<style scoped lang="less">
@num: 16px;
@media screen and (max-width: 1030px) {
  /deep/ .el-button--info.is-plain {
    width: 4 * @num;
    height: 2 * @num;
    font-size: 9px !important;
    padding: 0;
  }
  .full-screen {
    position: absolute;
    top: 9.375 * @num;
    right: 0.5 * @num;
    z-index: 333;
    font-size: 1.875 * @num;
  }
  /* 按钮 */
  .rotate {
    position: absolute;
    top: 4 * @num;
    right: 0.5 * @num;
    z-index: 333;
  }
  .tips {
    font-size: 1.4 * @num !important;
  }
  .slider {
    left: 0;
    bottom: 3.125 * @num;
    & .block {
      width: 18.75 * @num;
      height: auto;
      margin: 0 auto;
      background-color: rgba(45, 124, 255, 0.4);
      border-radius: 1.875 * @num;
      padding: 0.5 * @num 1.25 * @num;
    }
  }
  .Information {
    position: absolute;
    left: 0.1875 * @num;
    top: 0.1875 * @num;
    z-index: 333;
    // background: rgba(0, 0, 0, 0.4);
    width: 14 * @num;
    height: 14 * @num;
    border-radius: 20px;
    .tip_title {
      font-size: 0.875 * @num !important;
    }
  }
}

.vdr {
  top: 0;
}
.EquipmentDisplay {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
/* 提示信息 */
.Operation_tips {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 555;
  background-color: rgba(0, 0, 0, 0.4);
}
.tips_box {
  position: relative;
  border-radius: 1.875 * @num;
  border: 2px solid #fff;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  text-align: left !important;
}
.tips {
  font-size: 1.875 * @num;
  padding-left: 3 * @num;
  color: rgb(255, 255, 255);
  position: absolute;
  width: 100%;
}
.tips1 {
  top: 30%;
  left: 0;
}
.tips2 {
  top: 47%;
  left: 0;
}
.tips3 {
  bottom: 18%;
  left: 8 * @num;
}
/* 加载动画 */
.load {
  position: absolute;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.9);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}
.loading {
  color: #fff;
  margin-top: 45px;
}
.loading_text {
  color: #ccc;
}
#redball {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: blue;
  background-image: radial-gradient(
    ellipse farthest-corner at 25% 25%,
    #4b8b8b,
    #fff
  );
  margin: 0 auto;
  margin-top: 40vh;
  z-index: 999999;
}
#redball {
  transform-origin: center bottom;
  animation: bounce 1.3s cubic-bezier(0.3, 2.4, 0.85, 2.5) infinite;
}
@keyframes bounce {
  from,
  to {
    transform: translateY(30px) scaleY(0.98);
  }
  80% {
    transform: translateY(15px) scaleY(1.02);
  }
}
/* 全屏 */
.full-screen {
  position: absolute;
  top: 9.375 * @num;
  right: 1.875 * @num;
  z-index: 333;
  font-size: 1.875 * @num;
}

/* 按钮 */
.rotate {
  position: absolute;
  top: 4 * @num;
  right: 1.875 * @num;
  z-index: 333;
}
.Information {
  position: absolute;
  left: 0.625 * @num;
  top: 0.1875 * @num;
  z-index: 333;
  // background: rgba(0, 0, 0, 0.4);
  width: 18.75 * @num;
  height: 18.75 * @num;
  border-radius: 20px;
}
/**/
.tip1 {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid rgba(45, 124, 255, 0.4);
}
/*带缝隙的背景*/
.back1 {
  width: calc(100% - 5px);
  height: calc(100% - 5px);
  border-radius: 5px;
  background: rgba(45, 124, 255, 0.4);
  margin: 5px auto;
  padding: 10px;
  box-sizing: border-box;
}
.tip_title {
  color: rgba(255, 255, 255, 0.8);
  font-size: 15px;
  padding: 1px 0 8px 0;
  margin: 0;

  span {
    float: left;
    margin: 5px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    border: 2px solid #41a2ff;
  }
}
.tip_con {
  padding-left: 20px;

  p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 12px;
    padding: 2px 0;
    margin: 0;
  }
}
.slider {
  position: absolute;
  left: 0;
  bottom: 3.125 * @num;
  z-index: 333;
  width: 100%;
  & .demonstration {
    color: #fff;
    font: 0.875 * @num;
  }
  & .block {
    width: 18.75 * @num;
    height: auto;
    margin: 0 auto;
    background-color: rgba(45, 124, 255, 0.4);
    border-radius: 1.875 * @num;
    padding: 0.5 * @num 1.25 * @num;
  & .block > div{
    width: 100%;
  }
    .text{
      width: 50%;
      display: inline-block;
    }
    .switch{
      width: 50%;
      display: inline-block;
    }
  }
}

</style>
