<script>
    import {PerspectiveCamera} from 'three'
    import Object3D from './Object3D'

    export default {
        name: 'Camera',
        mixins: [Object3D],
        props: {
            obj: {type: Object}
        },
        inject: {
            renderer: {name: 'renderer', default: null}
        },
        data() {
            let curObj = this.obj
            const {w, h} = this.$global.rendererSize // fixme
            if (!curObj) {
                curObj = new PerspectiveCamera(45, w / h, 0.1, 10000)
                // curObj.setViewOffset(w,h,500,500)
            }
            curObj.name = curObj.name || curObj.type

            return {curObj}
        },

        mounted() {
            this.renderer.camera = this.curObj
            if (!this.$global.camera) {
                this.$global.camera = this.curObj
            }
        },

        beforeDestroy() {
            this.curObj = null
        },
        methods:{

        }

    }
</script>
