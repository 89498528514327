<template>
  <div class="PhotoExhibition">
    <renderer :size="size" :insetsize="insetsize">
      <scene :gridHelper="false">
        <pointer-lock-controls :wallname="wallname">
          <camera
            :position="CameraPosition"
            :rotation="CameraRotation"
          ></camera>
        </pointer-lock-controls>
        <audio-listener :position="{ x: 0, y: 0, z: 0 }"></audio-listener>
        <slot></slot>
        <ambient-light hex="#ccc" :intensity="0.2"></ambient-light>
        <raycaster @Object3D_Raycaster="Raycaste"></raycaster>
        <!-- <cssDomBackground name="eee" :position="{x:0,z:0,y:0}" src="static/img/d.jpg"></cssDomBackground> -->
      </scene>
    </renderer>
      <slot name="pane"></slot>
    <div class="Direction" v-show="!falg">
      <div class="Direction_keys">
        <div class="key top" @touchstart="topdown" @touchend="topup">
          <i class="el-icon-top"></i>
        </div>
        <div class="key bottom" @touchstart="bottomdown" @touchend="bottomup">
          <i class="el-icon-bottom"></i>
        </div>
        <div class="key right" @touchstart="rightdown" @touchend="rightup">
          <i class="el-icon-right"></i>
        </div>
        <div class="key left" @touchstart="leftdown" @touchend="leftup">
          <i class="el-icon-back"></i>
        </div>
      </div>
    </div>
    <el-dialog
      title="油画"
      :visible.sync="centerDialogVisible"
      :modal-append-to-body="false"
      :width="dialogWidth"
      custom-class="el_dialog_class"
      center
    >
      <div class="dialog">
        <img v-show="!falg" ref="image" :src="imgSrc" :width="dialogWidth" />
        <img-zoom
          v-if="falg"
          :src="imgSrc"
          width="100%"
          :bigsrc="imgSrc"
          :configs="configs"
        ></img-zoom>
        <img ref="img" :src="currentSrc" />
      </div>
    </el-dialog>
    <div class="menu">
      <div class="menuEmbedded_box">
<!--        <div class="menu-item">-->
<!--          <div class="look-box" v-show="isFull" @click="fullScreen()">-->
<!--            打开全屏-->
<!--          </div>-->
<!--          <div class="look-box" v-show="!isFull" @click="exitFullscreen()">-->
<!--            退出全屏-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="menu-item" @click="audio_paly">-->
<!--          <div class="look-box" v-show="isPaused">播放音乐</div>-->
<!--          <div class="look-box" v-show="!isPaused">停止音乐</div>-->
<!--        </div>-->
        <div class="menu-item" @click="camera_paly">
          <div class="look-box" v-show="!cameraIsPaly">播放动画</div>
          <div class="look-box" v-show="cameraIsPaly">停止动画</div>
        </div>
        <div class="menu-item" @click="Homing_camera">
          <div class="look-box">回到起点</div>
        </div>
      </div>
    </div>
    <div class="load" v-if="loadMesh">
      <div id="redball"></div>
      <div class="loading">3D模型加载中，请稍后...</div>
      <div class="loading_text">请点击地面行走</div>
    </div>
    <div class="cameraIsPaly" v-show="cameraIsPaly"></div>
    <div class="Operation_tips" @click="tipsShow = false" v-show="tipsShow">
      <div class="tips_box">
        <div class="tips tips1">点击地板任意处进行移动</div>
        <div class="tips tips2">点击查看名画介绍</div>
        <div class="tips tips3">前后左右移动</div>
        <div class="Direction">
          <div class="Direction_keys">
            <div class="key top">
              <i class="el-icon-top"></i>
            </div>
            <div class="key bottom">
              <i class="el-icon-bottom"></i>
            </div>
            <div class="key right">
              <i class="el-icon-right"></i>
            </div>
            <div class="key left">
              <i class="el-icon-back"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from "three";
import { TWEEN } from "three/examples/jsm/libs/tween.module.min.js";
import imgZoom from "vue2.0-zoom";
export default {
  name: "PhotoExhibition",
  components: { imgZoom, },
  data() {
    return {
      configs: {
        width: 650,
        height: 350,
        maskWidth: 100,
        maskHeight: 100,
        maskColor: "red",
        maskOpacity: 0.2,
      },
      matOpts: {
        color: 0xffffff,
        side: THREE.FrontSide,
        // blending: THREE.AdditiveBlending,
        metalness: 0,
        roughness: 0,
        envMapIntensity: 1,

        // opacity: 1,
        // depthWrite: false,
        // transparent: true,
      },
      options: {
        wrapS: THREE.RepeatWrapping,
        wrapT: THREE.RepeatWrapping,
        repeat: [180, 180],
        anisotropy: 16,
        encoding: THREE.sRGBEncoding,
      },
      Mshoptions: {
        receiveShadow: true, //// 平面接收阴影,
        castShadow: true, // 立方体的阴影
      },
      SpotLightOptions: {
        castShadow: true,
        // shadow:{
        //   mapSize:{
        //     width : 512,
        //     height :512,
        //   },
        //   camera:{
        //     near:10,
        //     far:200,
        //     focus:1,
        //   }
        // }
        // spotLight.shadow.mapSize.height = 512;
        // spotLight.shadow.camera.near = 10;
        // spotLight.shadow.camera.far = 200;
        // spotLight.shadow.focus = 1;
      },
      falg: true,
      centerDialogVisible: false,
      currentSrc: "",
      tween: null,
      animateinfo: null,
      // audio: null,
      isPaused: true,
      cameraIsPaly: false,
      loadMesh: false,
      dialogWidth: "60%",
      tipsShow: true,
      imgSrc: "",
      isFull: true,
    };
  },
  props: {
    size: {
      // 画布大小
      type: Object,
      default() {
        return { w: 0, h: 0 };
      },
    },
    insetsize: {
      //小地图大小
      type: Object,
      default() {
        return { w: 0, h: 0 };
      },
    },
    wallname: {
      //碰撞检测名字
      type: String,
      default() {
        return "";
      },
    },
    CameraPosition: {
      //相机位置
      type: Object,
      default() {
        return { x: 0, y: 0, z: 0 };
      },
    },
    CameraRotation: {
      // 相机角度
      type: Object,
      default() {
        return { x: 0, y: 0, z: 0 };
      },
    },
    // 网格
    GridHelpers: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  watch: {
    falg() {
      if (!this.falg) {
        this.dialogWidth = "100%";
      }
    },
  },
  created() {
    this.IsPC();
    this.$bus.$on("modelloaded", (val) => {
      if (val >= 1) {
        let s = 0;
        if (this.falg) {
          s = 3;
        } else {
          s = 8;
        }
        setTimeout(() => {
          this.loadMesh = false;
        }, s * 1000);
      }
    });
  },
  mounted() {
  },
  methods: {
    Raycaste(intersects) {
      this.tween = new TWEEN.Tween(this.$global.camera.position);
      if (intersects.length > 0) {
        if (
          intersects[0].object.material.map &&
          intersects[0].object.name != "地面"
        ) {
          this.centerDialogVisible = true
          this.imgSrc = intersects[0].object.userData.url;
          this.currentSrc =intersects[0].object.userData.introduceUrl;
        }
        if (intersects[0].object.name == "地面") {
          this.animate();
          let position = this.$global.camera.position;
          let tween = this.tween;
          tween = new TWEEN.Tween(position);
          tween.to(
            {
              x: intersects[0].point.x,
              z: intersects[0].point.z,
            },
            2000
          );
          tween.easing(TWEEN.Easing.Cubic.InOut);
          tween.start();
          let _this = this;
          tween.onUpdate(function (object) {
            _this.$global.camera.position.x = object.x;
            _this.$global.camera.position.z = object.z;
          });
          tween.onComplete(() => {
            cancelAnimationFrame(this.animateinfo); //停止动画
          });
        }
      }
    },
    animate() {
      this.animateinfo = requestAnimationFrame(this.animate);
      TWEEN.update();
    },
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          this.falg = false;
          break;
        }
      }
    },
    topdown(event) {
      // console.log("event",this.$global.camera.position)
      event.keyCode = 38;
      this.$bus.$emit("key-down", event);
    },
    topup(event) {
      event.keyCode = 38;
      this.$bus.$emit("key-up", event);
    },
    bottomdown(event) {
      event.keyCode = 40;
      this.$bus.$emit("key-down", event);
    },
    bottomup(event) {
      event.keyCode = 40;
      this.$bus.$emit("key-up", event);
    },
    rightdown(event) {
      event.keyCode = 39;
      this.$bus.$emit("key-down", event);
    },
    rightup(event) {
      event.keyCode = 39;
      this.$bus.$emit("key-up", event);
    },
    leftdown(event) {
      event.keyCode = 37;
      this.$bus.$emit("key-down", event);
    },
    leftup(event) {
      event.keyCode = 37;
      this.$bus.$emit("key-up", event);
    },
    tweenList() {
      this.animate();
      let CameraPosition = { x: -256, y: 100, z: 10 };
      let CameraRotation = { x: 0, y: 1.57, z: 0 };
      Object.assign(this.$global.camera.position, CameraPosition);
      Object.assign(this.$global.camera.rotation, CameraRotation);

      let position = { x: 201, y: 100, z: 1 };
      let tween = this.tween;
      tween = new TWEEN.Tween(CameraPosition);
      tween.to(position, 2000);
      let _this = this;
      tween.onUpdate(function (object) {
        _this.$global.camera.position.x = object.x;
        _this.$global.camera.position.z = object.z;
      });

      tween.easing(TWEEN.Easing.Cubic.InOut);
      let rotation = { x: 0, y: -1.57, z: 0 };
      let tween2 = this.tween;
      tween2 = new TWEEN.Tween(CameraRotation);
      tween2.to(rotation, 2000);
      tween2.onUpdate(function (object) {
        _this.$global.camera.rotation.y = object.y;
      });

      tween2.easing(TWEEN.Easing.Cubic.InOut);
      tween2.onComplete(() => {
        this.cameraIsPaly = false;
        cancelAnimationFrame(this.animateinfo); //停止动画
      });
      tween.onComplete(() => {
        tween.chain(tween2);
        //  this.cameraIsPaly = false
        // cancelAnimationFrame(this.animateinfo); //停止动画
      });
      tween.start();
      // tween.start()
    },
    camera_paly() {
      this.cameraIsPaly = true;
      this.tweenList();
    },
    Homing_camera() {
      this.$global.camera.position.set(
        this.CameraPosition.x,
        this.CameraPosition.y,
        this.CameraPosition.z
      );
      Object.assign(this.$global.camera.rotation, this.CameraRotation);
    },
  },
  beforeDestroy() {
    //  cancelAnimationFrame(this.animateinfo); //停止动画
    this.audio = null;
    this.$bus.$off("key-up");
    this.$bus.$off("key-down");
  },
};
</script>

<style scoped lang="less" >
@num: 16px;
.vdr {
  top: 0;
}
.PhotoExhibition {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.PhotoExhibition /deep/ *{
  outline: none;
}
.Direction {
  width: 80px;
  height: 80px;
  position: absolute;
  left: 3.125 * @num;
  bottom: 5 * @num;
  z-index: 320;
  transform: rotate(45deg);
  /* display: none; */
}
.Direction > .Direction_keys {
  position: relative;
  width: 100%;
  height: 100%;
}
.Direction_keys > .top {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 0.1875 * @num;
}
.Direction_keys > .bottom {
  position: absolute;
  bottom: 0;
  right: 0;
}
.Direction_keys > .left {
  position: absolute;
  left: 0;
  bottom: 0;
}
.Direction_keys > .right {
  position: absolute;
  right: 0;
}
.key {
  width: 1.875 * @num;
  height: 1.875 * @num;
  border-radius: 1.875 * @num;
  overflow: hidden;
  background: rgb(235, 235, 235);
  transform: rotate(-45deg);
  text-align: center;
  line-height: 1.875 * @num;
  font-size: 1.25 * @num;
  z-index: 333;
}
.key:hover {
  background: #ccc;
}
.dialog {
  /* width: 100%;
  height: 60vh; */
  text-align: center;
}
.dialog img {
  max-width: 100%;
  /* height: 100vh; */
}
.load {
  position: absolute;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.9);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}
.loading {
  color: #fff;
  margin-top: 45px;
}
.loading_text {
  color: #ccc;
}
#redball {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: blue;
  background-image: radial-gradient(
    ellipse farthest-corner at 25% 25%,
    #4b8b8b,
    #fff
  );
  margin: 0 auto;
  margin-top: 40vh;
  z-index: 999999;
}
#redball {
  transform-origin: center bottom;
  animation: bounce 1.3s cubic-bezier(0.3, 2.4, 0.85, 2.5) infinite;
}
@keyframes bounce {
  from,
  to {
    transform: translateY(30px) scaleY(0.98);
  }
  80% {
    transform: translateY(15px) scaleY(1.02);
  }
}
.cameraIsPaly {
  position: absolute;
  z-index: 9999;
  background-color: rgba(210, 255, 230, 0.1);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.Operation_tips {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 555;
  background-color: rgba(0, 0, 0, 0.4);
}
.tips_box {
  position: relative;
  border-radius: 1.875 * @num;
  border: 2px solid #fff;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  text-align: left !important;
}
.tips {
  font-size: 1.875 * @num;
  padding-left: 3 * @num;
  color: rgb(255, 255, 255);
  position: absolute;
  width: 100%;
}
.tips1 {
  top: 30%;
  left: 0;
}
.tips2 {
  top: 47%;
  left: 0;
}
.tips3 {
  bottom: 18%;
  left: 8 * @num;
}

/deep/.el_dialog_class{
  overflow: auto !important;
}

@media screen and (max-width: 1030px) {
  .dialog {
    text-align: center;
  }
  .dialog img {
    max-width: 100%;
  }
  .tips_box {
    position: relative;
    border-radius: 1.875 * @num;
    border: 2px solid #fff;
    top: 10%;
    left: 10%;
    width: 80%;
    height: 75%;
    text-align: left !important;
  }
  .tips {
    font-size: 1.4 * @num;
    padding-left: 1 * @num;
    color: rgb(255, 255, 255);
    position: absolute;
    width: 100%;
  }
  .tips1 {
    top: 20%;
    left: 0.5 * @num;
  }
  .tips2 {
    top: 37%;
    left: 0.5 * @num;
  }
  .tips3 {
    bottom: 18%;
    left: 8.4 * @num;
  }
}

.menu {
  position: absolute;
  top: 100rem;
  right: 10rem;
  .look-box {
    width: 65rem;
    height: 65rem;
    line-height: 65rem;
    font-size: 12rem;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    cursor: pointer;
    color: white;
  }

  .look-box:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .menuEmbedded_box {
    position: relative;
    z-index: 333;
    font-size: 1.875 * @num;
    .menu-item {
     margin-top: 20px;
    }
  }
}

@media (max-width: 512px) {
  .look-box {
    zoom: 0.5;
    line-height: 80rem;
    font-size: 14rem;
    top: 150rem;
    right: 40rem;
  }
}
@media (min-width: 512px) {
  .look-box {
    zoom: 0.5;
    line-height: 80rem;
    font-size: 14rem;
    top: 150rem;
    right: 40rem;
  }
}
@media (min-width: 960px) {
  .menu {
    position: absolute;
    top: 190rem;
    right: 20rem;
  }
  .look-box {
    zoom: 1;
  }
}
</style>
