<script>
    import Base from "../components/Base";
    import * as THREE from "three";
    import ModelStorage from "./ModelStorage";

    import {DDSLoader} from "three/examples/jsm/loaders/DDSLoader.js";
    import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";

    export default {
        name: "MObj",
        mixins: [Base, ModelStorage],
        inject: ["meshVm"],
        props: {
            objUrl: String,
        },
        data() {
            return {
                manager: null,
                objLoader: null,
            };
        },
        created() {
            this.initial(this.objUrl);
        },
        methods: {
            requireModel() {
                let {baseUrl, objUrl} = this;
                new Promise((resolve) => {
                    this.manager = new THREE.LoadingManager();
                    this.manager.addHandler(/\.dds$/i, new DDSLoader());
                    this.objLoader = new OBJLoader(this.manager);
                    if (baseUrl) {
                        this.objLoader.setPath(baseUrl);
                    }
                    this.objLoader.load(objUrl, (obj) => {
                        this.meshVm.curObj = obj;
                        let model = {
                            name: this.objUrl,
                            model: obj.toJSON(),
                            edition: this.edition,
                            animation: [], // worker 传入对对象必须是可以拷贝的
                        };
                        resolve(model);
                    });
                });

            },
        },
        beforeDestroy() {
            this.manager.dispose();
            this.objLoader.dispose();
            this.manager = null;
            this.objLoader = null;
            this.meshVm.curObj = null;
        },
    };
</script>
