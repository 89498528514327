<template>
    <div class="load">
        <div id="redball"></div>
        <div class="loading">3D模型加载中，请稍后...</div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "loadAnimation",
    }
</script>

<style scoped>
    .load{
        position: absolute;
        z-index: 99999;
        background-color: rgba(0,0,0,0.9);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center
    }
    #redball{
        border-radius: 50%;
        width: 30px;height: 30px;
        background-color: blue;
        background-image: radial-gradient(ellipse farthest-corner at 25% 25%,#4b8b8b,#fff);
        margin: 0 auto;
        margin-top:40vh;
        z-index: 999999;
    }
    #redball{
        transform-origin: center bottom;
        animation: bounce 1.3s cubic-bezier(0.30,2.40,0.85,2.50) infinite;
    }
    @keyframes bounce{
        from,to{
            transform: translateY(30px) scaleY(.98);
        }
        80%{
            transform: translateY(15px) scaleY(1.02);
        }
    }
    .loading{
        color: #fff;
        margin-top: 45px;
    }
</style>
