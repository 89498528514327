<template>
  <div class="box">
<!--    <img alt="Vue logo" src="../assets/logo.png">-->
    <Social msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Social from '@/components/Social.vue'

export default {
  name: 'Home',
  components: {
      Social
  }
}
</script>
