<template>
  <direction-light
    :position="sun"
    :hex="hex"
    :intensity="intensity"
  ></direction-light>
</template>

<script>
import * as THREE from "three";
import { TWEEN } from "three/examples/jsm/libs/tween.module.min.js";
import { Sky } from "three/examples/jsm/objects/Sky";
export default {
  name: "sky",
  inject: ["scene", "renderer"],
  data() {
    let sky = new Sky();
    sky.scale.setScalar(450000);
    let sun = new THREE.Vector3();
    let time = {
      morning: {
        turbidity: 10,
        rayleigh: 3,
        mieCoefficient: 0.005,
        mieDirectionalG: 0.7,
        inclination: 0.49, // elevation / inclination
        azimuth: 0, // Facing front,
        exposure: 0.5,
      },
      noon: {
        turbidity: 12.8,
        rayleigh: 1.778,
        mieCoefficient: 0.022,
        mieDirectionalG: 0.997,
        inclination: 0.1843, // elevation / inclination
        azimuth: 0.2341, // Facing front,
        exposure: 0.2385,
      },
      night: {
        turbidity: 0.3,
        rayleigh: 0.026,
        mieCoefficient: 0.001,
        mieDirectionalG: 0.993,
        inclination: 0.7652, // elevation / inclination
        azimuth: 0.711, // Facing front,
        exposure: 0.362,
      },
      evening: {
        turbidity: 0,
        rayleigh: 0.035,
        mieCoefficient: 0,
        mieDirectionalG: 0,
        inclination: 0, // elevation / inclination
        azimuth: 0, // Facing front,
        exposure: 0.138,
      },
      snow: {
        turbidity: 0.3,
        rayleigh: 0.026,
        mieCoefficient: 0.001,
        mieDirectionalG: 0.993,
        inclination: 0.7652, // elevation / inclination
        azimuth: 0.711, // Facing front,
        exposure: 0.362,
      },
    };
    return { sky, effectController: null, sun, time };
  },
  props: {
    Time: {
      type: String,
      default() {
        return "morning";
      },
    },
    hex: {
      type: String,
      default() {
        return "red";
      },
    },
    intensity: {
      type: Number,
      default() {
        return 0;
      },
    },
    options: {
      type: null,
      default() {
        return null;
      },
    },
  },
  watch: {
    Time(val) {
      this.currentTime(val);
    },
    options(newVal) {
      let tween = new TWEEN.Tween(this.effectController);
      tween.to(newVal, 2000);
      tween.easing(TWEEN.Easing.Cubic.InOut);
      let _this = this;
      tween.onUpdate(function (object) {
        Object.keys(object).forEach((k) => {
          _this.effectController[k] = object[k];
        });
        _this.guiChanged();
      });
      tween.start();
    },
  },
  mounted() {
    this.effectController = {
      turbidity: 10,
      rayleigh: 3,
      mieCoefficient: 0.005,
      mieDirectionalG: 0.7,
      inclination: 0.49, // elevation / inclination
      azimuth: 0.25, // Facing front,
      exposure: this.renderer.curObj.toneMappingExposure,
    };
    if (this.options) {
      let object = JSON.parse(JSON.stringify(this.options));
      let _this = this;
      Object.keys(object).forEach((k) => {
        _this.effectController[k] = object[k];
      });
    }
    this.scene.add(this.sky);
    this.guiChanged();
  },
  methods: {
    guiChanged() {
      const uniforms = this.sky.material.uniforms;
      const sun = this.sun;
      const effectController = this.effectController;
      uniforms["turbidity"].value = effectController.turbidity;
      uniforms["rayleigh"].value = effectController.rayleigh;
      uniforms["mieCoefficient"].value = effectController.mieCoefficient;
      uniforms["mieDirectionalG"].value = effectController.mieDirectionalG;

      const theta = Math.PI * (effectController.inclination - 0.5);
      const phi = 2 * Math.PI * (effectController.azimuth - 0.5);

      sun.x = Math.cos(phi);
      sun.y = Math.sin(phi) * Math.sin(theta);
      sun.z = Math.sin(phi) * Math.cos(theta);

      uniforms["sunPosition"].value.copy(sun);

      this.renderer.curObj.toneMappingExposure = effectController.exposure;
    },
    currentTime(current) {
      let tween = new TWEEN.Tween(this.effectController);
      tween.to(this.time[current], 2000);
      tween.easing(TWEEN.Easing.Cubic.InOut);
      let _this = this;
      tween.onUpdate(function (object) {
        Object.keys(object).forEach((k) => {
          _this.effectController[k] = object[k];
        });

        _this.guiChanged();
      });
      tween.start();
    },
  },
};
</script>

<style  scoped>
</style>
