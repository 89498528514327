(function(doc, win) {
    var docEl = doc.documentElement,
        resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
        recalc = function() {
//          var clientWidth = docEl.clientWidth;
//          if(!clientWidth) return;
//          docEl.style.fontSize = 100 * (clientWidth / 750) + 'px';
var clientWidth = docEl.clientWidth;
                    if(!clientWidth) return;

                    if(clientWidth>=1920){
                        docEl.style.fontSize =  (clientWidth / 1920) + 'px';
                    }else if(clientWidth>=1680){
                        docEl.style.fontSize = (clientWidth / 1680) + 'px';
                    }else if(clientWidth>=1440){
                        docEl.style.fontSize =  (clientWidth / 1440) + 'px';
                    }
                    else if(clientWidth>=1366){
                        docEl.style.fontSize =  (clientWidth / 1366) + 'px';
                    }
                    else if(clientWidth>=1280){
                        docEl.style.fontSize =  (clientWidth / 1280) + 'px';
                    }
                    else if(clientWidth>=960){
                        docEl.style.fontSize =  (clientWidth / 960) + 'px';
                    }
                    else if(clientWidth>=768){
                        docEl.style.fontSize =  (clientWidth / 768) + 'px';
                    }
                    else if(clientWidth>=512){
                        docEl.style.fontSize =  (clientWidth / 512) + 'px';
                    }
                    else if(clientWidth>=414){
                        docEl.style.fontSize =  (clientWidth / 414) + 'px';
                    }
                    else if(clientWidth>=375){
                        docEl.style.fontSize =  (clientWidth / 375) + 'px';
                    }
                    else{
                        docEl.style.fontSize =  (clientWidth / 750) + 'px';
                    }
        };

    if(!doc.addEventListener) return;
    win.addEventListener(resizeEvt, recalc, false);
    doc.addEventListener('DOMContentLoaded', recalc, false);
})(document, window);

