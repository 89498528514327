<script>
import { LightProbeGenerator } from 'three/examples/jsm/lights/LightProbeGenerator';
import * as THREE from 'three'
import Base from '../components/Base'

export default {
  name: 'envMapTexture',
  mixins: [Base],
  inject: ['material','scene'],
  props: {
    url: Array,
    options: { type: Object, default: () => ({}) },
    intensity: {
      type: Number, default(){
        return 1
      }
    }
  },
  watch:{},
  data () {
    let lightProbe = new THREE.LightProbe();
    let { url, ...rest } = this.options
    url = url || this.url
    let texture
    if (url) {
      const loader = new THREE.CubeTextureLoader();
      if (this.baseUrl) {
        loader.setPath( this.baseUrl);
      }
      texture = loader.load( this.url, (cubeTexture)=>{
        cubeTexture.encoding = THREE.sRGBEncoding;
        lightProbe.copy( LightProbeGenerator.fromCubeTexture( cubeTexture ) );
        lightProbe.intensity = this.intensity
      });
    } 
    Object.keys(rest).forEach(k => {
      if (k === 'repeat') {
        texture.repeat.set(...rest[k])
      } else {
        texture[k] = rest[k]
      }
    })
    this.material.envMap = texture
    this.material.envMap.needsUpdate = true;
    return { texture ,lightProbe}
  },
  mounted () {
    this.scene.add( this.lightProbe );
    // this.$emit('update:obj', this.texture)
  },
  beforeDestroy () {
     this.texture.dispose()
    // this.$emit('update:obj', null)
  },
  destroyed () {
    this.material.envMap = null;
    this.texture.dispose();
    this.texture = null;
  }
}
</script>
