<template>
    <div></div>
</template>

<script>
    export default {
        name: "canvasAcircle",
        inject: ["material"],
        data() {
            let canvas = document.createElement("canvas");
            let context = canvas.getContext("2d");
            return {
                canvas,
                context,
            };
        },
        props:{
            cirColor:{   // 圆形颜色
                type:String,
                default(){
                    return "rgba(0,0,0,0.5)"
                }
            },
            dotColor:{ // 圆点颜色 常条颜色
                type:String,
                default(){
                    return  "rgba(255, 255, 255,0.9)"
                }
            }
        },
        created() {
                this.canvas.width = 100;
                this.canvas.height = 100;
                this.context.beginPath();
                this.context.arc(50, 50, 30, 0, 2 * Math.PI);
                this.context.fillStyle = this.cirColor;
                this.context.fill();
                this.context.stroke();
                this.context.beginPath();
                this.context.arc(50, 50, 10, 0, 2 * Math.PI);
                this.context.fillStyle = this.dotColor;
                this.context.fill();
                this.context.stroke();

            this.material.canvasimg = this.canvas;
        }
    }
</script>

<style scoped>

</style>
