<script>
    import * as THREE from "three";
    import { Water } from 'three/examples/jsm/objects/Water.js';
    export default {
        name: "ocean",
        props:{
          size:{
              type:Array,
              default() {
                  return [10000,10000];
              }
          }
        },
        inject: {
            renderer: { name: 'renderer', default: null }
        },
        data(){
          return{
              water:{},
          }
        },
        mounted() {
            const waterGeometry = new THREE.PlaneGeometry( 10000, 10000 );
            this.water = new Water(
                waterGeometry,
                {
                    textureWidth: 512,
                    textureHeight: 512,
                    waterNormals: new THREE.TextureLoader().load( 'static/ground/waternormals.jpg', function ( texture ) {

                        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

                    } ),
                    alpha: 1.0,
                    sunDirection: new THREE.Vector3(),
                    sunColor: 0xffffff,
                    waterColor: 0x001e0f,
                    distortionScale: 3.7,
                }
            );

            this.water.rotation.x = - Math.PI / 2;
            this.water.position.y = -12;
            console.log(this.renderer)
            console.log(this.$global)
            if (this.renderer.scene) {
                this.renderer.scene.add(this.water);
            }
            this.animate();
        },
        methods:{
            animate() {
                this.animateinfo = requestAnimationFrame(this.animate);
                this.water.material.uniforms.time.value += 1.0 / 30.0;
            },
        }
    }
</script>

<style scoped>

</style>
