<template>
<transition name="fade">
  <div v-show="isShow" class="ShareQRCode" @dblclick="close()">
    <div class="P-relative fillet">
      <div class="QR_container">
        <div class="qr">
          <canvas ref="share"></canvas>
        </div>
        <div class="copy-url">
          <input
            class="copy-input"
            type="text"
            ref="share_url"
            :value="share_url"
          />
          <el-button
            type="success"
            circle
            icon="el-icon-document-copy"
            @click="copyUrl"
          ></el-button>
        </div>
      </div>
    </div>
  </div>
</transition>
</template>

<script>
import QRCode from "qrcode";
export default {
  name: "ShareQRCode",
  props: {
    isShow:{
        type: Boolean,
      default() {
        return true
      },
    }
  },
  data() {
    return {
      share_url:null
    };
  },
  created() {},
  mounted() {
    this.share_url = window.location.href
    this.Share();
  },
  methods: {
    Share() {
      let opts = {
        errorCorrectionLevel: "H", //容错级别
        type: "image/png", //生成的二维码类型
        quality: 0.5, //二维码质量
        margin: 1, //二维码留白边距
        width: 280, //宽
        height: 280, //高
        text: "http://www.xxx.com", //二维码内容
        color: {
          dark: "#333333", //前景色
          light: "#fff", //背景色
        },
      };
      let QRCodeMsg =this.share_url || window.location.href; //生成的二维码为URL地址js
      let msg = this.$refs.share;
      // 将获取到的数据（val）画到msg（canvas）上
      QRCode.toCanvas(msg, QRCodeMsg, opts, function (error) {
        console.log(error);
      });
    },
    copyUrl() {
      var copyDOM = this.$refs.share_url;
      copyDOM.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令用户定义的代码区域用户定义的代码区域
      this.$notify({
        title: "成功",
        message: "复制链接成功",
        type: "success",
      });
       this.$emit("changeShow",false)
    },
    close(){
        this.$emit("changeShow",false)
    }
  },
  beforeDestroy() {},
};
</script>

<style scoped>
.ShareQRCode {
  display: inline-block;
  width: 100%;
  height: 100vh;
  background-color: rgb(51 51 51);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
}
.QR_container {
  width: 280px;
  margin: 0 auto;
}
.qr {
  margin-top: 100px;
  zoom: 1;
}
.qr canvas {
  width: 100%;
  height: auto;
}

.copy-url {
  margin-top: 15px;
  background-color: #232323;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  box-shadow: inset 0 5px 10px -5px #191919, 0 1px 0 0 #444;
  -moz-box-shadow: inset 0 5px 10px -5px #191919, 0 1px 0 0 #444;
  -webkit-box-shadow: inset 0 5px 10px -5px #191919, 0 1px 0 0 #444;
}
.copy-url .copy-input {
  outline-style: none;
  border: 0;
  background: none;
  border-radius: 3px;
  padding: 13px 14px;
  font-size: 14px;
  font-weight: 700;
  font-family: "Microsoft soft";
  color: rgb(201, 201, 201);
}
* >>> .el-button {
  float: right;
  margin: 2px;
}
.P-relative {
  position: relative;
}
.Tailoring {
  overflow: hidden;
}
.fillet {
  width: 100%;
  height: 100%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .qr {
        margin-top: 200px;
        zoom: 1;
    }
    .QR_container{
         zoom: 0.8;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
   .qr {
        margin-top: 200px;
        zoom: 1;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .qr {
        margin-top: 200px;
        zoom: 1;
    }
} 

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
   
} 

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.fade-enter-active {
  transition: all 0.3s ease;
}
.fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter, .fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>