<template>
    <div ref="box">
       {{text}}
            <slot>
            </slot>
    </div>
</template>

<script>
    export default {
        props:['text','font','fontSzie','weight','decorations','positions','degs','classify','position','zIndex'],
        name: "myText",
        data(){
            return{
                deg:0
            }
        },
        mounted() {
            // if(this.degs){
            //     this.deg=this.degs
            // }
            let box=this.$refs.box;
            box.style.position=this.positions.type;
            box.style.display=this.classify;
            box.style.zIndex = this.zIndex;
            if(this.positions.type!=='static'){
                box.style.top=this.positions.y+'px';
                box.style.left=this.positions.x+'px';
                box.style.right=this.position.right+'px';
                box.style.bottom=this.position.bottom+'px';
            }else{
                box.style.marginTop=this.positions.y+'px';
                box.style.marginLeft=this.positions.x+'px';
                box.style.marginRight=this.position.right+'px';
                box.style.marginBottom=this.position.bottom+'px';
            }
            box.style.fontSize=this.fontSzie.fontsizes+'px';
            box.style.fontFamily=this.font.fontType;
            box.style.color=this.font.color;
            box.style.fontWeight=this.weight.weights;
            box.style.textDecoration=this.decorations.decoration;
            // if (this.positions.center && this.positions.vetically) {
            //     box.style.position = 'absolute';
            //     box.style.left = '50%';
            //     box.style.top = '50%';
            //     box.style.transform = 'translate(-' + 50 + '%,-' + 50 + '%) rotateZ('+this.deg+'deg)'
            // } else if (this.positions.center) {
            //     box.style.position = 'absolute';
            //     box.style.left = '50%';
            //     box.style.transform = 'translateX(-' + 50 + '%) rotateZ('+this.deg+'deg)'
            // } else if (this.positions.vetically) {
            //     box.style.position = 'absolute';
            //     box.style.top = '50%';
            //     box.style.transform = 'translateY(-' + 50 + '%) rotateZ('+this.deg+'deg)'
            // }else {
            //     box.style.transform=`rotateZ(${this.degs}deg)`;
            // }
            // box.style.transform=`rotateZ(${this.degs}deg)`;
        }
    }
</script>

<style scoped>

</style>
