<template>
  <Object3D :name="name">
    <mesh
        :position="position"
        :scale="scale"
        :rotation="rotation"
        :onRaycaster="onRaycaster"
        :visible="visible"
    >
      <geometry type="Plane" :args="args"></geometry>
      <material type="MeshBasic" :options="matOpts">
        <texture-loader :url="ImgUrl"></texture-loader>
      </material>
    </mesh>
  </Object3D>
</template>

<script>
    import * as THREE from "three";
    import wx from 'weixin-js-sdk';
    import Axios from 'axios'

    export default {
        name: "HyperlinkImage",
        data() {
            return {
                matOpts: {
                    color: 0xffffff,
                    side: THREE.DoubleSide,
                    blending: THREE.NormalBlending,
                    opacity: 1,
                    depthWrite: true,
                    transparent: false,
                },
                args: [this.size.w, this.size.h],
            };
        },
        props: {
            name: {
                type: String,
                default() {
                    return "";
                },
            },
            position: {
                //位置
                type: Object,
                default() {
                    return {x: 0, y: 0, z: 0};
                },
            },
            rotation: {
                // 角度
                type: Object,
                default() {
                    return {x: 0, y: 0, z: 0};
                },
            },
            scale: {
                type: Number,
                default() {
                    return 1
                }
            },
            size: {
                type: Object, // { w, h }
                default() {
                    return {w: 16, h: 9};
                },
            },
            ImgUrl: {
                // 图片路径
                type: String,
                default() {
                    return "";
                },
            },
            url: {
                // 跳转链接
                type: String,
                default() {
                    return "";
                },
            },
            miniProgramPath:{
                type: String,
                default() {
                    return "";
                },
            },
            visible: {
                // 跳转链接
                type: Boolean,
                default() {
                    return true;
                },
            },
            editable: {
                // 跳转链接
                type: Boolean,
                default() {
                    return false;
                },
            },
        },
        mounted() {
        },
        methods: {
            onRaycaster() {
                let _this = this;
                if (this.editable) {
                    if (window.__wxjs_environment) {
                        wx.miniProgram.getEnv(function (res) {
                            // console.log() // true
                            if (res.miniprogram) {
                                wx.chooseImage({
                                    count: 1, // 默认9
                                    sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
                                    sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
                                    success: function (res) {
                                        console.log(res); // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
                                        wx.getLocalImgData({
                                            localId: res.localIds[0],
                                            success: res => {
                                                let base64 = res.localData;
                                                let mime = "image/png"
                                                if (window.navigator.userAgent.match(/iPhone|ipad|ipod|ios/i)) {
                                                    // base64 = res.localData.replace("data:image/jgp;base64,", "");
                                                    let arr = base64.split(',')
                                                    base64 = arr[1];
                                                    mime = arr[0].match(/:(.*?);/)[1]
                                                }

                                                var bytes = atob(base64);
                                                var ab = bytes.length;
                                                var ia = new Uint8Array(ab);
                                                for (var i = 0; i < ab; i++) {
                                                    ia[i] = bytes.charCodeAt(i);
                                                }
                                                let fileName = _this.getPicId() + ".png";
                                                console.log(fileName)
                                                let file = new File([ia], fileName, { type: mime });
                                                var formDataToUpload = new FormData();
                                                formDataToUpload.append("file", file);
                                                formDataToUpload.append("fileName", fileName);
                                                formDataToUpload.append("type", 1);
                                                let url = '/3dapi/fileup/upload/';
                                                Axios.post(url,formDataToUpload)
                                                    .then(res => {
                                                        console.log(res)
                                                        let url = location.protocol + '//' + location.hostname +  '/3dapi' + res.data.file.url
                                                        console.log(_this.name, url)

                                                        _this.$bus.$emit('imageUpdate', {name: _this.name, url: url})
                                                    })
                                                    .catch(err => {
                                                        console.log(err)
                                                    });
                                            }
                                        });
                                    }
                                })
                            }
                        })
                    }
                }
                else {
                    if (!this.url && !this.miniProgramPath) {
                        console.error('No url')
                        return;
                    }

                    console.log('window.__wxjs_environment:',window.__wxjs_environment)
                    if (window.__wxjs_environment && this.miniProgramPath) {

                        wx.miniProgram.getEnv(function (res) {
                            console.log('res: ',res) // true
                            if (res.miniprogram) {
                                wx.miniProgram.navigateTo({
                                    url: _this.miniProgramPath,
                                })
                            }
                        })
                    } else if (this.url) {
                        // window.open(this.url);
                        if (this.url[0] === '/' || this.url[0] === '.') {
                            this.$router.push(this.url)
                        }
                        else {
                            window.open(this.url);
                        }
                    }
                }

            },
            getPicId () {
                function s4() {
                    return Math.floor((1 + Math.random()) * 0x10000)
                        .toString(16)
                        .substring(1);
                }
                return 'pic'+s4() + s4() + '_' + s4()
            }
        },
    };
</script>

<style>
</style>
