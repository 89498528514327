<template>
  <div class="PhotoExhibition">
    <renderer
      :size="size"
      PhotoExhibition
      :insetsize="insetsize"
      :EventSwitching="!centerDialogVisible"
    >
      <scene :gridHelper="false">
        <pointer-lock-controls :wallname="wallname">
          <camera
            :position="CameraPosition"
            :rotation="CameraRotation"
            @update:obj="Main_scene_cameras"
          ></camera>
        </pointer-lock-controls>
        <audio-listener :position="{ x: 0, y: 0, z: 0 }"></audio-listener>
        <slot></slot>
        <ambient-light :intensity="0.2"></ambient-light>
        <raycaster @Object3D_Raycaster="Raycaste"></raycaster>
      </scene>
    </renderer>
    <div class="Direction" v-show="!falg">
      <div class="Direction_keys">
        <div class="key top" @touchstart="topdown" @touchend="topup">
          <i class="el-icon-top"></i>
        </div>
        <div class="key bottom" @touchstart="bottomdown" @touchend="bottomup">
          <i class="el-icon-bottom"></i>
        </div>
        <div class="key right" @touchstart="rightdown" @touchend="rightup">
          <i class="el-icon-right"></i>
        </div>
        <div class="key left" @touchstart="leftdown" @touchend="leftup">
          <i class="el-icon-back"></i>
        </div>
      </div>
    </div>
    <div class="menu">
      <div class="menuEmbedded_box">
        <div class="menu-item full-screen">
          <div class="look-box" v-show="isFull" @click="fullScreen()">
            打开全屏
          </div>
          <div class="look-box" v-show="!isFull" @click="exitFullscreen()">
            退出全屏
          </div>
        </div>
        <div class="menu-item music" @click="audio_paly">
          <div class="look-box" v-show="isPaused">播放音乐</div>
          <div class="look-box" v-show="!isPaused">停止音乐</div>
        </div>
        <div class="menu-item camera" @click="camera_paly">
          <div class="look-box" v-show="!cameraIsPaly">播放动画</div>
          <div class="look-box" v-show="cameraIsPaly">停止动画</div>
        </div>
        <div class="menu-item" @click="Homing_camera">
          <div class="look-box">回到起点</div>
        </div>
        <div class="menu-item share">
          <div>
            <div class="share_text" @click="Share">
              <div class="look-box">分享</div>
            </div>
            <share-qr-code
              :isShow="isShare"
              @changeShow="shareQR"
            ></share-qr-code>
          </div>
        </div>
      </div>
    </div>

    <div class="load" v-show="loadMesh">
      <div id="redball"></div>
      <div class="loading">3D模型加载中，请稍后...</div>
      <div class="loading_text">请点击地面行走</div>
    </div>
    <div class="cameraIsPaly" v-show="cameraIsPaly"></div>
    <div class="Operation_tips" @click="tipsShow = false" v-show="tipsShow">
      <div class="tips_box">
        <div class="tips tips1">点击地板任意处进行移动</div>
        <div class="tips tips2">点击查看瓷器介绍</div>
        <div class="tips tips3">前后左右移动</div>
        <div class="Direction">
          <div class="Direction_keys">
            <div class="key top">
              <i class="el-icon-top"></i>
            </div>
            <div class="key bottom">
              <i class="el-icon-bottom"></i>
            </div>
            <div class="key right">
              <i class="el-icon-right"></i>
            </div>
            <div class="key left">
              <i class="el-icon-back"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗  :isShow.sync="centerDialogVisible" -->
    <model-pop-up
      ref="popUp"
      :isShow.sync="centerDialogVisible"
      @close="changIsShow"
    >
      <template v-slot:model>
        <div class="renderer">
          <renderer
            :size="{ w: 0, h: 0 }"
            :EventSwitching="false"
            :insetsize="{ w: 0, h: 0 }"
            :alpha="true"
          >
            <scene :gridHelper="false" @update:obj="secondScene">
              <orbit-controls
                :rotation="{ y: -Math.PI }"
                :minDistance="0"
                :maxDistance="880"
                :autoRotate="true"
                :minPolarAngle="0.5"
                ref="control"
                :target="target"
              >
                <camera
                  :position="{ x: 100, y: 120, z: 0 }"
                  :rotation="{ x: 0, y: 0, z: 0 }"
                  @update:obj="secondCamare"
                ></camera>
              </orbit-controls>
              <object-3-d :obj="obj"></object-3-d>
              <direction-light
                :position="{ x: 100, y: 120, z: 0 }"
                hex="#fff"
                :intensity="0.3"
              ></direction-light>
              <direction-light
                :position="{ x: 0, y: -120, z: -200 }"
                hex="#ccc"
                :intensity="0.1"
              ></direction-light>
              <direction-light
                :position="{ x: -100, y: 120, z: 0 }"
                hex="#ccc"
                :intensity="0.2"
              ></direction-light>
              <direction-light
                :position="{ x: 0, y: -120, z: 200 }"
                hex="#fff"
                :intensity="0.1"
              ></direction-light>
              <!-- <texture-scene bgCorlor="#fff"></texture-scene> -->
              <ambient-light hex="#ccc" :intensity="0.2"></ambient-light>
            </scene>
          </renderer>
        </div>
      </template>
      <template v-slot:details>
        <div class="details_box">
          <div class="introduce_box">
            <div class="introduce_title">{{ singleIntroduce.title }}</div>
            <div class="introduce_text">{{ singleIntroduce.introduce }}</div>
          </div>
          <div class="introduce_size">
            <div class="size_title">尺寸:</div>
            <div class="size_title">{{ singleIntroduce.size }}</div>
          </div>
          <div class="introduce_Price">
            <div class="size_title">价格:</div>
            <div class="size_title">
              &yen;<span class="Price">{{ singleIntroduce.Price }}</span>
            </div>
          </div>
        </div>
      </template>
      <template #english_title>
        <div class="Chinese_title">青花瓷</div>
        <div class="english_title">BLUE AND WHITE PORCELAIN</div>
      </template>
    </model-pop-up>
  </div>
</template>

<script>
import * as THREE from "three";
import { TWEEN } from "three/examples/jsm/libs/tween.module.min.js";
import tools from "./Tools_and_methods.js";
import Object3D from "../vue_three_js/components/Object3D.vue";
import ModelPopUp from "./ModelPopUp";
import ShareQrCode from "./ShareQRCode";
export default {
  components: { Object3D, ModelPopUp, ShareQrCode},
  name: "CulturalExpoExhibition",
  data() {
    return {
      matOpts: {
        color: 0xffffff,
        side: THREE.FrontSide,
        // blending: THREE.AdditiveBlending,
        metalness: 0,
        roughness: 0,
        envMapIntensity: 1,

        // opacity: 1,
        // depthWrite: false,
        // transparent: true,
      },
      options: {
        wrapS: THREE.RepeatWrapping,
        wrapT: THREE.RepeatWrapping,
        repeat: [180, 180],
        anisotropy: 16,
        encoding: THREE.sRGBEncoding,
      },
      Mshoptions: {
        receiveShadow: true, //// 平面接收阴影,
        castShadow: true, // 立方体的阴影
      },
      falg: true,
      centerDialogVisible: false,
      tween: null,
      animateinfo: null,
      audio: null,
      isPaused: true,
      cameraIsPaly: false,
      loadMesh: true,
      tipsShow: true,
      isFull: true,
      isShare: false, //分享显示,
      obj: null,
      target: { x: 0, y: 0, z: 0 },
      Second_scene_camera: null,
      scene: null,
      introduce: [],
      singleIntroduce: null,
      Main_camera: null,
    };
  },
  props: {
    size: {
      // 画布大小
      type: Object,
      default() {
        return { w: 0, h: 0 };
      },
    },
    insetsize: {
      //小地图大小
      type: Object,
      default() {
        return { w: 0, h: 0 };
      },
    },
    wallname: {
      //碰撞检测名字
      type: String,
      default() {
        return "";
      },
    },
    CameraPosition: {
      //相机位置
      type: Object,
      default() {
        return { x: 0, y: 0, z: 0 };
      },
    },
    CameraRotation: {
      // 相机角度
      type: Object,
      default() {
        return { x: 0, y: 0, z: 0 };
      },
    },
    // 网格
    GridHelpers: {
      type: Boolean,
      default() {
        return true;
      },
    },
    audioSrc: {
      type: String,
      default() {
        return "";
      },
    },
  },
  watch: {
    falg() {},
    centerDialogVisible() {},
  },
  created() {
    this.IsPC();
    this.$bus.$on("modelloaded", (val) => {
      if (val >= 1) {
        let s = 0;
        if (this.falg) {
          s = 3;
        } else {
          s = 8;
        }
        setTimeout(() => {
          this.loadMesh = false;
        }, s * 1000);
      }
    });
    this.audio = document.createElement("AUDIO");
    this.audio.src = this.audioSrc;
    this.audio.loop = "loop";
    this.introduce = [
      {
        name: "???????",
        type: "回青",
        title: "水滴瓶",
        Price: "666",
        size: "70*30*20",
        scaleValue: 100,
        introduce:
          "回青，有产于西域、新疆、云南等多种说法。此料发色菁幽泛紫，若单独使用则浑散不收，故多与石子青混合使用。明代嘉靖至万历前期多用此料。其中分上青：混入石子青10%，用于混水（填色），发色青亮；中青：混入石子青40%，用于设色（勾勒轮廓），笔路分明清晰。",
      },
      {
        name: "??_2",
        type: "石子青",
        title: "水壶",
        Price: "888",
        size: "70*30*20",
        scaleValue: 180,
        introduce:
          "石子青，又称石青，产于江西高安、宜丰、上高一带。此料单独使用时，青花发色灰暗甚至发黑，明清二代民窑普遍采用此料，官窑则用于与回青调和使用。",
      },
      {
        name: "pasted__polySurface2",
        type: "苏麻离青",
        title: "瓷坛",
        Price: "999",
        size: "70*30*20",
        scaleValue: 120,
        introduce:
          "苏麻离青，即苏泥麻青、苏勃泥青、苏泥勃青等。其名称的来源，一说是来自波斯语“苏来曼”的译音。这种钴料的产地在波斯卡山夸姆萨村，村民们认为是一名叫苏来曼的人发现了这种钴料，故以其名字来命名此料。另一种说法是，苏泥麻青应为苏麻离青，是英文smalt的译音，意为深蓝色或绘画用的深蓝色粉末颜料。此料属低锰高铁类钴料，故青花呈色浓重青翠，有“铁锈斑痕”，俗称“锡光”。元青花的一部分和明永乐、宣德官窑所用青料均是这种，产地均在古波斯或今叙利亚一带。",
      },
      {
        name: "pasted__transform1",
        type: "珠明料",
        title: "梅瓶",
        Price: "555",
        size: "70*30*20",
        scaleValue: 150,
        introduce:
          "珠明料，产于云南宣威、会泽、宜良等县，其中以宣威料最好。此料发色明丽纯正。康熙青花多采用此料。 　上述国产钴料多属高锰类。",
      },
      {
        name: "pasted__Line02",
        type: "浙料",
        title: "橄榄瓶",
        Price: "688",
        size: "70*30*20",
        scaleValue: 100,
        introduce:
          "浙料，又称浙青，产于浙江绍兴、金华一带。国产料中以浙料最为上乘，其发色青翠，明代万历中期至清代，景德镇官窑青花器均采用此料。",
      },

      {
        name: "pasted__polySurface1_2",
        type: "化学青料",
        title: "棒槌瓶",
        Price: "998",
        size: "70*30*20",
        scaleValue: 300,
        introduce:
          "化学青料，即用化学制品氧化钴配制的青料。发色紫蓝、纯粹、浓艳，但轻浮而缺乏附着力，价格也低廉。使用此料制作的青花器，缺乏天然青料的美感。",
      },
      {
        name: "huaping-1",
        type: "化学青料",
        title: "象腿瓶",
        size: "70*30*20",
        Price: "588",
        scaleValue: 100,
        introduce:
          "化学青料，即用化学制品氧化钴配制的青料。发色紫蓝、纯粹、浓艳，但轻浮而缺乏附着力，价格也低廉。使用此料制作的青花器，缺乏天然青料的美感。",
      },
    ];
    this.singleIntroduce = this.introduce[0];
  },
  mounted() {
    this.audio.play();
    this.isPaused = this.audio.paused;
  },
  methods: {
    // 射线
    Raycaste(intersects) {
      this.tween = new TWEEN.Tween(this.$global.camera.position);
      if (intersects.length > 0) {
        this.Click_the_ground_position_to_move(
          intersects,
          "地面",
          this.Main_camera
        );
        for (let i = 0; i < intersects.length; i++) {
          const object = intersects[i].object;
          let item = this.introduce.find((item) => {
            return item.name == object.name;
          });
          if (item) {
            this.centerDialogVisible = true;
            this.singleIntroduce = item;
            this.obj = object.clone();
            this.$refs.popUp.open();
            this.setContent(
              this.obj,
              // this.Second_scene_camera,
              this.singleIntroduce.scaleValue
            );
            break;
          }
        }
      }
    },
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          this.falg = false;
          break;
        }
      }
    },
    topdown(event) {
      event.keyCode = 38;
      this.$bus.$emit("key-down", event);
    },
    topup(event) {
      event.keyCode = 38;
      this.$bus.$emit("key-up", event);
    },
    bottomdown(event) {
      event.keyCode = 40;
      this.$bus.$emit("key-down", event);
    },
    bottomup(event) {
      event.keyCode = 40;
      this.$bus.$emit("key-up", event);
    },
    rightdown(event) {
      event.keyCode = 39;
      this.$bus.$emit("key-down", event);
    },
    rightup(event) {
      event.keyCode = 39;
      this.$bus.$emit("key-up", event);
    },
    leftdown(event) {
      event.keyCode = 37;
      this.$bus.$emit("key-down", event);
    },
    leftup(event) {
      event.keyCode = 37;
      this.$bus.$emit("key-up", event);
    },
    fullScreen() {
      this.isFull = false;
      tools.fullscreen();
    },
    exitFullscreen() {
      tools.exitFullscreen();
      this.isFull = true;
    },
    audio_paly() {
      if (this.audio.paused) {
        this.audio.play();
        this.isPaused = false;
      } else {
        this.audio.pause();
        this.isPaused = true;
      }
    },
    camera_paly() {
      // let list = [
      //   {
      //     p: this.CameraPosition,
      //     r: { x:318.9895926689453, y: 100.44244302876056, z: -341.4145973387126 },
      //     time: 2000,
      //   },
      //   {
      //     p: { x: -216.17469560901225, y: 100, z: -346.894423700785 },
      //     r: { x: -109.64000707138673, y: 86.20585135365332, z: -350.7506692934092 },
      //     time: 2000,
      //   },
      //   {
      //     p: { x: -216.17469560901225, y: 100, z: -346.894423700785 },
      //     r: {x: -318.946116564209, y: 90.83215937545283, z: -169.02215010005793 },
      //     time: 2000,
      //   },
      //   {
      //     p: { x: -216.17469560901225, y: 100, z: -346.894423700785 },
      //     r: { x: -104.48794445983616, y: 80.36144295975025, z: -140.30800643138673 },
      //     time: 2000,
      //   },
      //   {
      //     p: { x: -199.5608271647264, y: 100, z: -91.25210687634326 },
      //     r: { y: -1.4722605867159297 },
      //     time: 2000,
      //   },
      //   {
      //     p: { x: -199.5608271647264, y: 100, z: -91.25210687634326 },
      //     r: { y: 0.0874997595831311 },
      //     time: 2000,
      //   },

      //   {
      //     p: { x: -199.5608271647264, y: 100, z: -91.25210687634326 },
      //     r: { y: 1.4492825987924685 },
      //     time: 2000,
      //   },
      //   {
      //     p: { x: -199.5608271647264, y: 100, z: -91.25210687634326 },
      //     r: { y: 1.0630785868638652 },
      //     time: 2000,
      //   },
      //   {
      //     p: { x: -199.5608271647264, y: 100, z: -91.25210687634326 },
      //     r: { y: 1.0630785868638652 },
      //     time: 2000,
      //   },
      //   {
      //     p: { x: -252.5129200706608, y: 100, z: -134.09947192271525 },
      //     r: { y: 1.4964183827577162 },
      //     time: 2000,
      //   },
      //   {
      //     p: { x: -252.5129200706608, y: 100, z: -134.09947192271525 },
      //     r: { y: 1.4964183827577162 },
      //     time: 2000,
      //   },
      //   {
      //     p: { x: -231.91498460377548, y: 100, z: -246.96024618040218 },
      //     r: { x: -318.946116564209, y: 91.43134194214451, z: -262.0593774690677 },
      //     time: 2000,
      //   },
      //   {
      //     p: { x: -231.91498460377548, y: 100, z: -246.96024618040218 },
      //     r: { x: -272.4635749115147, y: 94.35493407970557, z: 227.0610089883106 },
      //     time: 2000,
      //   },
      // ];
      let list =[{
	"p": {
		"x": -216.17469560901225,
		"y": 100,
		"z": -346.894423700785
	},
	"r": {
		"x": -318.9895926689453,
		"y": 95.3449830616496,
		"z": -48.243668862361744
	},
	"time": 2000
}, {
	"p": {
		"x": -200.0437141114213,
		"y": 100,
		"z": -242.16160643805443
	},
	"r": {
		"x": -318.946116564209,
		"y": 93.95937117552722,
		"z": -223.18643817874408
	},
	"time": 2000
}, {
	"p": {
		"x": -187.10774151043404,
		"y": 100,
		"z": -147.47642726546587
	},
	"r": {
		"x": -318.946116564209,
		"y": 93.67997999064724,
		"z": -117.21074860567772
	},
	"time": 2000
}, {
	"p": {
		"x": -190.68963783222043,
		"y": 100,
		"z": -51.19511130258108
	},
	"r": {
		"x": -318.9895926689453,
		"y": 94.06979501552334,
		"z": -20.00934164898186
	},
	"time": 2000
}, {
	"p": {
		"x": -164.35339826064035,
		"y": 100,
		"z": 37.98834850128517
	},
	"r": {
		"x": -270.16643222210877,
		"y": 114.74886020069108,
		"z": 227.06649682757651
	},
	"time": 2000
}, {
	"p": {
		"x": -193.61050437518273,
		"y": 100,
		"z": 94.838478931544
	},
	"r": {
		"x": -318.9895926689453,
		"y": 120.04165889342826,
		"z": -79.70181366075653
	},
	"time": 2000
}, {
	"p": {
		"x": -193.61050404699023,
		"y": 100,
		"z": 94.83847990550314
	},
	"r": {
		"x": -109.64000707138672,
		"y": 68.94348772900078,
		"z": -83.00162591834894
	},
	"time": 2000
}, {
	"p": {
		"x": -298.14748045627437,
		"y": 100,
		"z": -246.36216488218818
	},
	"r": {
		"x": -83.47293347209946,
		"y": 104.53340162668395,
		"z": -236.08520164085388
	},
	"time": 2000
}, {
	"p": {
		"x": -298.14748045627476,
		"y": 100,
		"z": -246.36216488218818
	},
	"r": {
		"x": -109.64000707138672,
		"y": 90.50276113801706,
		"z": -355.9912528926518
	},
	"time": 2000
}, {
	"p": {
		"x": -130.82779588352415,
		"y": 100,
		"z": -508.8608508136068
	},
	"r": {
		"x": -312.37856199611576,
		"y": 108.37802711263629,
		"z": 226.96565268570356
	},
	"time": 2000
}]


      tools.Animation_group(
        this.Main_camera,
        list,
        () => {
          this.cameraIsPaly = true;
        },
        () => {
          this.cameraIsPaly = false;
        }
      );
    },
    Homing_camera() {
      this.Main_camera.position.set(
        this.CameraPosition.x,
        this.CameraPosition.y,
        this.CameraPosition.z
      );
      Object.assign(this.Main_camera.rotation, this.CameraRotation);
    },
    Share() {
      this.isShare = true;
    },
    // 第二场景 镜头
    secondCamare(obj) {
      console.log("obj", obj);
      this.Second_scene_camera = obj;
    },
    // 第二场景
    secondScene(obj) {
      this.scene = obj;
    },
    //获取模型适合观察的缩放的比例
    getFitScaleValue: function (obj, camera) {
      var boxHelper = new THREE.BoxHelper(obj);
      boxHelper.geometry.computeBoundingBox();
      var box = boxHelper.geometry.boundingBox;
      var maxDiameter = Math.max(
        box.max.x - box.min.x,
        box.max.y - box.min.y,
        box.max.z - box.min.z
      );
      return camera.position.z / maxDiameter;
    },
    // 物体居中
    setContent(object, scaleValue = 1) {
      object.updateMatrixWorld();
      let cpygeometry = object.geometry.clone();
      object.geometry = cpygeometry;
      object.geometry.computeBoundingBox();
      object.geometry.center();
      // let scaleValue = this.getFitScaleValue(object,camera)
      object.scale.set(scaleValue, scaleValue, scaleValue);
      const box = new THREE.Box3().setFromObject(object);
      const center = box.getCenter(new THREE.Vector3());
      // var height = box.getSize(new THREE.Vector3()).y;
      // var dist = height / (2 * Math.tan((camera.fov * Math.PI) / 360));
      // camera.position.set(10, 10, dist * 1.5);
      this.target = center;
      return object;
    },
    // 位置移动
    Click_the_ground_position_to_move(intersects, name, camera) {
      if (intersects.length > 0) {
        if (intersects[0].object.name == name) {
          tools.Camera_position_movement(camera, intersects[0].point);
        }
      }
    },
    // 主要场景相机
    Main_scene_cameras(obj) {
      this.Main_camera = obj;
    },
    btn_purchase() {
      window.open(
        "https://baike.baidu.com/item/%E7%93%B7%E5%99%A8/601?fr=aladdin"
      );
    },
    changIsShow() {
      this.centerDialogVisible = false;
    },
    shareQR(val) {
      this.isShare = val;
    },
  },
  beforeDestroy() {
    //  cancelAnimationFrame(this.animateinfo); //停止动画
    this.audio.pause();
    this.audio = null;
    this.$bus.$off("key-up");
    this.$bus.$off("key-down");
  },
};
</script>
<style scoped lang="less" >
@num: 16px;
.vdr {
  top: 0;
}
.PhotoExhibition {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.Direction {
  width: 80px;
  height: 80px;
  position: absolute;
  left: 3.125 * @num;
  bottom: 5 * @num;
  z-index: 320;
  transform: rotate(45deg);
  /* display: none; */
}
.Direction > .Direction_keys {
  position: relative;
  width: 100%;
  height: 100%;
}
.Direction_keys > .top {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 0.1875 * @num;
}
.Direction_keys > .bottom {
  position: absolute;
  bottom: 0;
  right: 0;
}
.Direction_keys > .left {
  position: absolute;
  left: 0;
  bottom: 0;
}
.Direction_keys > .right {
  position: absolute;
  right: 0;
}
.key {
  width: 1.875 * @num;
  height: 1.875 * @num;
  border-radius: 1.875 * @num;
  overflow: hidden;
  background: rgb(235, 235, 235);
  transform: rotate(-45deg);
  text-align: center;
  line-height: 1.875 * @num;
  font-size: 1.25 * @num;
  z-index: 333;
}
.key:hover {
  background: #ccc;
}
.load {
  position: absolute;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.9);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}
.loading {
  color: #fff;
  margin-top: 45px;
}
.loading_text {
  color: #ccc;
}
#redball {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: blue;
  background-image: radial-gradient(
    ellipse farthest-corner at 25% 25%,
    #4b8b8b,
    #fff
  );
  margin: 0 auto;
  margin-top: 40vh;
  z-index: 999999;
}
#redball {
  transform-origin: center bottom;
  animation: bounce 1.3s cubic-bezier(0.3, 2.4, 0.85, 2.5) infinite;
}
@keyframes bounce {
  from,
  to {
    transform: translateY(30px) scaleY(0.98);
  }
  80% {
    transform: translateY(15px) scaleY(1.02);
  }
}
.cameraIsPaly {
  position: absolute;
  z-index: 99999;
  background-color: rgba(210, 255, 230, 0.1);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.Operation_tips {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 555;
  background-color: rgba(0, 0, 0, 0.4);
}
.tips_box {
  position: relative;
  border-radius: 1.875 * @num;
  border: 2px solid #fff;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  text-align: left !important;
}
.tips {
  font-size: 1.875 * @num;
  padding-left: 3 * @num;
  color: rgb(255, 255, 255);
  position: absolute;
  width: 100%;
}
.tips1 {
  top: 30%;
  left: 0;
}
.tips2 {
  top: 47%;
  left: 0;
}
.tips3 {
  bottom: 18%;
  left: 8 * @num;
}

@media screen and (max-width: 1030px) {
  .dialog img {
    max-width: 100%;
  }
  .tips_box {
    position: relative;
    border-radius: 1.875 * @num;
    border: 2px solid #fff;
    top: 10%;
    left: 10%;
    width: 80%;
    height: 75%;
    text-align: left !important;
  }
  .tips {
    font-size: 1.4 * @num;
    padding-left: 1 * @num;
    color: rgb(255, 255, 255);
    position: absolute;
    width: 100%;
  }
  .tips1 {
    top: 20%;
    left: 0.5 * @num;
  }
  .tips2 {
    top: 37%;
    left: 0.5 * @num;
  }
  .tips3 {
    bottom: 18%;
    left: 8.4 * @num;
  }
  .share_info {
    // position: fixed;
    // left: 50%;
    // top: 50%;
    // transform: translateX(-50%);
    // transform: translateY(-50%);
  }
  .renderer_box {
    height: 40vh !important;
  }

  .a_line_title {
    font-size: 14px !important;
    text-align: center !important;
  }
  .a_line {
    line-height: 20px !important;
    line-height: 20px !important;
    font-size: 14px !important;
    text-align: justify !important;
  }
}

.look-box {
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 16px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  cursor: pointer;
  color: white;
}

.look-box:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

@media (max-width: 512px) {
  .look-box {
    zoom: 0.5;
    line-height: 80px;
    font-size: 14px;
    top: 150px;
    right: 40px;
  }
}
@media (min-width: 512px) {
  .look-box {
    zoom: 0.5;
    line-height: 80px;
    font-size: 14px;
    top: 150px;
    right: 40px;
  }
}
@media (min-width: 960px) {
  .look-box {
    zoom: 0.8;
  }
}
.menu {
  position: absolute;
  top: 0;
  right: 10px;

  .menuEmbedded_box {
    position: relative;
    z-index: 333;
    font-size: 1.875 * @num;
    .menu-item {
      margin: 10px;
    }
  }
}

// 文字介绍
.details_box {
  padding-left: 45px;
  padding-top: 10px;
  padding-bottom: 50px;
  .introduce_box {
    width: 75%;
  }
  .introduce_title {
    line-height: 50px;
    font-size: 20px;
    color: black;
  }
  .introduce_text {
    font-size: 16px;
    line-height: 30px;
    color: rgb(83, 83, 83);
    height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -moz-box;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
  }

  .introduce_size {
    margin-top: 30px;
    line-height: 30px;
  }
  .size_title {
    display: inline-block;
    font-size: 20px;
    color: black;
    margin-right: 10px;
  }
  .introduce_Price {
    line-height: 30px;
  }
  .Price {
    color: red;
    font-size: 25px;
    margin-left: 3px;
  }
}
.renderer {
  width: 400px;
  height: 400px;
  position: relative;
  top: -100px;
  right: -150px;
  margin: 0;
}

.Chinese_title {
  height: 100px;
  line-height: 100px;
  font-size: 50px;
  color: #fff;
  padding-left: 50px;
}

.english_title {
  color: #86aaf8;
  font-size: 40px;
}

.PhotoExhibition /deep/ .el-dialog {
  width: 850px;
  overflow: visible;
  height: 470px;
  .shop-top {
    background-image: linear-gradient(
      to bottom,
      rgba(120, 173, 250, 0.8),
      rgba(75, 127, 250, 0.99)
    );
    width: 100%;
    height: auto;
  }
  .shop-box-button {
    margin: 0;
    width: 100%;
  }
  .shop-button {
    position: static;
    top: 0;
    right: 0;
  }
  .shop-button-item {
    margin-left: 50%;
    transform: translateX(-50%);
    width: 318px;
    height: 68px;
    border-radius: 30px;
    font-size: 25px;
    font-weight: 400;
    line-height: 64px;
  }
}

@media only screen and (max-width: 1200px) {
}
@media only screen and (max-width: 992px) {
}
@media only screen and (max-width: 768px) {
  // 文字介绍
  .PhotoExhibition /deep/ .el-dialog {
    width: 85%;
    overflow: visible;
    height: 660px;
    .shop-top-item {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
    .renderer {
      width: 200px;
      height: 200px;
      margin: 0 auto;
      position: static;
      position: relative;
      top: 50px;
      right: 0;
    }
  }

  .Chinese_title {
    height: 300px;
    line-height: 80px;
    font-size: 30px;
    color: #fff;
    padding-left: 40px;
  }

  .english_title {
    color: #86aaf8;
    font-size: 30px;
  }
  .details_box {
    padding-left: 35px;
    padding-top: 10px;
    padding-bottom: 50px;
    .introduce_title {
      line-height: 50px;
      font-size: 18px;
    }
    .introduce_text {
      font-size: 14px;
      line-height: 30px;
      height: 60px;
    }
    .introduce_size {
      margin-top: 30px;
      line-height: 30px;
    }
    .size_title {
      display: inline-block;
      font-size: 18px;
      color: black;
      margin-right: 10px;
    }
    .introduce_Price {
      line-height: 30px;
    }
    .Price {
      color: red;
      font-size: 22px;
      margin-left: 3px;
    }
  }
}

@media only screen and (max-width: 600px) {
  // 文字介绍
  .PhotoExhibition /deep/ .el-dialog {
    width: 85%;
    overflow: visible;
    height: 650px;
    .shop-top-item {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
    .renderer {
      width: 200px;
      height: 200px;
      margin: 0 auto;
      position: static;
      position: relative;
      top: 50px;
      right: 0;
    }
  }

  .Chinese_title {
    height: 270px;
    line-height: 80px;
    font-size: 30px;
    color: #fff;
    padding-left: 40px;
  }

  .english_title {
    color: #86aaf8;
    font-size: 30px;
  }
  .details_box {
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 25px;
    .introduce_title {
      line-height: 40px;
      font-size: 18px;
    }
    .introduce_text {
      font-size: 14px;
      line-height: 20px;
      height: 40px;
    }
    .introduce_size {
      margin-top: 30px;
      line-height: 30px;
    }
    .size_title {
      display: inline-block;
      font-size: 16px;
      color: black;
      margin-right: 10px;
    }
    .introduce_Price {
      line-height: 30px;
    }
    .Price {
      color: red;
      font-size: 18px;
      margin-left: 3px;
    }
    .shop-box-button {
      margin: 0;
      width: 100%;
    }
    .shop-button {
      position: static;
      top: 0;
      right: 0;
    }
    .shop-button-item {
      margin-left: 50%;
      transform: translateX(-50%);
      width: 200px;
      height: 50px;
      border-radius: 25px;
      font-size: 20px;
      font-weight: 400;
      line-height: 50px;
    }
  }
}

@media only screen and (max-width: 470px) {
  // 文字介绍
  .PhotoExhibition /deep/ .el-dialog {
    width: 85%;
    overflow: visible;
    height: 670px;
    .shop-top-item {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
    .renderer {
      width: 200px;
      height: 200px;
      margin: 0 auto;
      position: static;
      position: relative;
      top: 50px;
      right: 0;
    }
  }

  .Chinese_title {
    height: 270px;
    line-height: 80px;
    font-size: 30px;
    color: #fff;
    padding-left: 40px;
  }

  .english_title {
    color: #86aaf8;
    font-size: 30px;
  }
  .details_box {
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 25px;
    .introduce_title {
      line-height: 40px;
      font-size: 18px;
    }
    .introduce_text {
      font-size: 14px;
      line-height: 20px;
      height: 40px;
    }
    .introduce_size {
      margin-top: 30px;
      line-height: 30px;
    }
    .size_title {
      display: inline-block;
      font-size: 16px;
      color: black;
      margin-right: 10px;
    }
    .introduce_Price {
      line-height: 30px;
    }
    .Price {
      color: red;
      font-size: 18px;
      margin-left: 3px;
    }
    .shop-box-button {
      margin: 0;
      width: 100%;
    }
    .shop-button {
      position: static;
      top: 0;
      right: 0;
    }
    .shop-button-item {
      zoom: 0.;
    }
  }
}
</style>
