<template>
  <div style="width:100%;height:100%;">
    <!-- update by cheney at 2022-12-06 add loadding background image -->
    <el-progress :text-inside="false" :stroke-width="16" :format="formatPgInfo" :percentage="percentage" v-show="loading"
                 class="progressBar" ></el-progress>
    <div style="position:fixed;top:0;left:0:margin:0;width:100vw;height:100vh;z-index: 222;" v-show="loading">
        <el-image src="/static/background.png" style="width:100vw;height:100vh;fit:fill;"></el-image>
    </div>
    <Object3D>
    <slot></slot>
    </Object3D>
    <div class="Direction" v-show="!isMobile">
    
      <div class="Direction_keys">
        <div class="key top" @touchstart="topdown" @touchend="topup">
          <i class="el-icon-top"></i>
        </div>
        <div class="key bottom" @touchstart="bottomdown" @touchend="bottomup">
          <i class="el-icon-bottom"></i>
        </div>
        <div class="key right" @touchstart="rightdown" @touchend="rightup">
          <i class="el-icon-right"></i>
        </div>
        <div class="key left" @touchstart="leftdown" @touchend="leftup">
          <i class="el-icon-back"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

    import Object3D from "./../components/Object3D";
    import * as THREE from "three";
    import {Euler} from "three";
    import {Octree} from 'three/examples/jsm/math/Octree.js';
    import {Capsule} from 'three/examples/jsm/math/Capsule.js';
    import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
    import Worker from "worker-loader!../mesh/worker.js";
    import {DRACOLoader} from "three/examples/jsm/loaders/DRACOLoader";
    import Dexie from "dexie";

    const GRAVITY = 30;

    const STEPS_PER_FRAME = 5;

    export default {
        name: "FpsControls",
        mixins: [Object3D],
        components: {Object3D},
        inject: {
            renderer: {name: 'renderer', default: null}
        },
        data() {
            return {
                isMobile: true,
                timer: null,
                frame: null,

                prevTime: null,
                velocity: null,
                direction: null,

                touches: null,
                state: -1,
                rotateStart: null,
                rotateEnd: null,
                rotateDelta: null,

                panStart: null,
                panEnd: null,
                panDelta: null,

                dollyStart: null,
                dollyEnd: null,
                dollyDelta: null,

                //sphericalDelta: null,

                enabled: true,
                STATE: {
                    NONE: -1,
                    ROTATE: 0,
                    DOLLY: 1,
                    PAN: 2,
                    TOUCH_ROTATE: 3,
                    TOUCH_PAN: 4,
                    TOUCH_DOLLY_PAN: 5,
                    TOUCH_DOLLY_ROTATE: 6,
                },
                changeEvent: {type: "change"},
                startEvent: {type: "start"},
                endEvent: {type: "end"},

                enableZoom: true,
                zoomSpeed: 0.8,

                // Set to false to disable rotating
                enableRotate: true,
                rotateSpeed: 0.5,

                // Set to false to disable panning
                enablePan: true,
                panSpeed: 0.8,
                isLocked: false,
                loading: true,
                percentage: 0,
                addOctree: this.isAddOct
            };
        },
        props: {
            modelUrl: {
                type: String,
                default() {
                    return '';
                }
            },
            edition: {
                type: Number,
                default() {
                    return 1
                }
            },
            modelPosition: {
                type: Object,
                default() {
                    return {x: 0, y: 0, z: 0};
                },
            },
            modelRotation: {
                type: Object,
                default() {
                    return {x: 0, y: 0, z: 0};
                },
            },
            modelScale: {
                type: Number,
                default() {
                    return 1
                }
            },
            isAddOct: {
                type: Boolean,
                default() {
                    return false;
                }
            },
            octBlackList: {
                type: Array,
                default() {
                    return [];
                }
            },
            radius: {
                type: Number,
                default() {
                    return 1
                }
            },
            initPosition: {
                type: Object,
                default() {
                    return {x: 0, y: 0, z: 0};
                },
            },
            initRotation: {
                type: Object,
                default() {
                    return {x: 0, y: 0, z: 0};
                },
            },
            renderComplete: {
                type: Boolean,
                default() {
                    return false;
                },
            },
            // 最小角度
            minAngle: {
                type: Number,
                default() {
                    return 0.48
                }
            },
            // 最大角度
            maxAngle: {
                type: Number,
                default() {
                    return 0.52
                }
            },
            mvSpeed: {
                type: Number,
                default() {
                    return 1
                }
            },
        },
        created() {
            this.timer = new THREE.Clock();
            this.prevTime = performance.now();
            this.velocity = new THREE.Vector3();
            this.direction = new THREE.Vector3();
            this.touches = {ONE: THREE.TOUCH.ROTATE, TWO: THREE.TOUCH.DOLLY_PAN};
            this.rotateStart = new THREE.Vector2();
            this.rotateEnd = new THREE.Vector2();
            this.rotateDelta = new THREE.Vector2();

            this.panStart = new THREE.Vector2();
            this.panEnd = new THREE.Vector2();
            this.panDelta = new THREE.Vector2();

            this.dollyStart = new THREE.Vector2();
            this.dollyEnd = new THREE.Vector2();
            this.dollyDelta = new THREE.Vector2();

            this.worldOctree = new Octree();

            this.playerCollider = new Capsule(new THREE.Vector3(this.initPosition.x, this.radius, this.initPosition.z), new THREE.Vector3(this.initPosition.x, this.initPosition.y, this.initPosition.z), this.radius);

            this.playerVelocity = new THREE.Vector3();
            this.playerDirection = new THREE.Vector3();

            this.playerOnFloor = true;

            this.keyStates = {};

            this.clock = new THREE.Clock();

            this.IsPC();


        },
        mounted() {
            // let domElement = this.renderer.div;
            // fixme this.$global.camera  let domElement = this.$global.rendererDom; // fixme

            this.camera = this.renderer.camera
            this.camera.rotation.order = 'YXZ';

            const fillLight1 = new THREE.HemisphereLight(0xaaaaaa, 0x002244, 0.5);
            fillLight1.position.set(2, 1, 1);
            this.renderer.scene.add(fillLight1);

            const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
            directionalLight.position.set(-5, 25, -1);
            directionalLight.castShadow = true;
            directionalLight.shadow.camera.near = 0.01;
            directionalLight.shadow.camera.far = 500;
            directionalLight.shadow.camera.right = 30;
            directionalLight.shadow.camera.left = -30;
            directionalLight.shadow.camera.top = 30;
            directionalLight.shadow.camera.bottom = -30;
            directionalLight.shadow.mapSize.width = 1024;
            directionalLight.shadow.mapSize.height = 1024;
            directionalLight.shadow.radius = 4;
            directionalLight.shadow.bias = -0.00006;
            this.renderer.scene.add(directionalLight);
            const pmremGenerator = new THREE.PMREMGenerator(this.renderer.curObj);

            this.renderer.scene.environment = pmremGenerator.fromScene(this.renderer.scene, 0.04).texture;

            this.initialModel(this.modelUrl);


            this.$bus.$on("mouse-down", this.onMouseDown);
            this.$bus.$on("mouse-up", this.onMouseUp);
            this.$bus.$on("mouse-move", this.onMouseMove);
            this.$bus.$on("key-down", this.onKeyDown);
            this.$bus.$on("key-up", this.onKeyUp);
            this.$bus.$on("touch-start", this.onTouchStart);
            this.$bus.$on("touch-end", this.onTouchEnd);
            this.$bus.$on("touch-move", this.onTouchMove);

        },

        beforeDestroy() {

            if (this.frame) {
                cancelAnimationFrame(this.frame);
            }

            this.worldOctree = new Octree();

            this.$bus.$off("mouse-down", this.onMouseDown);
            this.$bus.$off("mouse-up", this.onMouseUp);
            this.$bus.$off("mouse-move", this.onMouseMove);
            this.$bus.$off("key-down", this.onKeyDown);
            this.$bus.$off("key-up", this.onKeyUp);
            this.$bus.$off("touch-start", this.onTouchStart);
            this.$bus.$off("touch-end", this.onTouchEnd);
            this.$bus.$off("touch-move", this.onTouchMove);
        },

        methods: {
            IsPC() {
                var userAgentInfo = navigator.userAgent;
                var Agents = [
                    "Android",
                    "iPhone",
                    "SymbianOS",
                    "Windows Phone",
                    "iPad",
                    "iPod",
                ];
                for (var v = 0; v < Agents.length; v++) {
                    if (userAgentInfo.indexOf(Agents[v]) > 0) {
                        this.isMobile = false;
                        break;
                    }
                }
            },
            formatPgInfo(percentage) {
                if (percentage <= 0) {
                    return '数据读取中'
                } else if (percentage < 100) {
                    return '下载中：' + percentage + '%'
                } else {
                    return '文件资源解析中'
                }
            },
            topdown() {
                this.keyStates['KeyW'] = true;

            },
            topup() {
                this.keyStates['KeyW'] = false;

            },
            bottomdown() {
                this.keyStates['KeyS'] = true;

            },
            bottomup() {
                this.keyStates['KeyS'] = false;

            },
            rightdown() {

                this.keyStates['KeyD'] = true;
            },
            rightup() {

                this.keyStates['KeyD'] = false;
            },
            leftdown() {
                this.keyStates['KeyA'] = true;
            },
            leftup() {
                this.keyStates['KeyA'] = false;
            },

            onKeyDown(event) {
                this.keyStates[event.code] = true;
            },
            onKeyUp(event) {
                this.keyStates[event.code] = false;
            },

            onMouseDown() {
                this.isLocked = true;
            },
            onMouseUp() {
                this.isLocked = false;
            },
            onMouseMove(event) {
                const PI_2 = Math.PI / 2;
                if (this.isLocked) {
                    this.camera.rotation.y -= event.movementX / 500;
                    let dx = event.movementY / 500
                    dx = Math.max(Math.PI * this.minAngle - PI_2, Math.min(Math.PI * this.maxAngle - PI_2, dx));
                    this.camera.rotation.x -= dx;

                }


            },

            onTouchStart(event) {
                if (this.enabled === false) return;

                switch (event.touches.length) {
                    case 1:
                        switch (this.touches.ONE) {
                            case THREE.TOUCH.ROTATE:
                                this.handleTouchStartRotate(event);

                                this.state = this.STATE.TOUCH_ROTATE;

                                break;

                            case THREE.TOUCH.PAN:
                                if (this.enablePan === false) return;

                                this.handleTouchStartPan(event);

                                this.state = this.STATE.TOUCH_PAN;

                                break;

                            default:
                                this.state = this.STATE.NONE;
                        }

                        break;

                    case 2:
                        switch (this.touches.TWO) {
                            case THREE.TOUCH.DOLLY_PAN:
                                if (this.enableZoom === false && this.enablePan === false) return;

                                this.handleTouchStartDollyPan(event);

                                this.state = this.STATE.TOUCH_DOLLY_PAN;

                                break;

                            case THREE.TOUCH.DOLLY_ROTATE:
                                if (this.enableZoom === false && this.enableRotate === false)
                                    return;

                                this.handleTouchStartDollyRotate(event);

                                this.state = this.STATE.TOUCH_DOLLY_ROTATE;

                                break;

                            default:
                                this.state = this.STATE.NONE;
                        }

                        break;

                    default:
                        this.state = this.STATE.NONE;
                }

                // if (this.state !== this.STATE.NONE) {
                //     this.controls.dispatchEvent(this.startEvent);
                // }
            },

            onTouchMove(event) {
                if (this.enabled === false) {
                    return;
                }

                event.preventDefault();
                event.stopPropagation();

                switch (this.state) {
                    case this.STATE.TOUCH_ROTATE:
                        if (this.enableRotate === false) return;

                        this.handleTouchMoveRotate(event);

                        // this.update();

                        break;

                    case this.STATE.TOUCH_PAN:
                        if (this.enablePan === false) return;

                        this.handleTouchMovePan(event);

                        // this.update();

                        break;

                    case this.STATE.TOUCH_DOLLY_PAN:
                        if (this.enableZoom === false && this.enablePan === false) return;

                        this.handleTouchMoveDollyPan(event);

                        // this.update();

                        break;

                    case this.STATE.TOUCH_DOLLY_ROTATE:
                        if (this.enableZoom === false && this.enableRotate === false) return;

                        this.handleTouchMoveDollyRotate(event);

                        // this.update();

                        break;

                    default:
                        this.state = this.STATE.NONE;
                }
            },

            onTouchEnd(event) {
                if (this.enabled === false) return;

                // this.unlock();

                this.handleTouchEnd(event);

                this.dispatchEvent(this.endEvent);

                this.state = this.STATE.NONE;
            },

            handleTouchStartRotate(event) {
                let y;
                let x;

                if (
                    /iPhone|iPad|iPod/i.test(navigator.userAgent)
                ) {
                    if (window.orientation == 0 || window.orientation == 180) {
                        // $("body").attr("class", "portrait");
                        if (event.touches.length === 1) {
                            this.rotateStart.set(event.touches[0].clientY, this.renderer.rendererDomSize.h - event.touches[0].clientX);
                        } else {
                            y = 0.5 * (this.renderer.rendererDomSize.h - event.touches[0].clientX + this.renderer.rendererDomSize.h - event.touches[1].clientX);
                            x = 0.5 * (event.touches[0].clientY + event.touches[1].clientY);

                            this.rotateStart.set(x, y);
                        }
                    }
                    else if (window.orientation == 90 || window.orientation == -90) {
                        // $("body").attr("class", "landscape");
                        if (event.touches.length === 1) {
                            this.rotateStart.set(event.touches[0].clientX, event.touches[0].clientY);
                        } else {
                            x = 0.5 * (event.touches[0].clientX + event.touches[1].clientX);
                            y = 0.5 * (event.touches[0].clientY + event.touches[1].clientY);

                            this.rotateStart.set(x, y);
                        }
                    }
                }
                else if ( screen.orientation.type.startsWith("portrait")) {
                    if (event.touches.length === 1) {
                        this.rotateStart.set(event.touches[0].clientY, this.renderer.rendererDomSize.h - event.touches[0].clientX);
                    } else {
                        y = 0.5 * (this.renderer.rendererDomSize.h - event.touches[0].clientX + this.renderer.rendererDomSize.h - event.touches[1].clientX);
                        x = 0.5 * (event.touches[0].clientY + event.touches[1].clientY);

                        this.rotateStart.set(x, y);
                    }
                }
                else {
                    if (event.touches.length === 1) {
                        this.rotateStart.set(event.touches[0].clientX, event.touches[0].clientY);
                    } else {
                        x = 0.5 * (event.touches[0].clientX + event.touches[1].clientX);
                        y = 0.5 * (event.touches[0].clientY + event.touches[1].clientY);

                        this.rotateStart.set(x, y);
                    }
                }
            },

            handleTouchStartPan(event) {
                let x;
                let y;
                if (
                    /iPhone|iPad|iPod/i.test(navigator.userAgent)
                ) {
                    if (window.orientation == 0 || window.orientation == 180) {
                        // $("body").attr("class", "portrait");
                        if (event.touches.length == 1) {
                            this.panStart.set(event.touches[0].clientY, this.renderer.rendererDomSize.h - event.touches[0].clientX);
                        } else {
                            y = 0.5 * (this.renderer.rendererDomSize.h - event.touches[0].clientX + this.renderer.rendererDomSize.h - event.touches[1].clientX);
                            x = 0.5 * (event.touches[0].clientY + event.touches[1].clientY);

                            this.panStart.set(x, y);
                        }
                    }
                    else if (window.orientation == 90 || window.orientation == -90) {
                        // $("body").attr("class", "landscape");
                        if (event.touches.length == 1) {
                            this.panStart.set(event.touches[0].clientX, event.touches[0].clientY);
                        } else {
                            x = 0.5 * (event.touches[0].clientX + event.touches[1].clientX);
                            y = 0.5 * (event.touches[0].clientY + event.touches[1].clientY);

                            this.panStart.set(x, y);
                        }
                    }
                }
                else if ( screen.orientation.type.startsWith("portrait")) {
                    if (event.touches.length == 1) {
                        this.panStart.set(event.touches[0].clientY, this.renderer.rendererDomSize.h - event.touches[0].clientX);
                    } else {
                        y = 0.5 * (this.renderer.rendererDomSize.h - event.touches[0].clientX + this.renderer.rendererDomSize.h - event.touches[1].clientX);
                        x = 0.5 * (event.touches[0].clientY + event.touches[1].clientY);

                        this.panStart.set(x, y);
                    }
                }
                else {
                    if (event.touches.length == 1) {
                        this.panStart.set(event.touches[0].clientX, event.touches[0].clientY);
                    } else {
                        x = 0.5 * (event.touches[0].clientX + event.touches[1].clientX);
                        y = 0.5 * (event.touches[0].clientY + event.touches[1].clientY);

                        this.panStart.set(x, y);
                    }
                }
            },

            handleTouchStartDolly(event) {
                var dx = event.touches[0].clientX - event.touches[1].clientX;
                var dy = event.touches[0].clientY - event.touches[1].clientY;

                var distance = Math.sqrt(dx * dx + dy * dy);

                this.dollyStart.set(0, distance);
            },

            handleTouchStartDollyPan(event) {
                if (this.enableZoom) this.handleTouchStartDolly(event);

                if (this.enablePan) this.handleTouchStartPan(event);
            },

            handleTouchStartDollyRotate(event) {
                if (this.enableZoom) this.handleTouchStartDolly(event);

                if (this.enableRotate) this.handleTouchStartRotate(event);
            },

            handleTouchMoveRotate(event) {
                let x;
                let y;
// const PI_2 = Math.PI / 2;

                if (
                    /iPhone|iPad|iPod/i.test(navigator.userAgent)
                ) {
                    if (window.orientation == 0 || window.orientation == 180) {
                        // $("body").attr("class", "portrait");
                        if (event.touches.length == 1) {
                            this.rotateEnd.set(event.touches[0].clientY, this.renderer.rendererDomSize.h - event.touches[0].clientX);
                        } else {
                            y = 0.5 * (this.renderer.rendererDomSize.h - event.touches[0].clientX + this.renderer.rendererDomSize.h - event.touches[1].clientX);
                            x = 0.5 * (event.touches[0].clientY + event.touches[1].clientY);

                            this.rotateEnd.set(x, y);
                        }
                    }
                    else if (window.orientation == 90 || window.orientation == -90) {
                        // $("body").attr("class", "landscape");
                        if (event.touches.length == 1) {
                            this.rotateEnd.set(event.touches[0].clientX, event.touches[0].clientY);
                        } else {
                            x = 0.5 * (event.touches[0].clientX + event.touches[1].clientX);
                            y = 0.5 * (event.touches[0].clientY + event.touches[1].clientY);

                            this.rotateEnd.set(x, y);
                        }
                    }
                }
                else if ( screen.orientation.type.startsWith("portrait")) {
                    if (event.touches.length == 1) {
                        this.rotateEnd.set(event.touches[0].clientY, this.renderer.rendererDomSize.h - event.touches[0].clientX);
                    } else {
                        y = 0.5 * (this.renderer.rendererDomSize.h - event.touches[0].clientX + this.renderer.rendererDomSize.h - event.touches[1].clientX);
                        x = 0.5 * (event.touches[0].clientY + event.touches[1].clientY);

                        this.rotateEnd.set(x, y);
                    }
                }
                else {
                    if (event.touches.length == 1) {
                        this.rotateEnd.set(event.touches[0].clientX, event.touches[0].clientY);
                    } else {
                        x = 0.5 * (event.touches[0].clientX + event.touches[1].clientX);
                        y = 0.5 * (event.touches[0].clientY + event.touches[1].clientY);

                        this.rotateEnd.set(x, y);
                    }
                }

                this.rotateDelta
                    .subVectors(this.rotateEnd, this.rotateStart)
                    .multiplyScalar(this.rotateSpeed);
                // this.$global.rendererDom;
                var element = this.renderer.div

                // this.rotateLeft( 2 * Math.PI * this.rotateDelta.x / element.clientHeight ); // yes, height
                //
                // this.rotateUp( 2 * Math.PI * this.rotateDelta.y / element.clientHeight );

                // console.log('quaternion', this.controls.getObject().quaternion )
                var euler = new Euler(0, 0, 0, "YXZ");
                euler.setFromQuaternion(this.camera.quaternion);

                if (Math.abs(this.rotateDelta.x) > Math.abs(this.rotateDelta.y)) {
                  euler.y -= (2 * Math.PI * this.rotateDelta.x) / element.clientHeight;
                    this.keyStates['KeyW'] = false;
                    this.keyStates['KeyS'] = false;
                }
                else if (Math.abs(this.rotateDelta.x) < Math.abs(this.rotateDelta.y)) {
                    if (this.rotateDelta.y > 0) {
                        this.keyStates['KeyW'] = false;
                        this.keyStates['KeyS'] = true;
                    } else if (this.rotateDelta.y < 0) {
                        this.keyStates['KeyW'] = true;
                        this.keyStates['KeyS'] = false;
                    } else {
                        this.keyStates['KeyW'] = false;
                        this.keyStates['KeyS'] = false;
                    }
                }

                // euler.x = Math.max(PI_2 - Math.PI * this.maxAngle, Math.min(PI_2 - Math.PI * this.minAngle, euler.x));
                this.camera.quaternion.setFromEuler(euler);

                this.rotateStart.copy(this.rotateEnd);
            },

            handleTouchMovePan(event) {
                let x;
                let y;
                if (
                    /iPhone|iPad|iPod/i.test(navigator.userAgent)
                ) {
                    if (window.orientation == 0 || window.orientation == 180) {
                        // $("body").attr("class", "portrait");
                        if (event.touches.length == 1) {
                            this.panEnd.set(event.touches[0].clientY, this.renderer.rendererDomSize.h - event.touches[0].clientX);
                        } else {
                            y = 0.5 * (this.renderer.rendererDomSize.h - event.touches[0].clientX + this.renderer.rendererDomSize.h - event.touches[1].clientX);
                            x = 0.5 * (event.touches[0].clientY + event.touches[1].clientY);

                            this.panEnd.set(x, y);
                        }
                    }
                    else if (window.orientation == 90 || window.orientation == -90) {
                        // $("body").attr("class", "landscape");
                        if (event.touches.length == 1) {
                            this.panEnd.set(event.touches[0].clientX, event.touches[0].clientY);
                        } else {
                            x = 0.5 * (event.touches[0].clientX + event.touches[1].clientX);
                            y = 0.5 * (event.touches[0].clientY + event.touches[1].clientY);

                            this.panEnd.set(x, y);
                        }
                    }
                }
                else if ( screen.orientation.type.startsWith("portrait")) {
                    if (event.touches.length == 1) {
                        this.panEnd.set(event.touches[0].clientY, this.renderer.rendererDomSize.h - event.touches[0].clientX);
                    } else {
                        y = 0.5 * (this.renderer.rendererDomSize.h - event.touches[0].clientX + this.renderer.rendererDomSize.h - event.touches[1].clientX);
                        x = 0.5 * (event.touches[0].clientY + event.touches[1].clientY);

                        this.panEnd.set(x, y);
                    }
                }
                else {
                    if (event.touches.length == 1) {
                        this.panEnd.set(event.touches[0].clientX, event.touches[0].clientY);
                    } else {
                        x = 0.5 * (event.touches[0].clientX + event.touches[1].clientX);
                        y = 0.5 * (event.touches[0].clientY + event.touches[1].clientY);

                        this.panEnd.set(x, y);
                    }
                }

                this.panDelta
                    .subVectors(this.panEnd, this.panStart)
                    .multiplyScalar(this.panSpeed);

                //this.pan( this.panDelta.x, this.panDelta.y );
                // this.controls.moveForward(0 - this.panDelta.y);
                // this.controls.moveRight(this.panDelta.x);
                if (this.panDelta.y > 0) {
                    this.keyStates['KeyW'] = false;
                    this.keyStates['KeyS'] = true;
                } else if (this.panDelta.y < 0) {
                    this.keyStates['KeyW'] = true;
                    this.keyStates['KeyS'] = false;
                } else {
                    this.keyStates['KeyW'] = false;
                    this.keyStates['KeyS'] = false;
                }

                if (this.panDelta.x > 0) {
                    this.keyStates['KeyA'] = false;
                    this.keyStates['KeyD'] = true;
                } else if (this.panDelta.x < 0) {
                    this.keyStates['KeyA'] = true;
                    this.keyStates['KeyD'] = false;
                } else {
                    this.keyStates['KeyA'] = false;
                    this.keyStates['KeyD'] = false;
                }

                this.panStart.copy(this.panEnd);
            },

            handleTouchMoveDolly(event) {
                var dx = event.touches[0].clientX - event.touches[1].clientX;
                var dy = event.touches[0].clientY - event.touches[1].clientY;

                var distance = Math.sqrt(dx * dx + dy * dy);

                this.dollyEnd.set(0, distance);

                this.dollyDelta.set(
                    0,
                    Math.pow(this.dollyEnd.y / this.dollyStart.y, this.zoomSpeed)
                );

                //this.dollyOut( this.dollyDelta.y );
                this.camera.fov *= this.dollyDelta.y;

                this.dollyStart.copy(this.dollyEnd);
            },

            handleTouchMoveDollyPan(event) {
                if (this.enableZoom) this.handleTouchMoveDolly(event);

                if (this.enablePan) this.handleTouchMovePan(event);
            },

            handleTouchMoveDollyRotate(event) {
                if (this.enableZoom) this.handleTouchMoveDolly(event);

                if (this.enableRotate) this.handleTouchMoveRotate(event);
            },

            handleTouchEnd(/*event*/) {
                // no-op
                this.keyStates['KeyW'] = false;
                this.keyStates['KeyS'] = false;
                this.keyStates['KeyA'] = false;
                this.keyStates['KeyD'] = false;
            },

            playerCollisions() {

                const result = this.worldOctree.capsuleIntersect(this.playerCollider);

                // this.playerOnFloor = true;

                if (result) {

                    this.playerOnFloor = this.playerOnFloor || result.normal.y > 0;

                    if (!this.playerOnFloor) {

                        this.playerVelocity.addScaledVector(result.normal, -result.normal.dot(this.playerVelocity));

                    }

                    this.playerCollider.translate(result.normal.multiplyScalar(result.depth));

                }

            },

            updatePlayer(deltaTime) {

                let damping = Math.exp(-4 * deltaTime) - 1;

                if (!this.playerOnFloor) {

                    this.playerVelocity.y -= GRAVITY * deltaTime;

                    // small air resistance
                    damping *= 0.1;

                }

                this.playerVelocity.addScaledVector(this.playerVelocity, damping);

                const deltaPosition = this.playerVelocity.clone().multiplyScalar(deltaTime);
                this.playerCollider.translate(deltaPosition);

                this.playerCollisions();

                this.camera.position.copy(this.playerCollider.end);

            },

            getForwardVector() {

                this.camera.getWorldDirection(this.playerDirection);
                this.playerDirection.y = 0;
                this.playerDirection.normalize();

                return this.playerDirection;

            },

            getSideVector() {

                this.camera.getWorldDirection(this.playerDirection);
                this.playerDirection.y = 0;
                this.playerDirection.normalize();
                this.playerDirection.cross(this.camera.up);

                return this.playerDirection;

            },

            controls(deltaTime) {

                // gives a bit of air control
                const speedDelta = deltaTime * (this.playerOnFloor ? this.mvSpeed : 25);

                if (this.keyStates['KeyW']) {

                    this.playerVelocity.add(this.getForwardVector().multiplyScalar(speedDelta));

                }

                if (this.keyStates['KeyS']) {

                    this.playerVelocity.add(this.getForwardVector().multiplyScalar(-speedDelta));

                }

                if (this.keyStates['KeyA']) {

                    this.playerVelocity.add(this.getSideVector().multiplyScalar(-speedDelta));

                }

                if (this.keyStates['KeyD']) {

                    this.playerVelocity.add(this.getSideVector().multiplyScalar(speedDelta));

                }

                if (this.playerOnFloor) {

                    if (this.keyStates['Space']) {

                        this.playerVelocity.y = 15;
                        this.playerOnFloor = false;

                    }

                }

            },

            teleportPlayerIfOob() {

                if (this.camera.position.y <= -25) {

                    this.playerCollider.start.set(this.initPosition.x, this.radius, this.initPosition.z);
                    this.playerCollider.end.set(this.initPosition.x, this.initPosition.y, this.initPosition.z);
                    this.playerCollider.radius = this.radius;
                    this.camera.position.copy(this.playerCollider.end);
                    this.camera.rotation.set(this.initRotation.x, this.initRotation.y, this.initRotation.z);

                }

            },

            animate() {

                const deltaTime = Math.min(0.05, this.clock.getDelta()) / STEPS_PER_FRAME;

                // we look for collisions in substeps to mitigate the risk of
                // an object traversing another too quickly for detection.

                for (let i = 0; i < STEPS_PER_FRAME; i++) {

                    this.controls(deltaTime);

                    this.updatePlayer(deltaTime);

                    this.teleportPlayerIfOob();

                }

                // renderer.render( scene, this.camera );

                this.frame = requestAnimationFrame(this.animate);

            },

            /**线程 保存
             *@param obj: obj保存的对象
             */
            WorkerSave(obj) {
                let worker = new Worker();
                worker.postMessage(obj);
                worker.onmessage = (e) => {
                    console.log("Received message " + e.data);
                    worker.terminate();
                };
            },
            /**该方法用于读取数据库模型文件
             *@param modelName: 数据库存储的模型 名字
             **/
            async readModel(modelName) {
                let dbmodel = null;
                const db = new Dexie("ModelDatabase");
                db.version(1).stores({
                    // 创建表
                    modelList: "++id,name,model,edition,animation",
                });
                await db.modelList
                    .where("name")
                    .equalsIgnoreCase(modelName)
                    .first()
                    .then((res) => {
                        if (res) {
                            if (res.edition != this.edition) {
                                db.modelList.delete(res.id);
                                dbmodel = null;
                            } else {
                                //返回模型
                                dbmodel = res;
                            }
                        } else {
                            // 加载模型文件
                            dbmodel = null;
                        }
                    })
                    .catch((err) => {
                        console.log("数据读取失败", err);
                        dbmodel = null;
                    });

                return dbmodel;
            },
            /** 加载 three 对象
             * @param resModel: 需要解析的模型
             */
            loaderModel(res) {
                let loader = new THREE.ObjectLoader(); //加载 three 对象
                return new Promise((resolve) => {
                    loader.parse(res.model, (model) => {
                        this.addOtcTree(model);
                        this.renderer.scene.add(model);
                        this.animate();
                        this.loading = false;
                        resolve();
                    });
                }).then(() => {
                    loader = null;
                });
            },
            /** 初始化函数CameraRotation
             * @param fileName : String
             */
            initialModel(fileName) {
                // 查看数据有没有 模型
                //读取数据库
                new Promise((resolve) => {
                    this.readModel(fileName).then(async (dbmodel) => {
                        if (dbmodel) {
                            // 解析模型
                            this.percentage = 100;
                            setTimeout(()=>{ this.loaderModel(dbmodel)}, 300)

                        } else {
                            // 重新加载模型
                            await this.requireModel()
                        }
                        resolve(1)
                    })
                }).then(() => {

                });

            },
            /**
             * 加载 文件model
             */
            requireModel() {
                // 读取数据  console.log("读取数据");
                let _this = this;
                return new Promise((resolve) => {
                    const loader = new GLTFLoader()
                    // .setPath('/models/gltf/');
                    const dracoLoader = new DRACOLoader();
                    dracoLoader.setDecoderPath("static/threex/draco/gltf/");
                    loader.setDRACOLoader(this.dracoLoader);
                    loader.load(this.modelUrl,
                        (gltf) => {
                            // gltf.scene.scale.set(0.7, 0.7, 0.7);
                            let object = gltf.scene;
                            //  Box3 方法的作用
                            //  计算和世界轴对齐的一个对象 Object3D （含其子对象）的包围盒，计算对象和子对象的世界坐标变换。
                            var objBbox = new THREE.Box3().setFromObject(gltf.scene);
                            objBbox.setFromObject(object);

                            object.scale.set(_this.modelScale, _this.modelScale, _this.modelScale);
                            Object.assign(object.position, _this.modelPosition);
                            Object.assign(object.rotation, _this.modelRotation);

                            this.addOtcTree(object)

                            object.traverse(child => {

                                if (child.isMesh) {

                                    child.castShadow = true;
                                    child.receiveShadow = true;

                                    if (child.material.map) {

                                        child.material.map.anisotropy = 8;

                                    }

                                }

                            });
                            _this.renderer.scene.add(object);


                            let model = {
                                name: this.modelUrl,
                                model: object.toJSON(),
                                edition: this.edition,
                            };
                            resolve(model);
                            this.animate();
                            this.loading = false;

                        },
                        (xhr) => {
                            _this.percentage = Math.floor(xhr.loaded / xhr.total * 100);
                        }
                    );
                }).then((model) => {
                    this.WorkerSave(model);
                    return "结束"
                })
            },
            addOtcTree(object) {
                if (this.addOctree) {
                    if (this.octBlackList.length > 0) {
                        let children = object.children;
                        while (children.length === 1) {
                            children = children[0].children
                        }

                        for (let i = 0, l = children.length; i < l; i++) {
                            if (this.octBlackList.includes(children[i].name)) {
                                // console.log(children[i].name)
                            } else {
                                this.worldOctree.fromGraphNode(children[i])
                            }
                        }
                    } else {
                        this.worldOctree.fromGraphNode(object);
                    }
                    this.addOctree = false;
                }
            }
        },
    };
</script>

<style scoped lang="less">
  @num: 16px;
  .Direction {
    width: 80px;
    height: 80px;
    position: absolute;
    left: 3 * @num;
    bottom: 3 * @num;
    z-index: 320;
    transform: rotate(45deg);
    /* display: none; */
  }

  .Direction > .Direction_keys {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .Direction_keys > .top {
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 0.1875 * @num;
  }

  .Direction_keys > .bottom {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .Direction_keys > .left {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .Direction_keys > .right {
    position: absolute;
    right: 0;
  }

  .key {
    width: 1.875 * @num;
    height: 1.875 * @num;
    border-radius: 1.875 * @num;
    overflow: hidden;
    background: rgb(235, 235, 235);
    transform: rotate(-45deg);
    text-align: center;
    line-height: 1.875 * @num;
    font-size: 1.25 * @num;
    z-index: 333;
  }

  .key:hover {
    background: #ccc;
  }

  .progressBar {
    width: 80vmax;
    height: 5vmin;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 333;
}
// top: 45vmin;
// left: 10vmax;

  /deep/ .el-progress__text{
    color: #409EFF;
  }

</style>
