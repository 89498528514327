<template>
  <el-dialog
          :visible='visible'
          :modal="true"
          :modal-append-to-body="false"
          :close-on-click-modal="false"
  >
    <div class="close" @click="close">x</div>
    <div class="shop-box">
      <div class="shop-top">
        <div>
          <slot name="english_title">
            <div class="shop-top-title">{{titleName}}</div>
          </slot>
        </div>
        <div class="shop-top-item">
          <slot name="model"></slot>
        </div>
      </div>
      <div class="price-box">
        <div>
          <slot name="details"></slot>
        </div>
        <div class="shop-box-button">
          <slot name="number"></slot>
          <div class="shop-button">
            <div class="shop-button-item">
                <slot name="btn">点击购买</slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "ModelPopUp",
  data() {
    return {
      visible:false
    };
  },
  props: {
    closeFun:Function,
    titleName: {
      type: String,
      default() {
        return "BLUE AND WHITE PORCELAIN";
      },
    },
  },
  watch: {
  },
  created() {},
  mounted() {
  },
  methods: {
    close() {
      this.visible=false
      this.$emit('close')
    },
    open(){
      this.visible=true
    }
  },
  beforeDestroy() {},
};
</script>

<style scoped>
.Model_pop_up {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 10086;
  background-color: rgba(0, 0, 0, 0.4);
}
.Model_pop_up * {
  user-select: none;
}
.pop_body {
  width: 100%;
  height: 100%;
  position: relative;
}
.Pop_content {
  position: absolute;
  width: 53%;
  height: 71%;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*box-shadow: 5px 5px 15px #666666;*/
  overflow: auto;
  overflow-x: hidden;
}
.Pop_content .content-bg {
  background: #fff;
}
.title-bg {
  background-image: linear-gradient(
    to bottom,
    rgba(120, 173, 250, 0.8),
    rgba(75, 127, 250, 0.99)
  );
  width: 100%;
  height: auto;
}
.Chinese_psition {
  padding-left: 30px;
  line-height: 120px;
}

.Chinese_title {
  height: 180px;
  font-size: 50px;
  color: #fff;
}
.english_psition {
  position: absolute;
  bottom: 20px;
  left: 0;
  padding-left: 10rem;
}
/*.english_title {*/
/*  color: #86aaf8;*/
/*  font-size: 20px;*/
/*  margin-left: 10px;*/
/*  line-height: 80px;*/
/*}*/

.details {
  background-color: #fff;
  width: 100%;
  height: 100%;
}
.details_box {
  padding-left: 45px;
  padding-top: 10px;
}
.introduce_box {
  width: 75%;
}
.introduce_title {
  line-height: 50px;
  font-size: 20px;
  color: black;
}
.introduce_text {
  font-size: 20px;
  line-height: 30px;
  color: #616060;
}

.introduce_size {
  margin-top: 30px;
  line-height: 30px;
}
.size_title {
  display: inline-block;
  font-size: 20px;
  color: black;
  margin-right: 10px;
}
.introduce_Price {
  line-height: 30px;
}
.Price {
  color: red;
  font-size: 25px;
  margin-left: 3px;
}

.btn_position {
  position: absolute;
  bottom: -30px;
  right: 0;
  width: 100%;
}
.btn {
  margin: 0px auto;
  width: 300px;
  height: 80px;
  border-radius: 80px;
  cursor: pointer;
}
.btn_text {
  text-align: center;
  color: #fff;
  font-size: 25rem;
  font-weight: 400;
  line-height: 80px;
}
.btn_bg {
  background-image: linear-gradient(
    to bottom,
    rgb(170, 204, 255),
    rgba(75, 127, 250, 0.99)
  );
}

.model_position {
  position: absolute;
  top: 0;
  right: 0;
}

/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.fade-enter-active {
  transition: all 0.3s ease;
}
.fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter, .fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.P-relative {
  position: relative;
}
.Tailoring {
  overflow: hidden;
}
.fillet {
  width: 100%;
  /*height: 100%;*/
  border-radius: 20px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .model_position {
      position: absolute;
      /*width: 100%;*/
    }
  .Pop_content {
    width: 80%;
    height: 50%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .model_position {
      position: absolute;
      /*width: 100%;*/
    }
  .title-bg{
    height: 70rem;
  }
  .english_title {
    font-size: 20rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .model_position {
      position: absolute;
      /*width: 100%;*/
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .title-bg{
    height: auto;
  }
  .english_title{
    font-size: 50rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .model_position {
      position: absolute;
      width: auto;
    }
}


  /deep/ .el-dialog {
    width: 80%;
    height: 360px;
    background-color: #f2f2f2;
    border-radius: 15px;
    overflow: auto;
  }
  /deep/ .el-dialog__header{
    padding: 0;
  }
  /deep/ .el-dialog__wrapper{
    padding: 0;
  }
  /deep/ .el-dialog__body{
    padding: 0;
  }
  @media (max-width: 960px) {
    /deep/ .el-dialog {
      width: 80%;
    }
    /deep/ .shop-top-title{
      font-size: 23px;
    }
  }
  @media (min-width: 512px) and (max-width: 960px) {
    /deep/ .el-dialog {
      height: 230px;
    }
    /deep/ .el-dialog {
      width: 60%;
    }
  }
  @media (min-width: 960px){
    /deep/ .el-dialog {
      width: 40%;
    }

  }
</style>
