<template>
    <div>
        <slot></slot>
    </div>
</template>

<script>
    import * as THREE from "three";
    export default {
        name: "fileLoader",
        data() {
            const loader = new THREE.FontLoader();
            return {
                loader,
            };
        },
        created() {
            this.createText(this.loader)
        },
        methods:{
            getText(val){
               this.$bus.$emit('loaderText',val)
            },
            //创建文字
            createText(loader) {
                loader.load("static/fonts/DengXian_Bold.json",async (font) => {
                    let options = {
                        font: font,
                        size: 70,
                        height: 20,
                        curveSegments: 4,
                        bevelThickness: 4,
                        bevelSize: 1.5,
                        bevelEnabled: true,
                    };
                    await this.getText(options);
                });
            },
        }
    }
</script>

<style scoped>

</style>
