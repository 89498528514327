<template>
    <div class="PhotoExhibition">
        <div v-if="!isModel" >
            <loadAnimation>
                <el-progress class="percentage" :percentage="percentage"></el-progress>
            </loadAnimation>
        </div>
        <renderer :size="size" :insetsize="insetsize">
            <scene :gridHelper="false"  @update:obj="secondScene">
                <map-controls :autoRotate="autoRotate" :target="target">
                    <camera
                            :position="CameraPosition"
                            :rotation="CameraRotation"
                    ></camera>
                </map-controls>
                <audio-listener :position="{ x: 0, y: 0, z: 0 }"></audio-listener>
                <slot></slot>
                <ambient-light hex="#ccc" :intensity="0.2"></ambient-light>
                <raycaster @Object3D_Raycaster="Raycaste"></raycaster>
<!--                <mesh   v-for="(item,index) in videoList"-->
<!--                        :key="index"-->
<!--                        :rotation="{ y: -Math.PI / item.rotation }"-->
<!--                        :scale="1"-->
<!--                        :position="item.position"-->
<!--                >-->
<!--                    <geometry type="Plane" :args="item.args"></geometry>-->
<!--                    <material type="MeshBasic" :options="matOpts">-->
<!--                        <canvas-video :videourl="videoUrl" :isplay="true"></canvas-video>-->
<!--                        <video-texture></video-texture>-->
<!--                    </material>-->
<!--                </mesh>-->
                 <snow :scale="3" :snow="snow"></snow>
                 <rain :raining="rain" :scale="0.1"></rain>
                 <sky :Time="scene"></sky>
<fileLoader>
                <mesh v-for="(item,i) in titleList" name="Link"  :position="item.position" :key="i+'title'" :scale="0.1" :userData="item.src" :rotation="{ y: -Math.PI * item.rotation }">
                    <font-loader :text="item.name"> </font-loader>
                    <material type="MeshStandard" :options="matOpting"></material>
                </mesh>
</fileLoader>
<!--                <mesh v-for="(item,i) in showList" name="Link" :position="item.position" :key="i+'name'" :scale="0.036" :userData="item.src">-->
<!--                    <font-loader :text="item.name"> </font-loader>-->
<!--                    <material type="MeshStandard" :options="matOpting"></material>-->
<!--                </mesh>-->
                  <Fog color="#5a7b8c" :near="3000" :far="5000"></Fog>
            </scene>
        </renderer>
        <div style="position: absolute;top: 20rem;left: 15rem;">
            <div style="color: #333;font-size: 16rem;margin-left: 8rem;font-family: '微软雅黑'">当前场景</div>
            <el-select style="z-index: 9999" v-model="scene" @change="changeScene()" placeholder="选择场景">
                <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                </el-option>
            </el-select>
        </div>
        <div class="visible-box">
            <div class="visible" >
                <div v-if="!autoRotate" @click="changeRotate(true)">旋转观看</div>
                <div v-if="autoRotate" @click="changeRotate(false)">停止旋转</div>
            </div>

<!--            <div class="visible">-->
<!--                <div v-if="videoMusic" @click="openMusic()">打开声音</div>-->
<!--                <div v-if="!videoMusic" @click="closeMusic()">关闭声音</div>-->
<!--            </div>-->
        </div>

        <div v-if="!isPc">
            <div class="Direction">
                <div class="Direction_keys">
                    <div class="key top" @touchstart="topdown" @touchend="topup">
                        <i class="el-icon-caret-top"></i>
                    </div>
                    <div class="key bottom" @touchstart="bottomdown" @touchend="bottomup">
                        <i class="el-icon-caret-bottom"></i>
                    </div>
                    <div class="key right" @touchstart="rightdown" @touchend="rightup">
                        <i class="el-icon-caret-right"></i>
                    </div>
                    <div class="key left" @touchstart="leftdown" @touchend="leftup">
                        <i class="el-icon-caret-left"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="look-box" style="top: 180rem">
            <div v-if="isFull" class="look" @click="fullScreen()">全屏</div>
            <div v-if="!isFull" class="look" @click="exitFullscreen()">退出全屏</div>
        </div>
        <div class="Homing">
            <i @click="getHome()" @mouseover="home=true" @mouseleave="home=false" class="el-icon-location-information"></i>
            <div style="font-size: 16px;color: #fff;height: 20px;width: 100px"><div v-if="home">回到初始位置</div></div>
        </div>

        <ocean></ocean>
        <div @click="openArt()" v-if="art" style="position: absolute;top: 100rem;left: 30rem;z-index: 9999999">返回城市</div>
        <iframe v-if="art" src="http://art.yfzne.com"  style="position: absolute;top: 0;left: 0;z-index: 999999"></iframe>
        <audio src="static/rain.mp3" loop="loop"  preload="auto" ref="audio"></audio>
        <video v-show="videoLook"  src = "static/car.mp4" :muted = "muted" loop="loop" ref="video"></video>

    </div>
</template>

<script>
    import * as THREE from "three";
    import { TWEEN } from "three/examples/jsm/libs/tween.module.min.js";

    import  '../vue_three_js/rem';
    import loadAnimation from './loadAnimation'
    import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils.js';

    export default {
        name: "floorTemplate",
        props: {
            size: {
                // 画布大小
                type: Object,
                default() {
                    return { w: 0, h: 0 };
                },
            },
            insetsize: {
                //小地图大小
                type: Object,
                default() {
                    return { w: 0, h: 0 };
                },
            },
            wallname: {
                //碰撞检测名字
                type: String,
                default() {
                    return "";
                },
            },
            CameraPosition: {
                //相机位置
                type: Object,
                default() {
                    return { x: 0, y: 0, z: 0 };
                },
            },
            CameraRotation: {
                // 相机角度
                type: Object,
                default() {
                    return { x: 0, y: 0, z: 0 };
                },
            },
            // 网格
            GridHelpers: {
                type: Boolean,
                default() {
                    return true;
                },
            },
            audioSrc:{
                type: String,
                default() {
                    return "";
                },
            },
            music:{
                type:String,
                default(){
                    return "";
                },
            }
        },
        data(){
          return{
              isModel:false,
              matOpts: {
                  // color: 0x00ffff,
                  side: THREE.DoubleSide,
                  // blending: THREE.AdditiveBlending,
                  opacity: 1,
                  // depthWrite: false,
                  transparent: true,
              },
              matOpting: {
                  color: new THREE.Color("rgb(40,165,240)"),
                  side: THREE.DoubleSide,
                  metalness: 0,
                  roughness: 0,
                  envMapIntensity: 1,
                  emissive:new THREE.Color("rgb(40,165,240)"),
                  opacity:0.8,
                  transparent: true,
              },
              videoUrl:'static/car.mp4',
              videoList:[
                  // {position:{x: 18, y: 18, z: 20},args:[18,10],rotation:0.01},
                  // {position:{x: -21, y: 18, z: 26},args:[18,10],rotation:0.01},
                  {position:{x: -174, y: 130, z: 108},args:[48,34],rotation:-2},
                  // {position:{x: 33.5, y: 18, z: 7.5},args:[18,10],rotation:-2},
                  {position:{x: -169, y: 54, z: 223},args:[70,50],rotation:0.01},
              ],
              options:[
                  {value:'morning',label:'早晨'},
                  {value:'noon',label:'中午'},
                  {value:'evening',label:'晚上'},
                  {value:'night',label:'下雨'},
                  {value:'snow',label:'下雪'},
              ],
              isVideo:false,
              planeImg:[
                  {name:'http://vjs.zencdn.net/v/oceans.mp4',url:'static/ground/tu.jpg'},
                  {name:'static/skyVideo.mp4',url:'static/ground/tu.jpg'}
              ],
              meshName:'',
              model:{},
              floorMesh:{},
              cameras:{x:3,y:12,z:66},
              percentage:0,
              getMode:{},
              matOpt: {
                  color: 0xffffff,
                  side: THREE.DoubleSide,
                  // blending: THREE.AdditiveBlending,
                  opacity: 1,
                  // depthWrite: false,
                  transparent: true,
              },
              args: [10, 10],
              titleList:[
                  {position:{x:-196,y:185,z:106},name:'车展',src:'http://car.yfzne.com',rotation:-0.5},
                  {position:{x:-150,y:83,z:110},name:'文博展',src:'http://art.yfzne.com',rotation:-0.5},
                  {position:{x:-140,y:103,z:195},name:'画展',src:'http://porcelain.yfzne.com',rotation:-0.5},
                  {position:{x:151,y:71,z:-121},name:'机械展示',src:'http://mechanical.yfzne.com',rotation:0},
                  {position:{x:-109,y:65,z:-165},name:'超市',src:'http://shop.yfzne.com',rotation:-0.5}
              ],
              // showList:[
              //     {position:{x:20,y:45,z:0},name:'画展',src:'http://art.yfzne.com',rotation:1},
              //     {position:{x:20,y:55,z:0},name:'',src:'http://porcelain.yfzne.com',rotation:1},
              //     {position:{x:20,y:65,z:0},name:'机械展示',src:'http://mechanical.yfzne.com',rotation:1},
              // ],
              oneModel:{},
              isPc:true,
              isFull:true,
              art:false,
              clock: new THREE.Clock(),
              material:{},
              home:false,
              rain:false,
              scene:'morning',
              videoMusic:true,
              muted:"muted",
              autoRotate:true,
              videoLook:false,
              target:{x:-144,y:58,z:41},
              snow:false,
          }
        },
        created(){
            let uniform = {
                u_color: { value: new THREE.Color("#5588aa") },
                u_tcolor: { value: new THREE.Color("rgba(147,112,219,0.8)") },
                u_r: { value: 0.1 },
                u_length: { value: 80 },//扫过区域
                u_max: { value: 2000 }//扫过最大值
            };
            let Shader = {
                vertexShader: `
                varying vec3 vp;
                void main(){
                vp = position;
                gl_Position	= projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                }
            `,
                fragmentShader: `
                varying vec3 vp;
                uniform vec3 u_color;
                uniform vec3 u_tcolor;
                uniform float u_r;
                uniform float u_length;
                uniform float u_max;
                float getLeng(float x, float y){
                    return  sqrt((x-0.0)*(x-0.0)+(y-0.0)*(y-0.0));
                }
                void main(){
                    float uOpacity = 0.3;
                    vec3 vColor = u_color;
                    float uLength = getLeng(vp.x,vp.z);
                    if ( uLength <= u_r && uLength > u_r - u_length ) {
                        float op = sin( (u_r - uLength) / u_length ) * 0.6 + 0.3 ;
                        uOpacity = op;
                        if( vp.y<0.0){
                            vColor  = u_tcolor * 0.6;
                        }else{
                            vColor = u_tcolor;
                        };
                    }
                    gl_FragColor = vec4(vColor,uOpacity);
                }
            `
            }

            this.material = new THREE.ShaderMaterial({
                vertexShader: Shader.vertexShader,
                fragmentShader: Shader.fragmentShader,
                side: THREE.DoubleSide,
                uniforms: uniform,
                transparent: true,
                depthWrite: false,
            });
        },
        mounted(){
            this.$bus.$on("modelloaded", () => {
                this.percentage+=25;
                if(this.percentage===25){
                    this.isModel=true;
                }
            });
            if(window.screen.width<960){
                this.isPc=false
            }
            this.loadVideo();
            // this.videoUrl=this.options[0].value;
            // this.changeVideo();
            this.$bus.$on("getModels", (val) => {
                this.meshName="001-0-1";
                this.getModel(val.children);
                this.videoMesh();
                this.changeRotate(true)
            });
            this.changeScene();
            // this.animate();
            // this.addBox();
        },
        methods:{
            openMusic(){
                this.videoMusic=false;
                this.muted='';
                this.$refs.video.play()
            },
            closeMusic(){
                this.videoMusic=true;
                this.muted='muted';
                this.$refs.video.play()
            },
            changeScene(){
                if(this.scene==="night"){
                    this.rain=true;
                    this.snow=false;
                    this.$refs.audio.play();
                }else if(this.scene==="snow"){
                    this.snow=true;
                    this.rain=false;
                }else if(this.scene==="morning"){
                    this.changeLight(0.6);
                }else if(this.scene==="noon"){
                    this.changeLight(1);
                }else if(this.scene==="evening"){
                    this.changeLight(0.2);
                }
                if(this.scene!=="night" && this.scene!=="snow"){
                    this.rain=false;
                    this.$refs.audio.pause();
                    this.snow=false;
                }
            },
            changeLight(k){
                let AmbientLight={};
                this.$global.scene.children.forEach((item)=>{
                    if(item.name==="AmbientLight"){
                        AmbientLight=item;
                    }
                });
                AmbientLight.intensity=k;
            },
            loadVideo(){
                // let video = document.createElement('video');
                // video.src = "static/car.mp4"; // 设置视频地址
                // video.muted = "muted"; //静音
                // video.loop="loop";
                this.$refs.video.play();
                let videoTexture = new THREE.VideoTexture(this.$refs.video);
                let material=new THREE.MeshBasicMaterial({
                    map:videoTexture
                });
                let _this=this;
                this.videoList.forEach(item=>{
                    let geometry = new THREE.PlaneGeometry( item.args[0], item.args[1] );
                    let plane = new THREE.Mesh( geometry, material );
                    plane.position.set(item.position.x,item.position.y,item.position.z);
                    plane.rotation.y=-Math.PI/item.rotation;
                    _this.$global.scene.add( plane );
                });

            },
            // 虚拟按键
            topdown(event) {
                // console.log("event",this.$global.camera.position)
                event.keyCode = 38;
                this.$bus.$emit("key-down", event);
            },
            topup(event) {
                event.keyCode = 38;
                this.$bus.$emit("key-up", event);
            },
            bottomdown(event) {
                event.keyCode = 40;
                this.$bus.$emit("key-down", event);
            },
            bottomup(event) {
                event.keyCode = 40;
                this.$bus.$emit("key-up", event);
            },
            rightdown(event) {
                event.keyCode = 39;
                this.$bus.$emit("key-down", event);
            },
            rightup(event) {
                event.keyCode = 39;
                this.$bus.$emit("key-up", event);
            },
            leftdown(event) {
                event.keyCode = 37;
                this.$bus.$emit("key-down", event);
            },
            leftup(event) {
                event.keyCode = 37;
                this.$bus.$emit("key-up", event);
            },
            // 场景添加多个方块
            randomizeMatrix(i, matrix){
            const position = new THREE.Vector3();
            const rotation = new THREE.Euler();
            const quaternion = new THREE.Quaternion();
            const scale = new THREE.Vector3();
            rotation.x = 0;
            rotation.y = 0;
            rotation.z = 0;

            quaternion.setFromEuler( rotation );
            if(i===1){
                position.x = 0;
                position.y = 0;
                position.z = 0;
                scale.x = 2000;
                scale.y = 0.5;
                scale.z = 2000;
            }else {
                let moveX=Math.random() * 1600 - 800;
                let moveZ=Math.random() * 1600 - 800;
                if(moveX > 269 || moveX < -104 || moveZ>177 || moveZ<-92){
                    position.x = moveX;
                    position.y = 0;
                    position.z = moveZ;
                    scale.x = 20;
                    scale.y = Math.random() * 120 + 30;
                    scale.z = 20;

                }
            }
                matrix.compose( position, quaternion, scale );
            },
            addBox(){
                let geometries = [];
                const geometry = new THREE.BoxGeometry( 1, 1, 1 );
                const matrix = new THREE.Matrix4();

                for ( let i = 0; i < 1000; i ++ ) {
                    this.randomizeMatrix(i,matrix)

                    const instanceGeometry = geometry.clone();
                    instanceGeometry.applyMatrix4( matrix );

                    geometries.push( instanceGeometry );

                }

                const mergedGeometry = BufferGeometryUtils.mergeBufferGeometries( geometries );

                let oneMesh = new THREE.Mesh( mergedGeometry, this.material );
                oneMesh.name = "地面"
                this.$global.scene.add( oneMesh );

                // const instancedMesh = new THREE.InstancedMesh( geometry, this.material, 1000 );
                //
                // for ( let i = 0; i < 1000; i ++ ) {
                //
                //     this.randomizeMatrix(i,  matrix );
                //     instancedMesh.setMatrixAt( i, matrix );
                //
                // }
                //
                // this.$global.scene.add( instancedMesh );
            },
            // 打开画展页面
            openArt(){
                this.art=false
            },
            // 打开全屏
            fullScreen() {
                this.isFull=false;
                if(document.body.requestFullscreen) {
                    document.body.requestFullscreen();
                } else if(document.body.mozRequestFullScreen) {
                    document.body.mozRequestFullScreen();
                } else if(document.body.webkitRequestFullscreen) {
                    document.body.webkitRequestFullscreen();
                } else if(document.body.msRequestFullscreen) {
                    document.body.msRequestFullscreen();
                }
            },
            // 关闭全屏
            exitFullscreen() {
                this.isFull=true;
                if(document.exitFullscreen) {
                    document.exitFullscreen();
                } else if(document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if(document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                }
            },
            secondScene(){

                // value.add(this.oneModel)
            },
            // 自动游览动画
            donhua(){
                // let tweena = this.cameraCon({x:255,y:97,z:76},{x:6,y:12,z:20},2000);
                // let tweenb = this.cameraCon({x:160,y:12,z:64},{x:168,y:18,z:114},3000);
                // let tweenc = this.cameraCon({x:255,y:97,z:76},{x:78,y:8,z:53},2000);
                // let tweend = this.cameraCon({x:74,y:12,z:50},{x:102,y:12,z:19},3000);
                // let tweene = this.cameraCon({x:255,y:97,z:76},{x:6,y:12,z:20},2000);
                // let tweenf = this.cameraCon({x:3,y:12,z:66},{x:6,y:12,z:20},3000);
                // let tweeng = this.cameraCon({x:255,y:97,z:76},{x:-29,y:17,z:52},2000);
                // let tweenx = this.cameraCon({x:-29,y:17,z:52},{x:-29,y:17,z:86},3000);
                // let tweeny = this.cameraCon({x:255,y:97,z:76},{x:-29,y:17,z:86},3000);
                // tweena.chain(tweenb);
                // tweenb.chain(tweenc);
                // tweenc.chain(tweend);
                // tweend.chain(tweene);
                // tweene.chain(tweenf);
                // tweenf.chain(tweeng);
                // tweeng.chain(tweenx);
                // tweenx.chain(tweeny);
                // tweena.start();
            },
            changeRotate(val){
                if(val){
                    let tween = this.cameraCon({x:-28,y:526,z:503},{x:-144,y:58,z:41},2000);
                    tween.start();
                    tween.onComplete(() => {
                        this.autoRotate=true;
                    })
                }else {
                    this.autoRotate=false;
                }

            },
            getHome(){
                TWEEN.removeAll();
                let tweena = this.cameraCon({x:151,y:96,z:28},{x:-144,y:58,z:41},2000);
                tweena.start();
                tweena.onComplete(() => {
                })
            },
            cameraCon(p2,look,time=2000) {
                this.$bus.$emit("target");
                let tween1 = new TWEEN.Tween(this.$global.camera.position).to(p2,time).easing(TWEEN.Easing.Quadratic.InOut);
                let _this=this;
                let update = function (val) {
                    _this.$global.camera.lookAt(new THREE.Vector3(look.x,look.y,look.z));
                    _this.$global.camera.position.set(val.x,val.y,val.z);
                };
                this.animate();
                tween1.onUpdate(update);
                return tween1;
            },
            // 加载视频材质
            videoMesh(){
                let video = document.createElement('video');
                video.src = "static/skyVideo.mp4"; // 设置视频地址
                video.muted = "muted"; //静音
                video.loop="loop";
                video.play();
                let texture = new THREE.VideoTexture(video);
                if(this.model.material){
                    this.model.material.map=texture;
                }
            },
            getModel(model){
                model.forEach(items=>{
                    // HDM_01_04_trunk
                    if(items.name===this.meshName){
                        this.model=items;
                        return items
                    }else {
                        return this.getModel(items.children)
                    }
                });
            },
            Raycaste(intersects) {
                console.log('1234567',this.$global.camera,intersects);
                //  console.log("targetPosition:{x:"+ intersects[0].point.x+",y:"+intersects[0].point.y+",z:"+intersects[0].point.z+"}")
                this.tween = new TWEEN.Tween(this.$global.camera.position);
                if (intersects.length > 0) {
                    intersects[0].object.layers.toggle( 1 );
                    if (
                        intersects[0].object.material.map &&
                        intersects[0].object.name != "地面"
                    ) {
                        this.centerDialogVisible = true;
                        this.currentSrc = intersects[0].object.material.map.image.currentSrc
                        // this.currentSrc = "static/hua/" + intersects[0].object.name + ".png";
                    }
                    if (intersects[0].object.name == "地面") {
                        this.animate();
                        let position = this.$global.camera.position;
                        let tween = this.tween;
                        tween = new TWEEN.Tween(position);
                        tween.to(
                            {
                                x: intersects[0].point.x,
                                z: intersects[0].point.z,
                            },
                            2000
                        );
                        tween.easing(TWEEN.Easing.Cubic.InOut);
                        tween.start();
                        let _this = this;
                        tween.onUpdate(function (object) {
                            _this.$global.camera.position.x = object.x;
                            _this.$global.camera.position.z = object.z;
                        });
                        tween.onComplete(() => {
                            cancelAnimationFrame(this.animateinfo); //停止动画
                        });
                    }
                    if (intersects[0].object.name === "Link") {
                        window.open(intersects[0].object.userData)
                    }

                }
            },
            animate() {
                this.animateinfo = requestAnimationFrame(this.animate);
                TWEEN.update();
                // let clock = this.clock.getDelta();
                // if (this.material) {
                //     this.material.uniforms.u_r.value += clock * 100;
                //     if (this.material.uniforms.u_r.value >= 1000) {
                //         this.material.uniforms.u_r.value = 80
                //     }
                // }
            },
            changeVideo(){
                let result=this.planeImg.filter(item=>item.name==this.videoUrl);
                this.$bus.$emit("side");
                this.$nextTick(()=>{
                    this.$bus.$emit('PlaneImg',result[0].url)
                });

            },
        },
        components: {loadAnimation},

    }

</script>
<style>
    .el-progress__text{
        color: #0C9A9A;
    }
</style>
<style scoped lang="less">
    .Homing {
        position: absolute;
        bottom: 10px;
        right: 10px;
        transform: translateX(-50%);
        font-size: 30px;
        z-index: 2000;
        text-align: center;
    }
    .PhotoExhibition {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        overflow-x: hidden;
    }
.load{
    position: absolute;
    z-index: 99999;
    background-color: rgba(0,0,0,0.9);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center
}
#redball{
    border-radius: 50%;
    width: 30px;height: 30px;
    background-color: blue;
    background-image: radial-gradient(ellipse farthest-corner at 25% 25%,#4b8b8b,#fff);
    margin: 0 auto;
    margin-top:40vh;
    z-index: 999999;
}
#redball{
    transform-origin: center bottom;
    animation: bounce 1.3s cubic-bezier(0.30,2.40,0.85,2.50) infinite;
}
@keyframes bounce{
    from,to{
        transform: translateY(30px) scaleY(.98);
    }
    80%{
        transform: translateY(15px) scaleY(1.02);
    }
}
.loading{
    color: #fff;
    margin-top: 45px;
}
    .visible-box{
        position: absolute;
        bottom: 40rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 999;
    }
    .visible{
        width: 100px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-color: rgba(0,0,0,0.5);
        cursor: pointer;
        border-radius: 8px;
        color: #fff;
        float: left;
    }
    .percentage{
        z-index: 99999999;width: 300rem;height: 30rem;margin: 0 auto;margin-top: 10rem;
    }
    .Direction {
        width: 110px;
        height: 110px;
        position: absolute;
        left: 40px;
        bottom: 40px;
        z-index: 9999;
        transform: rotate(45deg);
        background-color: rgba(255,255,255,0.2);
        border-radius: 50%;
        /* display: none; */
    }
    .Direction > .Direction_keys {
        position: relative;
        width: 100%;
        height: 100%;
    }
    .Direction_keys > .top {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 10px 10px 0 0;
    }
    .Direction_keys > .bottom {
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: 0 0 10px 10px;
    }
    .Direction_keys > .left {
        position: absolute;
        left: 0;
        bottom: 0;
        border-radius: 10px 0 0 10px;
    }
    .Direction_keys > .right {
        position: absolute;
        right: 0;
        border-radius: 0 10px 10px 0;
    }
    .key {
        width: 40px;
        height: 40px;
        background: rgba(0, 0, 0,0.5);
        transform: rotate(-45deg);
        line-height: 40px;
        font-size: 20px;
        z-index: 333;
        color: #f2f2f2;
        text-align: center;
    }
    .look-box{
        position: absolute;
        top: 80rem;right: 50rem;
        z-index: 999;
        width: 80px;
        height: 80px;
        line-height: 80px;
        white-space: nowrap;
        text-align: center;
        background-color: rgba(0,0,0,0.5);
        border-radius: 50%;
    }
    .look{
        font-size: 14rem;
        color: white;cursor: pointer;
    }
    @media (max-width: 960px){
        .look-box{
            zoom: 0.5;
        }
        .look{
            font-size: 14px;
        }
    }
    @media (min-width: 960px){
        .look-box{
            zoom: 0.7;
        }
    }
</style>
