<script>
import * as THREE from "three";
import Object3D from "./../components/Object3D";
import { TWEEN } from "three/examples/jsm/libs/tween.module.min.js";
export default {
  name: "rain",
  mixins: [Object3D],
  inject:['renderer'],
  props: {
    Box3: {
      type: Object,
      default() {
        return {
          bottom: { x: -20000, y: 0, z: -20000 },
          top: { x: 20000, y: 5000, z: 20000 },
        };
      },
    },
    raining: {
      type: Boolean,
      default() {
        return true;
      },
    },
    imgSrc:{
      type:String,
      default(){
        return "static/img/water.png";
      }
    }
  },
  watch: {
    raining(val) {
      if (val) {
        this.startRaining();
      } else {
        this.stopRaining();
      }
    },
  },
  data() {
    const box = new THREE.Box3(
      new THREE.Vector3(
        this.Box3.bottom.x,
        this.Box3.bottom.y,
        this.Box3.bottom.z
      ),
      new THREE.Vector3(this.Box3.top.x, this.Box3.top.y, this.Box3.top.z)
    );

    //创建雨
    let material = new THREE.MeshBasicMaterial({
      transparent: true,
      opacity: 0.8,
      map: new THREE.TextureLoader().load(this.imgSrc), //twater
      depthWrite: false,
      side: THREE.DoubleSide,
    });

    material.onBeforeCompile = function (shader) {
      const getFoot = `
            uniform float top;
            uniform float bottom;
            uniform float time;
            #include <common>
            float angle(float x, float y){
              return atan(y, x);
            }
            vec2 getFoot(vec2 camera,vec2 normal,vec2 pos){
                vec2 position;

                float distanceLen = distance(pos, normal);

                float a = angle(camera.x - normal.x, camera.y - normal.y);

                pos.x > normal.x ? a -= 0.785 : a += 0.785;

                position.x = cos(a) * distanceLen;
                position.y = sin(a) * distanceLen;

                return position + normal;
            }
            `;
      const begin_vertex = `
            vec2 foot = getFoot(vec2(cameraPosition.x, cameraPosition.z),  vec2(normal.x, normal.z), vec2(position.x, position.z));
            float height = top - bottom;
            float y = normal.y - bottom - height * time;
            y = y + (y < 0.0 ? height : 0.0);
            float ratio = (1.0 - y / height) * (1.0 - y / height);
            y = height * (1.0 - ratio);
            y += bottom;
            y += position.y - normal.y;
            vec3 transformed = vec3( foot.x, y, foot.y );
            // vec3 transformed = vec3( position );
            `;
      shader.vertexShader = shader.vertexShader.replace(
        "#include <common>",
        getFoot
      );
      shader.vertexShader = shader.vertexShader.replace(
        "#include <begin_vertex>",
        begin_vertex
      );

      shader.uniforms.cameraPosition = {
        value: new THREE.Vector3(0, 200, 0),
      };
      shader.uniforms.top = {
        value: 5000,
      };
      shader.uniforms.bottom = {
        value: 0,
      };
      shader.uniforms.time = {
        value: 0,
      };
      material.uniforms = shader.uniforms;
    };

    var geometry = new THREE.BufferGeometry();

    const vertices = [];
    const normals = [];
    const uvs = [];
    const indices = [];

    for (let i = 0; i < 10000; i++) {
      const pos = new THREE.Vector3();
      pos.x = Math.random() * (box.max.x - box.min.x) + box.min.x;
      pos.y = Math.random() * (box.max.y - box.min.y) + box.min.y;
      pos.z = Math.random() * (box.max.z - box.min.z) + box.min.z;

      const height = (box.max.y - box.min.y) / 15;
      const width = height / 50;

      vertices.push(
        pos.x + width,
        pos.y + height / 2,
        pos.z,
        pos.x - width,
        pos.y + height / 2,
        pos.z,
        pos.x - width,
        pos.y - height / 2,
        pos.z,
        pos.x + width,
        pos.y - height / 2,
        pos.z
      );

      normals.push(
        pos.x,
        pos.y,
        pos.z,
        pos.x,
        pos.y,
        pos.z,
        pos.x,
        pos.y,
        pos.z,
        pos.x,
        pos.y,
        pos.z
      );

      uvs.push(1, 1, 0, 1, 0, 0, 1, 0);

      indices.push(
        i * 4 + 0,
        i * 4 + 1,
        i * 4 + 2,
        i * 4 + 0,
        i * 4 + 2,
        i * 4 + 3
      );
    }

    geometry.setAttribute(
      "position",
      new THREE.BufferAttribute(new Float32Array(vertices), 3)
    );
    geometry.setAttribute(
      "normal",
      new THREE.BufferAttribute(new Float32Array(normals), 3)
    );
    geometry.setAttribute(
      "uv",
      new THREE.BufferAttribute(new Float32Array(uvs), 2)
    );
    geometry.setIndex(new THREE.BufferAttribute(new Uint32Array(indices), 1));

    var curObj = new THREE.Mesh(geometry, material);
    return {
      frame: null,
      clock: new THREE.Clock(),
      material,
      time: 0,
      curObj,
    };
  },
  mounted() {
    if (this.raining) {
      this.startRaining();
    } else {
      this.stopRaining();
    }
  },
  methods: {
    animation() {
      this.frame = requestAnimationFrame(this.animation);
      let material = this.material;
      if (!this.material) {
        return;
      }
      this.time = (this.time + this.clock.getDelta() * 0.4) % 1;
      material.cameraPosition = this.renderer.camera.position;
      if (this.material.uniforms) {
        this.material.uniforms.cameraPosition.value = this.renderer.camera.position;
        this.material.uniforms.time.value = this.time;
      }
    },
    end() {
      if (this.frame) {
        cancelAnimationFrame(this.frame);
      }
    },
    startRaining() {
      this.setScale(this.scale);
      this.animation();
    },
    stopRaining() {
      let tween = new TWEEN.Tween(this.curObj.scale);
      tween.to({ y: 0 }, 2000);
      tween.easing(TWEEN.Easing.Cubic.InOut);
      let _this = this;
      tween.onUpdate(function (object) {
        _this.curObj.scale.y = object.y;
      });
      tween.start();
      tween.onComplete(function () {
        _this.end();
      });
    },
  },
  beforeDestroy() {
    this.end();
  },
};
</script>

<style>
</style>
