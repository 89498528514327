<template>
    <css-sign
    :src="src"
    :size="size"
    @imgclick="imgClick"
    :show="show"
  ></css-sign>
</template>

<script>
import Object3D from "../vue_three_js/components/Object3D";
export default {
  mixins: [Object3D],
  name: "panoramicMarker",
  data() {
    return {
      show:true
    };
  },
  created() {
    this.$bus.$on("current_panorama", this.Show_hide);
    this.$bus.$emit("launch_marker", this.panoramicImg);
   
  },
  watch:{
    show(){
      
    }
  },
  mounted(){
  },
  methods: {
    imgClick() {
      this.$bus.$emit("click_marker", this.panoramicImg);
    },
    Show_hide(current_panorama){
      if(this.belong_to_panoramic == current_panorama) {
        this.show =true
      }else{
        this.show =false
      }
    }
  },
  props: {
    size: {
      type: Object, // { w, h }
      // required: true,
      default() {
        return { w: 100, h: 100 };
      },
    },
    src: {
      type: String,
      default() {
        return "";
      },
    },
    panoramicImg: {
      type: String,
      default() {
        return "";
      },
    },
    belong_to_panoramic:{
       type: String,
        default() {
          return "";
        },
    }
  },
  beforeDestroy() {
    this.$bus.$off("launch_marker");
  },
};
</script>

<style>
</style>