<template>
  <div>
<!--  <raycaster @Object3D_Raycaster="RayCast"></raycaster>-->
  </div>
</template>

<script>
    import wx from 'weixin-js-sdk';
     export default {
        name: "negativeObject",
        props: {
            objectName: {
                //碰撞检测名字
                type: String,
                default() {
                    return "";
                },
            },
            redirectUrl: {
                //碰撞检测名字
                type: String,
                default() {
                    return "";
                },
            },
            miniProgramPath:{
                type: String,
                default() {
                    return "/pages/index/index";
                },
            }
        },
         mounted() {
             this.addNegative()
         },
         methods: {
            addNegative(){
                if (this.$global.negatives){
                    this.$global.negatives[this.objectName] = this.RayCast
                }
                else{
                    this.$global.negatives = {}
                    this.$global.negatives[this.objectName] = this.RayCast
                }
            },
            RayCast() {
                if (!this.objectName){
                    console.error('No objectName')
                    return;
                }
                if (!this.redirectUrl && !this.miniProgramPath){
                    console.error('No redirectUrl')
                    return;
                }

                if (window.__wxjs_environment && this.miniProgramPath) {
                    let _this = this
                    wx.miniProgram.getEnv((res) => {
                        if (res.miniprogram) {
                            wx.miniProgram.navigateTo({
                                url: _this.miniProgramPath,
                            })
                        }
                    })
                }
                else if (this.redirectUrl){
                    if (this.redirectUrl[0] === '/' || this.redirectUrl[0] === '.') {
                        this.$router.push(this.redirectUrl)
                    }
                    else {
                        window.open(this.redirectUrl);
                    }
                }
            },
        }
    }
</script>

<style scoped>

</style>
