<template>
    <div style="overflow: hidden;" ref="cicle" >
         <slot></slot>
    </div>
</template>

<script>
    export default {
        props:['color','border','bordercolor','positions','sizes','degs','zIndex','classify','position'],
        name: "myCircle",
        data(){
            return{
                deg:0
            }
        },
        mounted() {
            // if(this.degs){
            //     this.deg=this.degs
            // }
            let box = this.$refs.cicle;
            box.style.position=this.positions.type;
            box.style.display=this.classify;
            box.style.width = this.sizes.w + this.sizes.typeW;
            box.style.height = this.sizes.h + this.sizes.typeH;
            box.style.opacity=this.color.opacity;
            box.style.backgroundColor=this.color.color;
            box.style.borderRadius=`${this.border.btop}px ${this.border.bright}px ${this.border.bbottom}px ${this.border.bleft}px`
            box.style.zIndex = this.zIndex;
            if(this.positions.type!=='static'){
                box.style.top=this.positions.y+'px';
                box.style.left=this.positions.x+'px';
                box.style.right=this.position.right+'px';
                box.style.bottom=this.position.bottom+'px';
            }else{
                box.style.marginTop=this.positions.y+'px';
                box.style.marginLeft=this.positions.x+'px';
                box.style.marginRight=this.position.right+'px';
                box.style.marginBottom=this.position.bottom+'px';
            }
            box.style.borderTop = `1px solid ${this.bordercolor.topcolor}`;
            box.style.borderRight = `1px solid ${this.bordercolor.rightcolor}`;
            box.style.borderBottom = `1px solid ${this.bordercolor.bottomcolor}`;
            box.style.borderLeft = `1px solid ${this.bordercolor.leftcolor}`;
            // if (this.positions.center && this.positions.vetically) {
            //     box.style.position = 'absolute';
            //     box.style.left = '50%';
            //     box.style.top = '50%';
            //     box.style.transform = 'translate(-' + 50 + '%,-' + 50 + '%) rotateZ('+this.deg+'deg)'
            // } else if (this.positions.center) {
            //     box.style.position = 'absolute';
            //     box.style.left = '50%';
            //     box.style.transform = 'translateX(-' + 50 + '%) rotateZ('+this.deg+'deg)'
            // } else if (this.positions.vetically) {
            //     box.style.position = 'absolute';
            //     box.style.top = '50%';
            //     box.style.transform = 'translateY(-' + 50 + '%) rotateZ('+this.deg+'deg)'
            // }else {
            //     box.style.transform=`rotateZ(${this.degs}deg)`;
            // }
            box.style.transform=`rotateZ(${this.degs}deg)`;

        }
    }
</script>

<style scoped>
    .boxs{
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: auto;
        z-index: -9999;
    }
    .circle{
        overflow: hidden;
    }
</style>
