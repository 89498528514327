<template>
  <object3d
    :rotation="rotation"
    :position="position"
    :scale="scale"
    :name="name"
  >
    <mesh @update:obj="getMesh" :name="name" :rotation="{x:- Math.PI / 2, y:0, z:0}">
      <geometry type="PlaneBuffer" :args="[228, 228, 64]"></geometry>
      <material type="MeshStandard" :options="matOpts">
        <envMapTexture v-if="imgurl"
          :url="imgurl"
          :intensity="intensity"
        ></envMapTexture>
        <uvTexture :url="uvTextureUrl" :options="options"></uvTexture>
        <bumpMap :url="bumpMapUrl" :options="options"></bumpMap>
      </material>
    </mesh>
  </object3d>
</template>

<script>
import * as THREE from "three";
export default {
  name: "PlaneBuffer",
  data() {
    let imgurl = null
    if(this.px && this.nx && this.py && this.pz && this.nz){
      imgurl = []
      imgurl.push(this.px)
      imgurl.push(this.nx)
      imgurl.push(this.py)
      imgurl.push(this.ny)
      imgurl.push(this.pz)
      imgurl.push(this.nz)
    }
    return {
      matOpts: {
        color: 0xffffff,
        side: THREE.FrontSide,
        metalness: 0,
        roughness: 0,
        envMapIntensity: 1,
      },
      options: {
        wrapS: THREE.RepeatWrapping,
        wrapT: THREE.RepeatWrapping,
        repeat: [30, 30],
        anisotropy: 16,
        encoding: THREE.sRGBEncoding,
      },
      imgurl
    };
  },
  props: {
    name: {
      type: String,
      default() {
        return "";
      },
    },
    position: {
      type: Object,
      default() {
        return { x: 0, y: 0, z: 0 };
      },
    },
    rotation: {
      type: Object,
      default() {
        return { x: 0, y: 0, z: 0 };
      },
    },
    scale: {
      type: Number,
      default() {
        return 1;
      },
    },
    intensity: {
      type: Number,
      default() {
        return 1;
      },
    },
    visible: {
      type: Boolean,
      default() {
        return true;
      },
    },
    px: {
      type: String,
      default() {
        return "";
      },
    },
    nx: {
      type: String,
      default() {
        return "";
      },
    },
    py: {
      type: String,
      default() {
        return "";
      },
    },
    ny: {
      type: String,
      default() {
        return "";
      },
    },
    pz: {
      type: String,
      default() {
        return "";
      },
    },
    nz: {
      type: String,
      default() {
        return "";
      },
    },
    uvTextureUrl: {
      type: String,
      default() {
        return "";
      },
    },
    bumpMapUrl: {
      type: String,
      default() {
        return "";
      },
    },
  },
  mounted() {
  },
  methods:{
    getMesh(){

    }
  }
};
</script>

<style>
</style>
