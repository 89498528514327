<template>
        <audio controls="controls"  ref="audio">
                <source :src="src" type="audio/mpeg" />
        </audio>
</template>

<script>
    export default {
        props:['src','positions','sizes','degs'],
        name: "audioComponent",
            data(){
                    return{
                            deg:0
                    }
            },
        mounted() {
        // if(this.degs){
        //         this.deg=this.degs
        // }
            let box=this.$refs.audio;
            box.style.position=this.positions.type;
            box.style.height=this.sizes.h+'px';
            box.style.width=this.sizes.w+'px';
                if(this.positions.type!=='static'){
                        box.style.top=this.positions.y+'px';
                        box.style.left=this.positions.x+'px';
                }else{
                        box.style.marginTop=this.positions.y+'px';
                        box.style.marginLeft=this.positions.x+'px';
                }
            // if (this.positions.center && this.positions.vetically) {
            //     box.style.position = 'absolute';
            //     box.style.left = '50%';
            //     box.style.top = '50%';
            //     box.style.transform = 'translate(-' + 50 + '%,-' + 50 + '%) rotateZ('+this.deg+'deg)'
            // } else if (this.positions.center) {
            //     box.style.position = 'absolute';
            //     box.style.left = '50%';
            //     box.style.transform = 'translateX(-' + 50 + '%) rotateZ('+this.deg+'deg)'
            // } else if (this.positions.vetically) {
            //     box.style.position = 'absolute';
            //     box.style.top = '50%';
            //     box.style.transform = 'translateY(-' + 50 + '%) rotateZ('+this.deg+'deg)'
            // }else {
            //     box.style.transform=`rotateZ(${this.degs}deg)`;
            // }
                box.style.transform=`rotateZ(${this.degs}deg)`;
        }
    }
</script>

<style scoped>

</style>

<!--<template>-->
<!--<div>-->
<!--        <span>-->
<!--        <span class="noaudio" v-if="!isShow">无</span>-->
<!--        <span class="audio" v-if="isShow">-->
<!--            <i-->
<!--                    class="el-icon-caret-right play"-->
<!--                    :class="'play' + index"-->
<!--                    @click="play(index)"-->
<!--            ></i>-->
<!--            <span class="pross">-->
<!--                <span :class="'pross_line' + index" class="pross_line"></span>-->
<!--                <span :class="'pross_spot' + index" class="pross_spot"></span>-->
<!--            </span>-->
<!--            <span>-{{ longTime }}</span>-->
<!--            <audio :class="'audio' + index" controls="controls" hidden="true">-->
<!--                <source :src="src" :type="sourceType" />-->
<!--            </audio>-->
<!--        </span>-->
<!--    </span>-->
<!--</div>-->
<!--</template>-->

<!--<script>-->
<!--        export default {-->
<!--                name: "audioComponent",-->
<!--                props: {-->
<!--                        src: {-->
<!--                                type: String,-->
<!--                                default: ""-->
<!--                        },-->
<!--                        sourceType: {-->
<!--                                type: String,-->
<!--                                default: "audio/mp3"-->
<!--                        },-->
<!--                        index: {-->
<!--                                type: Number,-->
<!--                                default: 0-->
<!--                        },-->

<!--                },-->
<!--                computed: {-->
<!--                        isShow: {-->
<!--                                get () {-->
<!--                                        if (this.src === "" || this.src == null) {-->
<!--                                                return false-->
<!--                                        } else {-->
<!--                                                return true-->
<!--                                        }-->
<!--                                },-->
<!--                                set () {}-->
<!--                        }-->
<!--                },-->
<!--                data () {-->
<!--                        return {-->
<!--                                tag: 0,-->
<!--                                longTime:''-->
<!--                        }-->
<!--                },-->
<!--                methods: {-->
<!--                        // 音频播放-->
<!--                        play (index) {-->
<!--                                // 切换图表状态-->
<!--                                const obj = document.getElementsByClassName("play" + index)[0] // 播放图表-->
<!--                                const MP3 = document.getElementsByClassName("audio" + index)[0] // 播放控件-->
<!--                                const pross_line = document.getElementsByClassName(-->
<!--                                        "pross_line" + index-->
<!--                                )[0] // 播放进度长度-->
<!--                                const pross_spot = document.getElementsByClassName(-->
<!--                                        "pross_spot" + index-->
<!--                                )[0] // 播放进度点-->
<!--                                const str = obj.className-->
<!--                                if (str.indexOf("el-icon-video-pause") > 0) {-->
<!--                                        obj.classList.add("el-icon-caret-right")-->
<!--                                        obj.classList.remove("el-icon-video-pause")-->
<!--                                } else {-->
<!--                                        obj.classList.add("el-icon-video-pause")-->
<!--                                        obj.classList.remove("el-icon-caret-right")-->
<!--                                }-->
<!--                                const sum = MP3.duration // 总时间-->
<!--                                let newsTime = MP3.currentTime // 播放时间-->
<!--                                // 进度条长度为128px-->
<!--                                let addNum = 0 // 当前播放进度长度-->
<!--                                // 清除上次的定时器-->
<!--                                clearInterval(this.tag)-->
<!--                                if (MP3.paused) {-->
<!--                                        MP3.play()-->
<!--                                        this.tag = setInterval(() => {-->
<!--                                                this.longTime=parseInt(newsTime)-->
<!--                                                newsTime = MP3.currentTime-->
<!--                                                addNum = (newsTime / sum) * 112-->
<!--                                                pross_line.style.cssText = "width:" + addNum + "px"-->
<!--                                                pross_spot.style.cssText = "left:" + addNum + "px"-->
<!--                                                if (addNum == 112) {-->
<!--                                                        pross_line.style.cssText = ""-->
<!--                                                        pross_spot.style.cssText = ""-->
<!--                                                        obj.classList.add("el-icon-caret-right")-->
<!--                                                        obj.classList.remove("el-icon-video-pause")-->
<!--                                                        clearInterval(this.tag)-->
<!--                                                }-->
<!--                                        }, 500) // 监听播放进度-->
<!--                                } else {-->
<!--                                        MP3.pause()-->
<!--                                        clearInterval(this.tag)-->
<!--                                        obj.classList.add("el-icon-caret-right")-->
<!--                                        obj.classList.remove("el-icon-video-pause")-->
<!--                                }-->
<!--                        },-->


<!--                },-->

<!--        }-->
<!--</script>-->

<!--<style lang="less" scoped>-->
<!--        .audio {-->
<!--                display: flex;-->
<!--                align-items: center;-->
<!--                padding: 9px 16px;-->
<!--                color: #fff;-->
<!--                height: 30px;-->
<!--                background: #868686;-->
<!--                width: 208px;-->
<!--                border-radius: 6px;-->
<!--                .play {-->
<!--                        font-size: 20px;-->
<!--                        padding-right: 17px;-->
<!--                }-->
<!--                .pross {-->
<!--                        display: inline-block;-->
<!--                        background: rgba(255, 255, 255, 0.2);-->
<!--                        margin-right: 5px;-->
<!--                        width: 128px;-->
<!--                        height: 5px;-->
<!--                        position: relative;-->
<!--                }-->
<!--                .pross_spot {-->
<!--                        top: -2.5px;-->
<!--                        position: absolute;-->
<!--                        left: 0;-->
<!--                        width: 10px;-->
<!--                        height: 10px;-->
<!--                        border-radius: 10px;-->
<!--                        background: #fff;-->
<!--                }-->
<!--                .pross_line {-->
<!--                        position: absolute;-->
<!--                        background: #fff;-->
<!--                        height: 5px;-->
<!--                        left: 0;-->
<!--                        width: 0;-->
<!--                }-->
<!--        }-->
<!--</style>-->
