<template>
  <object3d
    :rotation="rotation"
    :position="position"
    :scale="scale"
    :name="name"
  >
    <mesh @update:obj="getMesh"   :name="name" :userData="userData" >
      <geometry type="PlaneBuffer" :args="[size.w, size.h, 64]"></geometry>
      <material type="MeshStandard" :options="matOpts">
         <texture-loader :url="url" :options="options"></texture-loader>
          <bumpMap :url="url"  :options="options"></bumpMap>
      </material>
    </mesh>
  </object3d>
</template>

<script>
import * as THREE from "three";
export default {
  name: "painting",
  data() {
    let userData = {
      url:this.url,
      introduceUrl:this.introduceUrl
    }
    return {
      matOpts: {
        color: 0xffffff,
        side: 2,
        metalness: 0,
        roughness: 0.4,
        envMapIntensity: 1,
        bumpScale:0.1
      },
      options: {
        wrapS: THREE.RepeatWrapping,
        wrapT: THREE.RepeatWrapping,
        anisotropy: 16,
        encoding: THREE.sRGBEncoding,
      },
      userData
    }
  },
  props: {
    name: {
      type: String,
      default() {
        return "";
      },
    },
    size:{
      type: Object,
      default() {
        return { w: 100, h: 100 };
      },
    },
    url:{
       type: String,
      default() {
        return "";
      },
    },
    introduceUrl:{
       type: String,
      default() {
        return "";
      },
    },
    position: {
      type: Object,
      default() {
        return { x: 0, y: 0, z: 0 };
      },
    },
    rotation: {
      type: Object,
      default() {
        return { x: 0, y: 0, z: 0 };
      },
    },
    scale: {
      type: Number,
      default() {
        return 1;
      },
    },
    intensity: {
      type: Number,
      default() {
        return 1;
      },
    },
    visible: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  mounted() {
  },
  methods:{
    getMesh(){

    }
  }
};
</script>

<style>
</style>
