<script>
import { TextureLoader } from 'three'
import Base from '../components/Base'

export default {
  name: 'uvTexture',
  mixins: [Base],
  inject: ['material'],
  props: {
    url: String,
    options: { type: Object, default: () => ({}) }
  },
  watch:{

  },
  data () {
    let { url, ...rest } = this.options
    url = url || this.url

    let texture
    if (url) {
      if (this.baseUrl) {
        url = this.baseUrl + url
      }
      texture = new TextureLoader().load(url)
    }

    Object.keys(rest).forEach(k => {
      if (k === 'repeat') {
        texture.repeat.set(...rest[k])
      } else {
        texture[k] = rest[k]
      }
    })

    this.material.map = texture
    this.material.map.needsUpdate = true;
    return { texture }
  },
  mounted () {
    // this.$emit('update:obj', this.texture)
      this.$bus.$on('PlaneImg',(val)=>{
          let { url, ...rest } = this.options
          url = url || val

          let texture
          if (url) {
              if (this.baseUrl) {
                  url = this.baseUrl + url
              }
              texture = new TextureLoader().load(url)
          }

          Object.keys(rest).forEach(k => {
              if (k === 'repeat') {
                  texture.repeat.set(1,1)
              } else {
                  texture[k] = rest[k]
              }
          })

          this.material.map = texture
          this.material.map.needsUpdate = true;
      })
  },
  beforeDestroy () {
     this.texture.dispose()
    // this.$emit('update:obj', null)
  },
  destroyed () {
    this.material.map = null;
    this.texture.dispose();
    this.texture = null;
  }
}
</script>
