<template>
  <div class="box">
    <free-move :modelUrl="angelad83bfef_db71.modelUrl"
        :size="angelad83bfef_db71.size"
               :insetsize="angelad83bfef_db71.insetsize"
               :CameraPosition="angelad83bfef_db71.CameraPosition"
               :CameraRotation="angelad83bfef_db71.CameraRotation"
               :GridHelpers="angelad83bfef_db71.GridHelpers"
               :minAngle="angelad83bfef_db71.minAngle"
               :maxAngle="angelad83bfef_db71.maxAngle"
               :class="angelad83bfef_db71.class"
               :initPosition="angelad83bfef_db71.initPosition"
               :isAddOct="angelad83bfef_db71.isAddOct"
               :radius="angelad83bfef_db71.radius"
               :octBlackList="angelad83bfef_db71.octBlackList"
               :edition="angelad83bfef_db71.edition"
               :mvSpeed="angelad83bfef_db71.mvSpeed"
    >
      <sky ></sky>

      <hyperlink-image :name="angelf1a01821_f3d7.name"
                       :position="angelf1a01821_f3d7.position"
                       :rotation="angelf1a01821_f3d7.rotation"
                       :size="angelf1a01821_f3d7.size"
                       :scale="angelf1a01821_f3d7.scale"
                       :ImgUrl="angelf1a01821_f3d7.ImgUrl"
                       :url="angelf1a01821_f3d7.url"
                       :visible="angelf1a01821_f3d7.visible"
                       :class="angelf1a01821_f3d7.class"
      ></hyperlink-image>

      <hyperlink-image :name="angelf1a01821_f3d8.name"
                       :position="angelf1a01821_f3d8.position"
                       :rotation="angelf1a01821_f3d8.rotation"
                       :size="angelf1a01821_f3d8.size"
                       :scale="angelf1a01821_f3d8.scale"
                       :ImgUrl="angelf1a01821_f3d8.ImgUrl"
                       :url="angelf1a01821_f3d8.url"
                       :visible="angelf1a01821_f3d8.visible"
                       :class="angelf1a01821_f3d8.class"
      ></hyperlink-image>

      <hyperlink-image :name="angelf1a01821_f3d9.name"
                       :position="angelf1a01821_f3d9.position"
                       :rotation="angelf1a01821_f3d9.rotation"
                       :size="angelf1a01821_f3d9.size"
                       :scale="angelf1a01821_f3d9.scale"
                       :ImgUrl="angelf1a01821_f3d9.ImgUrl"
                       :url="angelf1a01821_f3d9.url"
                       :visible="angelf1a01821_f3d9.visible"
                       :class="angelf1a01821_f3d9.class"
      ></hyperlink-image>

      <hyperlink-image :name="angelf1a01821_f3e0.name"
                       :position="angelf1a01821_f3e0.position"
                       :rotation="angelf1a01821_f3e0.rotation"
                       :size="angelf1a01821_f3e0.size"
                       :scale="angelf1a01821_f3e0.scale"
                       :ImgUrl="angelf1a01821_f3e0.ImgUrl"
                       :url="angelf1a01821_f3e0.url"
                       :visible="angelf1a01821_f3e0.visible"
                       :class="angelf1a01821_f3e0.class"
      ></hyperlink-image>
      <hyperlink-image :name="angelf1a01821_f3e1.name"
                       :position="angelf1a01821_f3e1.position"
                       :rotation="angelf1a01821_f3e1.rotation"
                       :size="angelf1a01821_f3e1.size"
                       :scale="angelf1a01821_f3e1.scale"
                       :ImgUrl="angelf1a01821_f3e1.ImgUrl"
                       :url="angelf1a01821_f3e1.url"
                       :visible="angelf1a01821_f3e1.visible"
                       :class="angelf1a01821_f3e1.class"
      ></hyperlink-image>
      <hyperlink-image :name="angelf1a01821_f3e2.name"
                       :position="angelf1a01821_f3e2.position"
                       :rotation="angelf1a01821_f3e2.rotation"
                       :size="angelf1a01821_f3e2.size"
                       :scale="angelf1a01821_f3e2.scale"
                       :ImgUrl="angelf1a01821_f3e2.ImgUrl"
                       :url="angelf1a01821_f3e2.url"
                       :visible="angelf1a01821_f3e2.visible"
                       :class="angelf1a01821_f3e2.class"
      ></hyperlink-image>

      <hyperlink-image :name="angelf1a01821_f3e3.name"
                       :position="angelf1a01821_f3e3.position"
                       :rotation="angelf1a01821_f3e3.rotation"
                       :size="angelf1a01821_f3e3.size"
                       :scale="angelf1a01821_f3e3.scale"
                       :ImgUrl="angelf1a01821_f3e3.ImgUrl"
                       :url="angelf1a01821_f3e3.url"
                       :visible="angelf1a01821_f3e3.visible"
                       :class="angelf1a01821_f3e3.class"
      ></hyperlink-image>
      <hyperlink-image :name="angelf1a01821_f3e4.name"
                       :position="angelf1a01821_f3e4.position"
                       :rotation="angelf1a01821_f3e4.rotation"
                       :size="angelf1a01821_f3e4.size"
                       :scale="angelf1a01821_f3e4.scale"
                       :ImgUrl="angelf1a01821_f3e4.ImgUrl"
                       :url="angelf1a01821_f3e4.url"
                       :visible="angelf1a01821_f3e4.visible"
                       :class="angelf1a01821_f3e4.class"
      ></hyperlink-image>

      <hyperlink-image :name="angelf1a01821_f3e5.name"
                       :position="angelf1a01821_f3e5.position"
                       :rotation="angelf1a01821_f3e5.rotation"
                       :size="angelf1a01821_f3e5.size"
                       :scale="angelf1a01821_f3e5.scale"
                       :ImgUrl="angelf1a01821_f3e5.ImgUrl"
                       :url="angelf1a01821_f3e5.url"
                       :visible="angelf1a01821_f3e5.visible"
                       :class="angelf1a01821_f3e5.class"
      ></hyperlink-image>
      <hyperlink-image :name="angelf1a01821_f3e6.name"
                       :position="angelf1a01821_f3e6.position"
                       :rotation="angelf1a01821_f3e6.rotation"
                       :size="angelf1a01821_f3e6.size"
                       :scale="angelf1a01821_f3e6.scale"
                       :ImgUrl="angelf1a01821_f3e6.ImgUrl"
                       :url="angelf1a01821_f3e6.url"
                       :visible="angelf1a01821_f3e6.visible"
                       :class="angelf1a01821_f3e6.class"
      ></hyperlink-image>
      <hyperlink-image :name="angelf1a01821_f3e7.name"
                       :position="angelf1a01821_f3e7.position"
                       :rotation="angelf1a01821_f3e7.rotation"
                       :size="angelf1a01821_f3e7.size"
                       :scale="angelf1a01821_f3e7.scale"
                       :ImgUrl="angelf1a01821_f3e7.ImgUrl"
                       :url="angelf1a01821_f3e7.url"
                       :visible="angelf1a01821_f3e7.visible"
                       :class="angelf1a01821_f3e7.class"
      ></hyperlink-image>
      <hyperlink-image :name="angelf1a01821_f3e8.name"
                       :position="angelf1a01821_f3e8.position"
                       :rotation="angelf1a01821_f3e8.rotation"
                       :size="angelf1a01821_f3e8.size"
                       :scale="angelf1a01821_f3e8.scale"
                       :ImgUrl="angelf1a01821_f3e8.ImgUrl"
                       :url="angelf1a01821_f3e8.url"
                       :visible="angelf1a01821_f3e8.visible"
                       :class="angelf1a01821_f3e8.class"
      ></hyperlink-image>
      <hyperlink-image :name="angelf1a01821_f3e9.name"
                       :position="angelf1a01821_f3e9.position"
                       :rotation="angelf1a01821_f3e9.rotation"
                       :size="angelf1a01821_f3e9.size"
                       :scale="angelf1a01821_f3e9.scale"
                       :ImgUrl="angelf1a01821_f3e9.ImgUrl"
                       :url="angelf1a01821_f3e9.url"
                       :visible="angelf1a01821_f3e9.visible"
                       :class="angelf1a01821_f3e9.class"
      ></hyperlink-image>
      <hyperlink-image :name="angelf1a01821_f3f0.name"
                       :position="angelf1a01821_f3f0.position"
                       :rotation="angelf1a01821_f3f0.rotation"
                       :size="angelf1a01821_f3f0.size"
                       :scale="angelf1a01821_f3f0.scale"
                       :ImgUrl="angelf1a01821_f3f0.ImgUrl"
                       :url="angelf1a01821_f3f0.url"
                       :visible="angelf1a01821_f3f0.visible"
                       :class="angelf1a01821_f3f0.class"
      ></hyperlink-image>
      <hyperlink-image :name="angelf1a01821_f3f1.name"
                       :position="angelf1a01821_f3f1.position"
                       :rotation="angelf1a01821_f3f1.rotation"
                       :size="angelf1a01821_f3f1.size"
                       :scale="angelf1a01821_f3f1.scale"
                       :ImgUrl="angelf1a01821_f3f1.ImgUrl"
                       :url="angelf1a01821_f3f1.url"
                       :visible="angelf1a01821_f3f1.visible"
                       :class="angelf1a01821_f3f1.class"
      ></hyperlink-image>
      <hyperlink-image :name="angelf1a01821_f3f2.name"
                       :position="angelf1a01821_f3f2.position"
                       :rotation="angelf1a01821_f3f2.rotation"
                       :size="angelf1a01821_f3f2.size"
                       :scale="angelf1a01821_f3f2.scale"
                       :ImgUrl="angelf1a01821_f3f2.ImgUrl"
                       :url="angelf1a01821_f3f2.url"
                       :visible="angelf1a01821_f3f2.visible"
                       :class="angelf1a01821_f3f2.class"
      ></hyperlink-image>
      <hyperlink-image :name="angelf1a01821_f3f3.name"
                       :position="angelf1a01821_f3f3.position"
                       :rotation="angelf1a01821_f3f3.rotation"
                       :size="angelf1a01821_f3f3.size"
                       :scale="angelf1a01821_f3f3.scale"
                       :ImgUrl="angelf1a01821_f3f3.ImgUrl"
                       :url="angelf1a01821_f3f3.url"
                       :visible="angelf1a01821_f3f3.visible"
                       :class="angelf1a01821_f3f3.class"
      ></hyperlink-image>
      <hyperlink-image :name="angelf1a01821_f3f4.name"
                       :position="angelf1a01821_f3f4.position"
                       :rotation="angelf1a01821_f3f4.rotation"
                       :size="angelf1a01821_f3f4.size"
                       :scale="angelf1a01821_f3f4.scale"
                       :ImgUrl="angelf1a01821_f3f4.ImgUrl"
                       :url="angelf1a01821_f3f4.url"
                       :visible="angelf1a01821_f3f4.visible"
                       :class="angelf1a01821_f3f4.class"
      ></hyperlink-image>

      <hyperlink-image :name="angelf1a01821_f3f6.name"
                       :position="angelf1a01821_f3f6.position"
                       :rotation="angelf1a01821_f3f6.rotation"
                       :size="angelf1a01821_f3f6.size"
                       :scale="angelf1a01821_f3f6.scale"
                       :ImgUrl="angelf1a01821_f3f6.ImgUrl"
                       :url="angelf1a01821_f3f6.url"
                       :visible="angelf1a01821_f3f6.visible"
                       :class="angelf1a01821_f3f6.class"
      ></hyperlink-image>

      <hyperlink-image :name="angelf1a01821_f3f8.name"
                       :position="angelf1a01821_f3f8.position"
                       :rotation="angelf1a01821_f3f8.rotation"
                       :size="angelf1a01821_f3f8.size"
                       :scale="angelf1a01821_f3f8.scale"
                       :ImgUrl="angelf1a01821_f3f8.ImgUrl"
                       :url="angelf1a01821_f3f8.url"
                       :visible="angelf1a01821_f3f8.visible"
                       :class="angelf1a01821_f3f8.class"
      ></hyperlink-image>

      <hyperlink-image :name="angelf1a01821_f3fa.name"
                       :position="angelf1a01821_f3fa.position"
                       :rotation="angelf1a01821_f3fa.rotation"
                       :size="angelf1a01821_f3fa.size"
                       :scale="angelf1a01821_f3fa.scale"
                       :ImgUrl="angelf1a01821_f3fa.ImgUrl"
                       :url="angelf1a01821_f3fa.url"
                       :visible="angelf1a01821_f3fa.visible"
                       :class="angelf1a01821_f3fa.class"
      ></hyperlink-image>
      <hyperlink-image :name="angelf1a01821_f3fb.name"
                       :position="angelf1a01821_f3fb.position"
                       :rotation="angelf1a01821_f3fb.rotation"
                       :size="angelf1a01821_f3fb.size"
                       :scale="angelf1a01821_f3fb.scale"
                       :ImgUrl="angelf1a01821_f3fb.ImgUrl"
                       :url="angelf1a01821_f3fb.url"
                       :visible="angelf1a01821_f3fb.visible"
                       :class="angelf1a01821_f3fb.class"
      ></hyperlink-image>
    </free-move>
  </div>
</template>

<script>
    import Sky from "./vue-three/vue_three_js/sky/sky";
    export default {
        name: "Social",
        components: {Sky},
        props: {
            msg: String
        },
        data() {

            return {
                "angelad83bfef_db71": {
                    "modelUrl": "/models/gltf/scene.glb",
                    "edition": 1.1,
                    "size": {
                        "w": 0,
                        "h": 0
                    },
                    "insetsize": {
                        "w": 0,
                        "h": 0
                    },
                    "CameraPosition": {
                        "x": 1.4,
                        "y": 1.6,
                        "z": 3.5
                    },
                    "CameraRotation": {
                        "x": Math.PI,
                        "y": Math.PI / 4,
                        "z": Math.PI
                    },
                    "octBlackList": [
                        "ad9403","ad9404","light9405","model9407","pos9408","loof9409"
                    ],
                    "isAddOct": true,
                    "GridHelpers": false,
                    "minAngle": 0.5,
                    "maxAngle": 0.5,
                    "radius": 0.3,
                    "mvSpeed": 15
                },
                "angelf1a01821_f3d7": {
                    "name": "angelf1a01821_f3d7",
                    "position": {
                        "x": -3.5,
                        "y": 2.48,
                        "z": -5.28
                    },
                    "rotation": {
                        "x": -3.141592653589793,
                        "y": 0,
                        "z": -3.141592653589793
                    },
                    "size": {
                        "w": 3,
                        "h": 4.2
                    },
                    "scale": 1,
                    "ImgUrl": "/img/jieshao.png",
                    "visible": true,
                },
                "angelf1a01821_f3d8": {
                    "name": "angelf1a01821_f3d8",
                    "position": {
                        "x": -3.38,
                        "y": 1.61,
                        "z": -4.37
                    },
                    "rotation": {
                        "x": 0,
                        "y": 0,
                        "z": 0
                    },
                    "size": {
                        "w": 3,
                        "h": 2
                    },
                    "scale": 1,
                    "ImgUrl": "/img/wenhua1.png",
                    "visible": true
                },
                "angelf1a01821_f3d9": {
                    "name": "angelf1a01821_f3d9",
                    "position": {
                        "x": -2.45,
                        "y": 1.57,
                        "z": 3.52
                    },
                    "rotation": {
                        "x": -3.141592653589793,
                        "y": 0,
                        "z": -3.141592653589793
                    },
                    "size": {
                        "w": 0.48,
                        "h": 1.45
                    },
                    "scale": 1,
                    "ImgUrl": "/img/wenhua2.png",
                    "visible": true
                },
                "angelf1a01821_f3e0": {
                    "name": "angelf1a01821_f3e0",
                    "position": {
                        "x": -3.76,
                        "y": 1.57,
                        "z": 3.52
                    },
                    "rotation": {
                        "x": -3.141592653589793,
                        "y": 0,
                        "z": -3.141592653589793
                    },
                    "size": {
                        "w": 0.78,
                        "h": 1.45
                    },
                    "scale": 1,
                    "ImgUrl": "/img/wenhua3.png",
                    "visible": true
                },
                "angelf1a01821_f3e1": {
                    "name": "angelf1a01821_f3e1",
                    "position": {
                        "x": 0.32,
                        "y": 1.54,
                        "z": -0.37
                    },
                    "rotation": {
                        "x": 0,
                        "y": -1.57,
                        "z": 0
                    },
                    "size": {
                        "w": 0.54,
                        "h": 1.26
                    },
                    "scale": 1,
                    "ImgUrl": "/img/wenhua2.png",
                    "visible": true
                },
                "angelf1a01821_f3e2": {
                    "name": "angelf1a01821_f3e2",
                    "position": {
                        "x": 3.34,
                        "y": 1.54,
                        "z": -0.37
                    },
                    "rotation": {
                        "x": 0,
                        "y": -1.57,
                        "z": 0
                    },
                    "size": {
                        "w": 0.54,
                        "h": 1.26
                    },
                    "scale": 1,
                    "ImgUrl": "/img/wenhua2.png",
                    "visible": true
                },
                "angelf1a01821_f3e3": {
                    "name": "angelf1a01821_f3e3",
                    "position": {
                        "x": -1.70,
                        "y": 1.53,
                        "z": 1.24
                    },
                    "rotation": {
                        "x": -3.14,
                        "y": 0,
                        "z": -3.14
                    },
                    "size": {
                        "w": 0.39,
                        "h": 1.26
                    },
                    "scale": 1,
                    "ImgUrl": "/img/wenhua2.png",
                    "visible": true
                },
                "angelf1a01821_f3e4": {
                    "name": "angelf1a01821_f3e4",
                    "position": {
                        "x": -2.43,
                        "y": 1.53,
                        "z": 1.24
                    },
                    "rotation": {
                        "x": -3.14,
                        "y": 0,
                        "z": -3.14
                    },
                    "size": {
                        "w": 0.26,
                        "h": 1.26
                    },
                    "scale": 1,
                    "ImgUrl": "/img/wenhua2.png",
                    "visible": true
                },

                "angelf1a01821_f3e5": {
                    "name": "angelf1a01821_f3e3",
                    "position": {
                        "x": 6.45,
                        "y": 1.53,
                        "z": -1.94
                    },
                    "rotation": {
                        "x": -3.14,
                        "y": 0,
                        "z": -3.14
                    },
                    "size": {
                        "w": 0.39,
                        "h": 1.26
                    },
                    "scale": 1,
                    "ImgUrl": "/img/wenhua2.png",
                    "visible": true
                },
                "angelf1a01821_f3e6": {
                    "name": "angelf1a01821_f3e4",
                    "position": {
                        "x": 5.71,
                        "y": 1.53,
                        "z": -1.94
                    },
                    "rotation": {
                        "x": -3.14,
                        "y": 0,
                        "z": -3.14
                    },
                    "size": {
                        "w": 0.26,
                        "h": 1.26
                    },
                    "scale": 1,
                    "ImgUrl": "/img/wenhua2.png",
                    "visible": true
                },
                "angelf1a01821_f3e7": {
                    "name": "angelf1a01821_f3e7",
                    "position": {
                        "x": 4.85,
                        "y": 1.57,
                        "z": 3.46
                    },
                    "rotation": {
                        "x": -3.14,
                        "y": 0,
                        "z": -3.14
                    },
                    "size": {
                        "w": 0.48,
                        "h": 1.45
                    },
                    "scale": 1,
                    "ImgUrl": "/img/huodong1.png",
                    "visible": true
                },
                "angelf1a01821_f3e8": {
                    "name": "angelf1a01821_f3e8",
                    "position": {
                        "x": 7.6,
                        "y": 1.57,
                        "z": 3.46
                    },
                    "rotation": {
                        "x": -3.14,
                        "y": 0,
                        "z": -3.14
                    },
                    "size": {
                        "w": 0.48,
                        "h": 1.45
                    },
                    "scale": 1,
                    "ImgUrl": "/img/huodong1.png",
                    "visible": true
                },
                "angelf1a01821_f3e9": {
                    "name": "angelf1a01821_f3e8",
                    "position": {
                        "x": 6.22,
                        "y": 1.57,
                        "z": 3.46
                    },
                    "rotation": {
                        "x": -3.14,
                        "y": 0,
                        "z": -3.14
                    },
                    "size": {
                        "w": 0.78,
                        "h": 1.45
                    },
                    "scale": 1,
                    "ImgUrl": "/img/huodong3.png",
                    "visible": true
                },
                "angelf1a01821_f3f0": {
                    "name": "angelf1a01821_f3f0",
                    "position": {
                        "x": 4.70,
                        "y": 1.57,
                        "z": -4.21
                    },
                    "rotation": {
                        "x": 0,
                        "y": 0,
                        "z": 0
                    },
                    "size": {
                        "w": 0.48,
                        "h": 1.45
                    },
                    "scale": 1,
                    "ImgUrl": "/img/huodong1.png",
                    "visible": true
                },
                "angelf1a01821_f3f1": {
                    "name": "angelf1a01821_f3f1",
                    "position": {
                        "x": 7.56,
                        "y": 1.57,
                        "z": -4.21
                    },
                    "rotation": {
                        "x": 0,
                        "y": 0,
                        "z": 0
                    },
                    "size": {
                        "w": 0.48,
                        "h": 1.45
                    },
                    "scale": 1,
                    "ImgUrl": "/img/huodong1.png",
                    "visible": true
                },
                "angelf1a01821_f3f2": {
                    "name": "angelf1a01821_f3f2",
                    "position": {
                        "x": 6.14,
                        "y": 1.57,
                        "z": -4.21
                    },
                    "rotation": {
                        "x": 0,
                        "y": 0,
                        "z": 0
                    },
                    "size": {
                        "w": 0.78,
                        "h": 1.45
                    },
                    "scale": 1,
                    "ImgUrl": "/img/huodong3.png",
                    "visible": true
                },
                "angelf1a01821_f3f3": {
                    "name": "angelf1a01821_f3f3",
                    "position": {
                        "x": 10.18,
                        "y": 1.51,
                        "z": 8.88
                    },
                    "rotation": {
                        "x": 0,
                        "y": -1.57,
                        "z": 0
                    },
                    "size": {
                        "w": 6.2,
                        "h": 2.75
                    },
                    "scale": 1,
                    "ImgUrl": "/img/zhuxuan.png",
                    "visible": true
                },
                "angelf1a01821_f3f4": {
                    "name": "angelf1a01821_f3f4",
                    "position": {
                        "x": 1.595,
                        "y": 1.99,
                        "z": 18.15
                    },
                    "rotation": {
                        "x": 3.14,
                        "y": 0,
                        "z": 3.14
                    },
                    "size": {
                        "w": 1.36,
                        "h": 0.76
                    },
                    "scale": 1,
                    "ImgUrl": "/img/enter.png",
                    "visible": true
                },

                "angelf1a01821_f3f6": {
                    "name": "angelf1a01821_f3f6",
                    "position": {
                        "x": -1.315,
                        "y": 1.99,
                        "z": 18.15
                    },
                    "rotation": {
                        "x": 3.14,
                        "y": 0,
                        "z": 3.14
                    },
                    "size": {
                        "w": 1.36,
                        "h": 0.76
                    },
                    "scale": 1,
                    "ImgUrl": "/img/promotion.png",
                    "visible": true
                },
                "angelf1a01821_f3f8": {
                    "name": "angelf1a01821_f3f8",
                    "position": {
                        "x": -3.95,
                        "y": 1.99,
                        "z": 18.15
                    },
                    "rotation": {
                        "x": 3.14,
                        "y": 0,
                        "z": 3.14
                    },
                    "size": {
                        "w": 1.36,
                        "h": 0.76
                    },
                    "scale": 1,
                    "ImgUrl": "/img/culture.png",
                    "visible": true
                },

                "angelf1a01821_f3fa": {
                    "name": "angelf1a01821_f3fa",
                    "position": {
                        "x": -5.27,
                        "y": 2.18,
                        "z": 7.83
                    },
                    "rotation": {
                        "x": 0,
                        "y": 1.57,
                        "z": 0
                    },
                    "size": {
                        "w": 0.55,
                        "h": 0.76
                    },
                    "scale": 1,
                    "ImgUrl": "/img/ad1.png",
                    "visible": true
                },
                "angelf1a01821_f3fb": {
                    "name": "angelf1a01821_f3fb",
                    "position": {
                        "x": -5.27,
                        "y": 2.18,
                        "z": 7.23
                    },
                    "rotation": {
                        "x": 0,
                        "y": 1.57,
                        "z": 0
                    },
                    "size": {
                        "w": 0.55,
                        "h": 0.76
                    },
                    "scale": 1,
                    "ImgUrl": "/img/ad2.png",
                    "visible": true
                },
            }
                ;
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>
