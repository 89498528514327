<template>
        <video controls="controls" :src="src" ref="video" class="video"></video>
</template>

<script>
    export default {
        props:['src','positions','sizes','degs'],
        name: "videoComponent",
            data(){
                    return{
                            deg:0
                    }
            },
        mounted() {
                // if(this.degs){
                //         this.deg=this.degs
                // }
            let box=this.$refs.video;
            box.style.position=this.positions.type;
                if(this.positions.type!=='static'){
                        box.style.top=this.positions.y+'px';
                        box.style.left=this.positions.x+'px';
                }else{
                        box.style.marginTop=this.positions.y+'px';
                        box.style.marginLeft=this.positions.x+'px';
                }
            box.style.height=this.sizes.h+this.sizes.typeH;
            box.style.width=this.sizes.w+this.sizes.typeW;
                // if (this.positions.center && this.positions.vetically) {
                //         box.style.position = 'absolute';
                //         box.style.left = '50%';
                //         box.style.top = '50%';
                //         box.style.transform = 'translate(-' + 50 + '%,-' + 50 + '%) rotateZ('+this.deg+'deg)'
                // } else if (this.positions.center) {
                //         box.style.position = 'absolute';
                //         box.style.left = '50%';
                //         box.style.transform = 'translateX(-' + 50 + '%) rotateZ('+this.deg+'deg)'
                // } else if (this.positions.vetically) {
                //         box.style.position = 'absolute';
                //         box.style.top = '50%';
                //         box.style.transform = 'translateY(-' + 50 + '%) rotateZ('+this.deg+'deg)'
                // }else {
                //         box.style.transform=`rotateZ(${this.degs}deg)`;
                // }
                box.style.transform=`rotateZ(${this.degs}deg)`;
        }
    }
</script>

<style scoped>
.video{
        width: 100%;
}
</style>
