<template>
  <div class="box">
<!--    <free-show :size="angeldff74eeb_f57f.size"-->
<!--               :insetsize="angeldff74eeb_f57f.insetsize"-->
<!--               :CameraPosition="angeldff74eeb_f57f.CameraPosition"-->
<!--               :CameraRotation="angeldff74eeb_f57f.CameraRotation"-->
<!--               :minDistance="angeldff74eeb_f57f.minDistance"-->
<!--               :maxDistance="angeldff74eeb_f57f.maxDistance"-->
<!--               :target="angeldff74eeb_f57f.target"-->
<!--               :autoRotate="angeldff74eeb_f57f.autoRotate"-->
<!--               :GridHelper="angeldff74eeb_f57f.GridHelper"-->
<!--               :autoRotateSpeed="angeldff74eeb_f57f.autoRotateSpeed"-->
<!--               :angle="angeldff74eeb_f57f.angle"-->
<!--               :class="angeldff74eeb_f57f.class"-->
<!--    >-->
    <free-move
        :modelUrl="angeldff74eeb_f57f.modelUrl"
                   :position="angeldff74eeb_f57f.position"
                   :rotation="angeldff74eeb_f57f.rotation"
                   :scale="angeldff74eeb_f57f.scale"
                :size="angeldff74eeb_f57f.size"
               :insetsize="angeldff74eeb_f57f.insetsize"
               :CameraPosition="angeldff74eeb_f57f.CameraPosition"
               :CameraRotation="angeldff74eeb_f57f.CameraRotation"
               :GridHelpers="angeldff74eeb_f57f.GridHelpers"
               :minAngle="angeldff74eeb_f57f.minAngle"
               :maxAngle="angeldff74eeb_f57f.maxAngle"
               :class="angeldff74eeb_f57f.class"
        :isAddOct="angeldff74eeb_f57f.isAddOct"
        :octBlackList="angeldff74eeb_f57f.octBlackList"
        :radius="angeldff74eeb_f57f.radius"
        :mvSpeed="angeldff74eeb_f57f.mvSpeed"
    >
      <sky ></sky>
      <div>
        <backdrop :bgCorlor="angelcaf0b1e3_7b85.bgCorlor"
                  :url="angelcaf0b1e3_7b85.url"
                  :envMap="angelcaf0b1e3_7b85.envMap"
                  :px="angelcaf0b1e3_7b85.px"
                  :nx="angelcaf0b1e3_7b85.nx"
                  :py="angelcaf0b1e3_7b85.py"
                  :ny="angelcaf0b1e3_7b85.ny"
                  :pz="angelcaf0b1e3_7b85.pz"
                  :nz="angelcaf0b1e3_7b85.nz"
                  :class="angelcaf0b1e3_7b85.class"
        ></backdrop>
      </div>
      <div>
<!--        <model :name="angel8a0cc001_5a41.name"-->
<!--               :modelsrc="angel8a0cc001_5a41.modelsrc"-->
<!--               :position="angel8a0cc001_5a41.position"-->
<!--               :rotation="angel8a0cc001_5a41.rotation"-->
<!--               :renderorder="angel8a0cc001_5a41.renderorder"-->
<!--               :edition="angel8a0cc001_5a41.edition"-->
<!--               :scale="angel8a0cc001_5a41.scale"-->
<!--               :class="angel8a0cc001_5a41.class"-->
<!--        ></model>-->
      </div>
      <div>
        <direction-light :name="angel993032f5_1084.name"
                         :hex="angel993032f5_1084.hex"
                         :intensity="angel993032f5_1084.intensity"
                         :position="angel993032f5_1084.position"
                         :targetPosition="angel993032f5_1084.targetPosition"
                         :bias="angel993032f5_1084.bias"
                         :far="angel993032f5_1084.far"
                         :edge="angel993032f5_1084.edge"
                         :mapSize="angel993032f5_1084.mapSize"
                         :castShadow="angel993032f5_1084.castShadow"
                         :class="angel993032f5_1084.class"
        ></direction-light>
      </div>
      <div>
        <negative-object :name="angel5ec6fc3b_3ad3.name"
                         :objectName="angel5ec6fc3b_3ad3.objectName"
                         :redirectUrl="angel5ec6fc3b_3ad3.redirectUrl"
                         :class="angel5ec6fc3b_3ad3.class"
                         :miniProgramPath="angel5ec6fc3b_3ad3.miniProgramPath"
        ></negative-object>
      </div>
      <div>
        <negative-object :name="angelaf1ee1b0_d398.name"
                         :objectName="angelaf1ee1b0_d398.objectName"
                         :redirectUrl="angelaf1ee1b0_d398.redirectUrl"
                         :class="angelaf1ee1b0_d398.class"
                         :miniProgramPath="angelaf1ee1b0_d398.miniProgramPath"
        ></negative-object>
      </div>
      <div>
        <hyperlink-image :name="angel95a630e9_e2fc.name"
                         :position="angel95a630e9_e2fc.position"
                         :rotation="angel95a630e9_e2fc.rotation"
                         :size="angel95a630e9_e2fc.size"
                         :scale="angel95a630e9_e2fc.scale"
                         :ImgUrl="angel95a630e9_e2fc.ImgUrl"
                         :url="angel95a630e9_e2fc.url"
                         :visible="angel95a630e9_e2fc.visible"
                         :class="angel95a630e9_e2fc.class"
                         :miniProgramPath="angel95a630e9_e2fc.miniProgramPath"
        ></hyperlink-image>
      </div>
      <div>
        <hyperlink-image :name="angele4422c06_8c06.name"
                         :position="angele4422c06_8c06.position"
                         :rotation="angele4422c06_8c06.rotation"
                         :size="angele4422c06_8c06.size"
                         :scale="angele4422c06_8c06.scale"
                         :ImgUrl="angele4422c06_8c06.ImgUrl"
                         :url="angele4422c06_8c06.url"
                         :visible="angele4422c06_8c06.visible"
                         :class="angele4422c06_8c06.class"
                         :miniProgramPath="angele4422c06_8c06.miniProgramPath"
        ></hyperlink-image>
      </div>
      <div>
        <hyperlink-image :name="angele4422c06_8c07.name"
                         :position="angele4422c06_8c07.position"
                         :rotation="angele4422c06_8c07.rotation"
                         :size="angele4422c06_8c07.size"
                         :scale="angele4422c06_8c07.scale"
                         :ImgUrl="angele4422c06_8c07.ImgUrl"
                         :url="angele4422c06_8c07.url"
                         :visible="angele4422c06_8c07.visible"
                         :class="angele4422c06_8c07.class"
                         :miniProgramPath="angele4422c06_8c07.miniProgramPath"
        ></hyperlink-image>
      </div>
      <div>
        <hyperlink-image :name="angel957ac90f_d798.name"
                         :position="angel957ac90f_d798.position"
                         :rotation="angel957ac90f_d798.rotation"
                         :size="angel957ac90f_d798.size"
                         :scale="angel957ac90f_d798.scale"
                         :ImgUrl="angel957ac90f_d798.ImgUrl"
                         :url="angel957ac90f_d798.url"
                         :visible="angel957ac90f_d798.visible"
                         :class="angel957ac90f_d798.class"
                         :miniProgramPath="angel957ac90f_d798.miniProgramPath"
        ></hyperlink-image>
      </div>
      <div>
        <hyperlink-image :name="angel8b8d9966_c08a.name"
                         :position="angel8b8d9966_c08a.position"
                         :rotation="angel8b8d9966_c08a.rotation"
                         :size="angel8b8d9966_c08a.size"
                         :scale="angel8b8d9966_c08a.scale"
                         :ImgUrl="angel8b8d9966_c08a.ImgUrl"
                         :url="angel8b8d9966_c08a.url"
                         :visible="angel8b8d9966_c08a.visible"
                         :class="angel8b8d9966_c08a.class"
                         :miniProgramPath="angel8b8d9966_c08a.miniProgramPath"
        ></hyperlink-image>
      </div>
      <div>
        <hyperlink-image :name="angeld0819f06_7962.name"
                         :position="angeld0819f06_7962.position"
                         :rotation="angeld0819f06_7962.rotation"
                         :size="angeld0819f06_7962.size"
                         :scale="angeld0819f06_7962.scale"
                         :ImgUrl="angeld0819f06_7962.ImgUrl"
                         :url="angeld0819f06_7962.url"
                         :visible="angeld0819f06_7962.visible"
                         :class="angeld0819f06_7962.class"
                         :miniProgramPath="angeld0819f06_7962.miniProgramPath"
        ></hyperlink-image>
      </div>
      <div>
        <hyperlink-image :name="angel36a1706b_5854.name"
                         :position="angel36a1706b_5854.position"
                         :rotation="angel36a1706b_5854.rotation"
                         :size="angel36a1706b_5854.size"
                         :scale="angel36a1706b_5854.scale"
                         :ImgUrl="angel36a1706b_5854.ImgUrl"
                         :url="angel36a1706b_5854.url"
                         :visible="angel36a1706b_5854.visible"
                         :class="angel36a1706b_5854.class"
                         :miniProgramPath="angel36a1706b_5854.miniProgramPath"
                         :editable="angel36a1706b_5854.editable"
        ></hyperlink-image>
      </div>
      <div>
        <hyperlink-image :name="angel32fd2562_8ebf.name"
                         :position="angel32fd2562_8ebf.position"
                         :rotation="angel32fd2562_8ebf.rotation"
                         :size="angel32fd2562_8ebf.size"
                         :scale="angel32fd2562_8ebf.scale"
                         :ImgUrl="angel32fd2562_8ebf.ImgUrl"
                         :url="angel32fd2562_8ebf.url"
                         :visible="angel32fd2562_8ebf.visible"
                         :class="angel32fd2562_8ebf.class"
                         :miniProgramPath="angel32fd2562_8ebf.miniProgramPath"
                         :editable="angel32fd2562_8ebf.editable"
        ></hyperlink-image>
      </div>
      <div>
        <video-switch :name="angelff9a5c2e_4cf6.name"
                      :position="angelff9a5c2e_4cf6.position"
                      :rotation="angelff9a5c2e_4cf6.rotation"
                      :scale="angelff9a5c2e_4cf6.scale"
                      :videourl="angelff9a5c2e_4cf6.videourl"
                      :play="angelff9a5c2e_4cf6.play"
                      :suspend="angelff9a5c2e_4cf6.suspend"
                      :visible="angelff9a5c2e_4cf6.visible"
                      :class="angelff9a5c2e_4cf6.class"
        ></video-switch>
      </div>
    </free-move>
<!--    </free-show>-->
  </div>

</template>

<script>
    import wxShare from '../utils/wxSDK';

    import Axios from 'axios'
    // import CircularJSON from 'circular-json'; 
    export default {
        name: 'HelloWorld',
        props: {
            msg: String
        },
        data() {

            let iUser = this.getUrlKey('iUser');
            let pUser = this.getUrlKey('pUser');
            let wxUser = this.getUrlKey('wxUser');
            let iBrand = this.getUrlKey('iBrand');

            let isOwn = false

            if (iUser && iUser === wxUser){
                isOwn = true;
            }

            console.log('iUser', iUser)
            console.log('wxUser', wxUser)
            console.log('iBrand', iBrand)
            console.log('isOwn', isOwn)

            let appData = {
                    "angeldff74eeb_f57f": {
                        "modelUrl": "/models/gltf/geren.glb",
                        "position": {
                            "x": -37.44,
                            "y": -1.2,
                            "z": 30.81
                        },
                        "rotation": {
                            "x": 0,
                            "y": 0,
                            "z": 0
                        },

                        "scale": 100,
                        "size": {
                            "w": 0,
                            "h": 0
                        },
                        "insetsize": {
                            "w": 0,
                            "h": 0
                        },
                        "CameraPosition": {
                            "x": -30,
                            "y": 18,
                            "z": 30
                        },
                        "CameraRotation": {
                            "x": 0,
                            "y": -0.82,
                            "z": 0
                        },
                        "CameraScale": 14,
                        "minDistance": 10,
                        "maxDistance": 100,
                        "target": {
                            "x": 0,
                            "y": 10,
                            "z": 0
                        },
                        "octBlackList": [
"chair9383","lit9396","mus9406","TV120","laptop001","model_202176125318781","Desk001","10077","card9410","ad9411","board9412"
                        ],
                        "radius": 5,
                        "GridHelpers": false,
                        "isAddOct": true,
                        "maxAngle": 0.5,
                        "minAngle": 0.5,
                        "mvSpeed": 100
                    },
                    "angelcaf0b1e3_7b85": {
                        "bgCorlor": "#cccccc",
                        "url": ""
                    },
                    // "angel8a0cc001_5a41": {
                    //     "name": "angel8a0cc001_5a41",
                    //     "modelsrc": "/static/aaa.glb",
                    //     "position": {
                    //         "x": -3.4,
                    //         "y": -0.12,
                    //         "z": 2.8
                    //     },
                    //     "rotation": {
                    //         "x": 0,
                    //         "y": 0,
                    //         "z": 0
                    //     },
                    //     "renderorder": 0,
                    //     "edition": 1,
                    //     "scale": 10
                    // },
                    "angel993032f5_1084": {
                        "name": "angel993032f5_1084",
                        "hex": "#fff",
                        "intensity": 1,
                        "position": {
                            "x": -100,
                            "y": 100,
                            "z": 100
                        },
                        "targetPosition": {
                            "x": 0,
                            "y": 1,
                            "z": 0
                        },
                        "bias": -0.0001,
                        "far": 500,
                        "edge": 50,
                        "mapSize": {
                            "w": 2048,
                            "h": 2048
                        },
                        "castShadow": true
                    },
                    "angel5ec6fc3b_3ad3": {
                        "name": "angel5ec6fc3b_3ad3",
                        "objectName": "Obj3d66-551855-464-732",
                        "miniProgramPath": "/thirdPackages/pages/online_red_group_sales/online_red_group_sales?iBrand=" + iBrand
                    },

                    "angelaf1ee1b0_d398": {
                        "name": "angelaf1ee1b0_d398",
                        "objectName": "dsa1720",
                    },
                "angele4422c06_8c06": {
                    "name": "angele4422c06_8c06",
                    "position": {
                        "x": -7.650,
                        "y": 10.900,
                        "z": 8.180,
                    },
                    "rotation": {
                        "x": -0.335,
                        "y": -0.593,
                        "z": -0.180
                    },
                    "size": {
                        "w": 9.3,
                        "h": 14.6
                    },
                    "scale": 1,
                    "ImgUrl": "/static/card1.png",
                    "miniProgramPath": "/subPackages/pages/leaving_message/leaving_message?iUser=" + iUser,
                    // "url": "./social",
                    "url": "/subPackages/pages/leaving_message/leaving_message?iUser=" + iUser,
                    "visible": true,
                },
                "angele4422c06_8c07": {
                    "name": "angele4422c06_8c07",
                    "position": {
                        "x": -14.1,
                        "y": 25.5,
                        "z": 1
                    },
                    "rotation": {
                        "x": 0,
                        "y": 0,
                        "z": 0
                    },
                    "size": {
                        "w": 6.4,
                        "h": 9
                    },
                    "scale": 1,
                    "ImgUrl": "/static/card2.png",
                    "miniProgramPath": "/fourthPackages/pages/reality_store_resource/reality_store_resource?iStore=56",
                    "url": "/fourthPackages/pages/reality_store_resource/reality_store_resource?iStore=56",
                    "visible": true,
                },

                    "angel95a630e9_e2fc": {
                        "name": "angel95a630e9_e2fc",
                        "position": {
                            "x": -35.2,
                            "y": 25.5,
                            "z": 1
                        },
                        "rotation": {
                            "x": 0,
                            "y": 0,
                            "z": 0
                        },
                        "size": {
                            "w": "6.4",
                            "h": "9"
                        },
                        "scale": 1,
                        "ImgUrl": "/static/card3.png",
                        "miniProgramPath": "",
                        "url": "",
                        "visible": true,
                    },

                    "angel957ac90f_d798": {
                        "name": "angel957ac90f_d798",
                        "position": {
                            "x": -42.7,
                            "y": 25.5,
                            "z": 1
                        },
                        "rotation": {
                            "x": 0,
                            "y": 0,
                            "z": 0
                        },
                        "size": {
                            "w": 6.4,
                            "h": 9
                        },
                        "scale": 1,
                        "ImgUrl": "/static/card4.png",
                        "miniProgramPath": "",
                        "url": "",
                        "visible": true,
                    },
                    "angel8b8d9966_c08a": {
                        "name": "angel8b8d9966_c08a",
                        "position": {
                            "x": -1.1,
                            "y": 25.5,
                            "z": 8.45
                        },
                        "rotation": {
                            "x": 0,
                            "y": -1.57,
                            "z": 0
                        },
                        "size": {
                            "w": "7",
                            "h": "10"
                        },
                        "scale": 1,
                        "ImgUrl": "/static/status.png",
                        "miniProgramPath": "/secondPackages/pages/meeting_appraisal/meeting_appraisal?iMeeting=12",
                        "url": "/secondPackages/pages/meeting_appraisal/meeting_appraisal?iMeeting=12",
                        "visible": true,
                    },
                    "angeld0819f06_7962": {
                        "name": "angeld0819f06_7962",
                        "position": {
                            "x": -1.1,
                            "y": 25.5,
                            "z": 24.04
                        },
                        "rotation": {
                            "x": 0,
                            "y": -1.5707963267948966,
                            "z": 0
                        },
                        "size": {
                            "w": 7,
                            "h": 10
                        },
                        "scale": 1,
                        "ImgUrl": "/static/street.png",
                        "miniProgramPath": "/thirdPackages/pages/trade_message/trade_message?iUser=" + iUser,
                        "url": "/thirdPackages/pages/trade_message/trade_message?iUser=" + iUser,
                        "visible": true,
                    },
                    "angel36a1706b_5854": {
                        "name": "angel36a1706b_5854",
                        "position": {
                            "x": -1.1,
                            "y": 25.5,
                            "z": 38.9
                        },
                        "rotation": {
                            "x": 0,
                            "y": -1.5707963267948966,
                            "z": 0
                        },
                        "size": {
                            "w": 7,
                            "h": 10
                        },
                        "scale": 1,
                        "ImgUrl": "/static/street.png",
                        "miniProgramPath": "/thirdPackages/pages/trade_message/trade_message?iUser=" + iUser,
                        "url": "/thirdPackages/pages/trade_message/trade_message?iUser=" + iUser,
                        "visible": true,
                        "editable" : isOwn
                    },
                    "angel32fd2562_8ebf": {
                        "name": "angel32fd2562_8ebf",
                        "position": {
                            "x": -1.1,
                            "y": 25.5,
                            "z": 47.35
                        },
                        "rotation": {
                            "x": 0,
                            "y": -1.5707963267948966,
                            "z": 0
                        },
                        "size": {
                            "w": 7,
                            "h": 10
                        },
                        "scale": 1,
                        "ImgUrl": "/static/cust2.png",
                        "miniProgramPath": "/thirdPackages/pages/trade_message/trade_message?iUser=" + iUser,
                        "url": "/thirdPackages/pages/trade_message/trade_message?iUser=" + iUser,
                        "visible": true,
                        "editable" : isOwn
                    },
                    "angelff9a5c2e_4cf6": {
                        "name": "angelff9a5c2e_4cf6",
                        "position": {
                            "x": -3.6,
                            "y": 14.25,
                            "z": 29
                        },
                        "rotation": {
                            "x": 0,
                            "y": 0,
                            "z": 0
                        },
                        "scale": 1.8,
                        "videourl": "/static/default/tv.mp4",
                        "play": "/static/img/play.png",
                        "suspend": "/static/img/suspend.png",
                        "visible": true,
                    }
                }
            ;
            

            // Axios.get('/3dapi/user', {
            //     params: {
            //         wxId: iUser
            //     }
            // }).then(res => {
            //     console.log("3dapi", res);
            //     appData.user = res.data;
            //     if (res.data.userData) {
            //         let userData = JSON.parse(res.data.userData)
            //         var keys = Object.getOwnPropertyNames(userData)
            //         keys.forEach(function (key) {
            //             // console.log(key + '--->' + userData[key])
            //             appData[key].ImgUrl = userData[key].ImgUrl
            //         })
            //     }
            // })
            // iUser:店铺用户
            // pUser:使用用户
            // iUser = this.$route.query.iUser
            // pUser = this.$route.query.pUser
            console.log('iUser: ',iUser)
            console.log('pUser: ',pUser)
            if (!iUser){
                iUser = 1234
            }
            Axios.get('/api/user', {
                params: {
                    wxId: iUser
                }
            }).then(res => {
                // console.log("3dapi", res);
                appData.user = res.data;
                // console.log('res.data: ',res.data)
                // console.log('res.data.data: ',res.data.data)
                // console.log('res.data.data.userData: ',res.data.data.userData)
                if (res.data.data.userData) {
                    let userData = JSON.parse(res.data.data.userData.replace(/'/g, '"'))
                    // let userData = JSON.parse(JSON.stringify(res.data.data.userData))
                    // let userData = CircularJSON.parse(res.data.data.userData.replace(/'/g, '"'))
                    console.log('userData: ',userData)
                    // var keys = Object.getOwnPropertyNames(userData)
                    // console.log('keys: ',keys)
                    // keys.forEach(function (key) {
                    //     // console.log(key + '--->' + userData[key])
                    //     appData[key].ImgUrl = userData[key].ImgUrl
                    // })
                    for (var val in userData) {
                        // console.log('userData[val].ImgUrl: ',userData[val].ImgUrl)
                        // console.log('userData[val].miniProgramPath: ',userData[val].miniProgramPath)
                        appData[val].ImgUrl = userData[val].ImgUrl
                        // console.log('val,appData[val].ImgUrl: ',val,appData[val].ImgUrl)
                        appData[val].miniProgramPath = userData[val].miniProgramPath
                        appData[val].url = userData[val].miniProgramPath
                        console.log('val,appData[val].miniProgramPath: ',val,appData[val].miniProgramPath)
                        console.log('val,appData[val].url: ',val,appData[val].url)
                    }
                    // console.log('appData: ',appData)

                }
            })
            

            // if (iUser) {
            //     Axios.get('/llbbapi/userCardWebService/weijia', {
            //         params: {
            //             model: 'user',
            //             method: 'listUserCardPersonal',
            //             piUser: iUser
            //         }
            //     }).then(res => {
            //         console.log("llbbapi", res)
            //         let userList = res.data;
            //         if (Array.isArray(userList)) {
            //             if (userList[0]) {
            //                 appData.angele4422c06_8c06.ImgUrl = "/llbbapi/images/picture/weijia" + userList[0].iPhoto + ".jpg";
            //             }

            //             if (userList[1]) {
            //                 appData.angel95a630e9_e2fc.ImgUrl = "/llbbapi/images/picture/weijia" + userList[1].iPhoto + ".jpg";
            //             }

            //             if (userList[2]) {
            //                 appData.angel957ac90f_d798.ImgUrl = "/llbbapi/images/picture/weijia" + userList[2].iPhoto + ".jpg";
            //             }
            //         }

            //     })
            // }


            console.log('appData', appData)
            return appData;
        },
        mounted() {
            const imgUrl = 'favicon.ico'
            // console.log(location)
            // const href = location.protocol + '//' + location.hostname + '/';
            const config = {
                title: '来自神笔的3D名片',
                desc: '来自神笔的3D名片',
                link: location.href,
                imgUrl: imgUrl
            }
            wxShare.updateWxShareConfig(config)

            this.$bus.$on('imageUpdate', this.onImageUpdate)
        },
        methods: {
            onImageUpdate(payload) {
                console.log('onImageUpdate');
                console.log(this.user);
                let name = payload.name;
                let url = payload.url;
                let data = this.$data;
                data[name].ImgUrl = url;

                let user = data.user;
                if (!user.userData) {
                    user.userData = {}
                }
                if (!user.userData[name]) {
                    user.userData[name] = {}
                }
                user.userData[name].ImgUrl = url;
                let upUser = JSON.parse(JSON.stringify(user));
                Object.assign(upUser, {
                    userData: JSON.stringify(user.userData),
                });
                Axios.post('/3dapi/user', upUser)
                    .then(res => {
                        console.log(res)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            getUrlKey(name) {

                return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [undefined, ''])[1].replace(/\+/g, '%20')) || null

            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
