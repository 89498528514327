<script>
import {  TextureLoader, } from 'three'
import Base from '../components/Base'

export default {
  name: 'bumpMap',
  mixins: [Base],
  inject: ['material'],
  props: {
    url: String,
    options: { type: Object, default: () => ({}) }
  },
  watch:{
  },
  data () {
    let { url, ...rest } = this.options
    url = url || this.url

    let texture
    if (url) {
      if (this.baseUrl) {
        url = this.baseUrl + url
      }
      texture = new TextureLoader().load(url)

        Object.keys(rest).forEach(k => {
            if (k === 'repeat') {
                texture.repeat.set(...rest[k])
            } else {
                texture[k] = rest[k]
            }
        })

        this.material.bumpMap = texture
        this.material.bumpMap.needsUpdate = true;
    }
    return { texture }
  },
  mounted () {
    // this.$emit('update:obj', this.texture)
  },
  beforeDestroy () {
     // this.texture.dispose()
    // this.$emit('update:obj', null)
  },
  destroyed () {
    this.material.bumpMap = null;
    // this.texture.dispose();
    this.texture = null;
  }
}
</script>
