<template>
    <div @mouseover="changeOver()" @mouseleave="changeLeave()" class="look-box" ref="full">
        <div ref="font" v-if="isFull" class="look" @click="fullScreen()">{{open}}</div>
        <div ref="font" v-if="!isFull" class="look" @click="exitFullscreen()">{{close}}</div>
    </div>
</template>

<script>
    export default {
        name: "fullButton",
        props:['color','border','sizes','zIndex','fontSize','font','open','close','top','right','bottom','left','hoverColor'],
        data(){
            return{
                isFull:true
            }
        },
        mounted(){
            let box = this.$refs.full;
            let font=this.$refs.font;
            box.style.position='absolute';
            box.style.width = this.sizes.w +'px';
            box.style.height = this.sizes.h+'px';
            box.style.lineHeight=this.sizes.h+'px';
            box.style.backgroundColor=this.color.color;
            box.style.borderRadius=this.border+'%';
            box.style.zIndex = this.zIndex;
            box.style.top=this.top+'px';
            box.style.left=this.left+'px';
            box.style.right=this.right+'px';
            box.style.bottom=this.bottom+'px';
            font.style.fontSize=this.fontSize+'px';
            font.style.fontFamily=this.font.fontType;
            font.style.color=this.font.color;
        },
        methods:{
            fullScreen() {
                this.isFull = false;
                if (document.body.requestFullscreen) {
                    document.body.requestFullscreen();
                } else if (document.body.mozRequestFullScreen) {
                    document.body.mozRequestFullScreen();
                } else if (document.body.webkitRequestFullscreen) {
                    document.body.webkitRequestFullscreen();
                } else if (document.body.msRequestFullscreen) {
                    document.body.msRequestFullscreen();
                }
            },
            exitFullscreen() {
                this.isFull = true;
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                }
            },
            changeOver(){
                this.$refs.full.style.backgroundColor=this.hoverColor.color;
            },
            changeLeave(){
                this.$refs.full.style.backgroundColor=this.color.color;
            }
        }
    }
</script>

<style scoped>
.look-box{
    white-space: nowrap;
    text-align: center;
    border-radius: 50%;
}
.look{
   cursor: pointer;
}
@media (max-width: 768px){
    .look-box{
        zoom: 0.8;
    }
}
</style>
