<template>
  <div>
    <object3d
      v-if="renderorder <= loaderorder"
      :position="position"
      :rotation="rotation"
      :name="name"
    >
      <mesh :options="Mshoptions" :scale="scale">

          <m-obj :objUrl="modelsrc" :process="process" :edition="edition" v-if="getFileType(modelsrc, 'obj')">
            <!-- :edition="edition" -->
          </m-obj>

      </mesh>
    </object3d>
  </div>
</template>

<script>
import Object3D from "../vue_three_js/components/Object3D";
export default {
  name: "lineObj",
  mixins: [Object3D],
  props: {
    name:{
      type: String,
      default() {
        return "";
      },
    },
    position: {
      type: Object,
      default() {
        return { x: 0, y: 0, z: 0 };
      },
    },
    rotation: {
      type: Object,
      default() {
        return { x: 0, y: 0, z: 0 };
      },
    },
    modelsrc: {
      type: String,
      default() {
        return "static/line.obj";
      },
    },
    renderorder: {
      type:Number,
      default(){
        return 0
      }
    },
    edition:{
      type:Number,
      default(){
        return 1
      }
    },
    scale: {
      type:Number,
      default(){
        return 1
      }
    },
  },
  data() {
    return {
      loaderorder: 0,
      Mshoptions:{
        receiveShadow:true, //// 平面接收阴影,
        castShadow:true  // 立方体的阴影
      },
    };
  },
  watch: {
  },
  mounted() {

  },
  methods: {
    process() {
      this.loaderorder += 1;
      this.$bus.$emit("modelloaded", this.loaderorder);
    },
    /** 返回文件 后缀名 */
    getFileType(filePath, fileName) {
      var startIndex = filePath.lastIndexOf(".");
      if (startIndex != -1) {
        var name = filePath
          .substring(startIndex + 1, filePath.length)
          .toLowerCase();
        return name == fileName;
      } else {
        return "";
      }
    },
    beforeDestroy() {
      this.loaderorder = 0;
    },
  },
  beforeDestroy(){

  }
};
</script>

<style>
</style>
