<script>
    import Object3D from "@/components/vue-three/vue_three_js/components/Object3D";
    import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
    import {DRACOLoader} from "three/examples/jsm/loaders/DRACOLoader";
    import * as THREE from "three";
    // import { Flow } from 'three/examples/jsm/modifiers/CurveModifier.js';

    export default {
        name: "CurveToFlow",
        mixins: [Object3D],
        props: {
            lineName:{
                type: String,
                default() {
                    return "";
                },
            },
            modelsrc1: {
                type: String,
                default() {
                    return null;
                },
            },
            modelsrc2: {
                type: String,
                default() {
                    return null;
                },
            },
            modelsrc3: {
                type: String,
                default() {
                    return null;
                },
            },
            modelsrc4: {
                type: String,
                default() {
                    return null;
                },
            },
            renderorder: {
                type:Number,
                default(){
                    return 0
                }
            },
            edition:{
                type:Number,
                default(){
                    return 1
                }
            },
            scale: {
                type:Number,
                default(){
                    return 0.01
                }
            },
            direction: {
                type:Number,
                default(){
                    return 1
                }
            },
            speed: {
                type:Number,
                default(){
                    return 1
                }
            },
        },
        data() {
            let urlCars = [];
            if (this.modelsrc1)
            {
                urlCars.push(this.modelsrc1)
            }
            if (this.modelsrc2)
            {
                urlCars.push(this.modelsrc2)
            }
            if (this.modelsrc3)
            {
                urlCars.push(this.modelsrc3)
            }
            if (this.modelsrc4)
            {
                urlCars.push(this.modelsrc4)
            }
            return {
                urlCars: urlCars,
                cars:[],
                offset: Math.random() / 40
            };
        },
        created(){

                setTimeout( ()=>{
                this.$global.scene.traverse((child) => {
                    if (child.name === this.lineName) {
                        let temArr = child.geometry.attributes.position.array;
                        let arr = []
                        temArr.forEach((child, ndx) => {
                            if (ndx % 3 == 0) {
                                let temD = [];
                                temD.push(temArr[ndx])
                                temD.push(temArr[ndx + 1])
                                temD.push(temArr[ndx + 2])
                                arr.push(temD)
                            }
                        })
                        let controlPoints = arr;
                        const p0 = new THREE.Vector3();

                        this.curve = new THREE.CatmullRomCurve3(
                            controlPoints.map((p) => {
                                p0.set(...p);
                                return [
                                    (new THREE.Vector3()).copy(p0)

                                ];
                            }).flat(),
                            true, 'centripetal', 0
                        );
                        let carObjs= [], carCount = 0
                        this.loader = new GLTFLoader();

                        this.dracoLoader = new DRACOLoader();
                        this.dracoLoader.setDecoderPath("static/threex/draco/gltf/");
                        this.loader.setDRACOLoader(this.dracoLoader);

                        this.urlCars.forEach(url => {
                            this.loader.load(url, glb => {
                                carObjs.push(glb.scene);
                                glb.scene.traverse((child) => {
                                    if (child.type === 'Mesh') {
                                        child.castShadow = true;
                                        child.receiveShadow = true;
                                    }
                                })
                                glb.scene.scale.set(this.scale, this.scale, this.scale)
                                const box = new THREE.Box3().setFromObject(glb.scene)
                                const size = new THREE.Vector3()
                                box.getSize(size);
                                const center = box.getCenter(new THREE.Vector3())
                                glb.scene.position.y = size.y/2 - center.y

                                carObjs.push(glb.scene)
                                carCount++;
                                if (carCount === this.urlCars.length) {//

                                    for (let i = 0; i < 24; ++i) {
                                        let ints = Math.floor(Math.random() * carCount)
                                        const mesh = carObjs[ints].clone();
                                        // console.log(mesh)
                                        let obj3D = new THREE.Object3D();
                                        obj3D.add(mesh);
                                        if (this.direction) {
                                            mesh.rotation.y = Math.PI / 2
                                        } else {
                                            mesh.rotation.y = -Math.PI / 2
                                        }
                                        this.$global.scene.add(obj3D);
                                        this.cars.push(obj3D);
                                    }

                                }
                                // console.log(glb.scene)

                            })
                        })
                    }
                })
                }, 5000)

        },
        mounted(){
          this.animate()
        },
        methods:{
            animate() {

                requestAnimationFrame( this.animate );
                if(!this.curve){
                    return;
                }
                const time = performance.now();
                // const delta = time - this.prevTime;
                const targetOffset = .0002;

                const pathTime = time * 0.0000048 + this.speed;
                this.cars.forEach((car, ndx) => {
                    let u = pathTime;
                    if (this.direction) {
                        u = pathTime + ndx / this.cars.length + this.offset;
                    } else {
                        u = -pathTime + ndx / this.cars.length + this.offset;
                        while (u < 0) {
                            u = u + 1
                        }
                    }

                    let carPosition = new THREE.Vector3();
                    let carTarget = new THREE.Vector3();
                    let lookAt = new THREE.Vector3();
                    // 获取第一个点
                    this.curve.getPointAt(u % 1, carPosition);
                    // this.carPosition.applyMatrix4(this.curveObject.matrixWorld);

                    this.curve.getPointAt((u + targetOffset) % 1, carTarget);
                    // this.carTarget.applyMatrix4(this.curveObject.matrixWorld);
                    // this.carTarget.z=this.carTarget.z+0.2;

                    car.position.copy(carPosition);
                    // this.carTarget.z=this.carTarget.z*(-1)
                    // car.traverse( (child) => {
                    //     if (child.isMesh) {
                    //         if (child.name.indexOf("Wheel") !== -1 || child.name.indexOf("Tire") !== -1){
                    //             child.rotation.z = child.rotation.z + rotationSpeed;
                    //         }
                    //
                    //     }
                    // })

                    car.lookAt(lookAt.copy(carTarget));


                });

            }
        }
    }
</script>

<style scoped>

</style>
