<template>
  <texture-scene
    :bgCorlor="bgCorlor"
    :url="imgurl"
    :envMap="envMap"
  ></texture-scene>
</template>

<script>
export default {
  name: "backdrop",
  data() {
    /**
     *    'px.jpg', 'nx.jpg',
					'py.jpg', 'ny.jpg',
					'pz.jpg', 'nz.jpg',
     */
    let imgurl
    if(this.url){
      imgurl = this.url
    }else if(this.px && this.nx && this.py && this.pz && this.nz){
      imgurl = []
      imgurl.push(this.px)
      imgurl.push(this.nx)
      imgurl.push(this.py)
      imgurl.push(this.ny)
      imgurl.push(this.pz)
      imgurl.push(this.nz)
    }
    return {
      imgurl
    };
  },
  created() {
  
  },
  props: {
    bgCorlor: {
      //背景颜色
      type: String,
      default() {
        return "#FFB6C1";
      },
    },
    url: {
      //背景图路径
      type: String,
      default() {
        return "";
      },
    },
    px: {
      type: String,
      default() {
        return "";
      },
    },
    nx: {
      type: String,
      default() {
        return "";
      },
    },
    py: {
      type: String,
      default() {
        return "";
      },
    },
    ny: {
      type: String,
      default() {
        return "";
      },
    },
    pz: {
      type: String,
      default() {
        return "";
      },
    },
    nz: {
      type: String,
      default() {
        return "";
      },
    },

    envMap: {
      //场景内物体环境贴图 路径
      type: String,
      default() {
        return "";
      },
    },
  },
};
</script>

<style>
</style>