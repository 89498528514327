<template>
    <a ref="link" :href="href" :target="target">
        {{text}}
    </a>
</template>

<script>
    export default {
        name: "linkComponent",
        props:['text','href','target','fontsize'],
        mounted() {
            let box=this.$refs.link;
            box.style.fontSize=this.fontsize+'px';
        }
    }
</script>

<style scoped>

</style>
